<template>
  <div
    class="tab-pane fade show active"
    id="dial1"
    role="tabpanel"
    aria-labelledby="dial-tab1"
  >
  <form class="search-form d-flex" @submit.prevent  style="border-radius: 0px;">
          <input
            v-model="searchTerm"
            class="form-control "
            type="search"
            placeholder="Search"
            aria-label="Search"
            @keydown.enter="updateFilteredData"
            style="margin-top: 0px;margin-bottom: none; width: 100%;"
            @dragover.prevent
            @input="setParam"            
          />
          <button type="submit" style=" background-color: white; height: 38px; border-radius: 4px; border: 1px solid #e5e5e5; padding: 8px;" @click="updateFilteredData">
            <i class="fas fa-search"></i>
          </button>        
        </form>

    <div class="contact-list-tab-wrapper">
      <div class="contact-listing custom-listings">        
        <ul class="list-unstyled mb-0">
          <li v-if="lengthofData" style="margin-bottom: 10px;">
            <div class="each-contact" style="cursor: none">
              <p>No voicemail records</p>
            </div>
          </li>
          <li
            v-for="recording in voiceMailRecordingData"
            :key="recording.RecordingUrl"
          >
            <div class="each-contact d-flex" style="cursor: default">
              <span class="first-letter"
                ><img src="@/assets/images/sound-wave.svg" alt="wave"
              /></span>
              <div class="each-user-profile">
                <div class="d-flex align-items-center">
                  <p :title="recording.CustomerName" class="mb-0">{{ recording.CustomerName.length>15?recording.CustomerName.substring(0,15)+'..':recording.CustomerName || "Unknown" }}</p>
                  <span class="callduration">{{ recording.CallDuration }}</span>
                </div>
                <span>{{ recording.PhoneNumber }}</span> -
                <span class="calling-date">{{
                  formatDateTime(recording.CallDate, timeZone).date
                }}</span>
                <br />
                <span
                  >{{ timeZone }} -
                  {{ formatDateTime(recording.CallDate, timeZone).time }}</span
                >
              </div>
            </div>

            <div class="icons_wrapper">
              <a
                title="Add contact"
                v-if="!recording.CustomerName"
                href="#"
                class="add-contact"
                style="padding: 5px !important"
                @click="openAddCustomerContactModal(recording.PhoneNumber)"
                ><i class="fa-solid fa-user-plus"></i
              ></a>
              <a
                title="Voice recording"
                :href="recording.RecordingUrl"
                class="custom-links"
                target="_blank"
                rel="noopener noreferrer"
                style="margin: 6px"
                ><i class="fas fa-link"></i
              ></a>
            </div>
          </li>
        </ul>
      </div>
      <nav v-if="!lengthofData" class="page-navigation" aria-label="Page navigation example">
        <span class="page-item first-page">
          <a href="#" @click="goToFirstPage">First</a>
        </span>
        <ul class="pagination justify-content-end mb-0">
          <li class="page-item" @click="goToPreviousPages">
            <a href="#" class="page-link"><i class="fas fa-caret-left"></i></a>
          </li>
          <li
            v-for="page in displayedTabs"
            :key="page"
            class="page-item"
            :class="{ 'page-visited': page === currentPage }"
          >
            <a class="page-link" href="#" @click="setCurrentPage(page)">{{
              page
            }}</a>
          </li>
          <li class="page-item" @click="goToNextPages">
            <a href="#" class="page-link"><i class="fas fa-caret-right"></i></a>
          </li>
        </ul>
        <span class="page-item last-page">
          <a href="#" @click="goToLastPage">Last</a>
        </span>
      </nav>
    </div>
  </div>
</template>
  
  <script>
import store from "@/store";
import { computed, ref } from "vue";

export default {
  setup() {
    const searchTerm = ref("");        
    const currentPage = ref(1);
    let callerName = ref('');
    let callerPhoneNumber = ref('');    

    const setParam = async () => {            
  if (searchTerm.value) {       
    if (/^\d+$/.test(searchTerm.value)) {      
      callerPhoneNumber.value =  searchTerm.value;
      callerName.value=null
    } else {          
      callerName.value = searchTerm.value;
      callerPhoneNumber.value=null
    }
  } else {    
    callerPhoneNumber.value = null;
    callerName.value = null;    
  }
}

    let voiceMailRecordingData = computed(() => store.getters.getVoiceMailRecordings);
    let lengthofData =computed (() => {
      if(voiceMailRecordingData.value.length===0)
      {
        return true
      }
      else{
        return false
      }
    });
    
    const updateFilteredData= async () =>{
      currentPage.value=1;     
      await store.dispatch("emptyVoiceMailRecordingList"); 
      await store.dispatch("setTotalVoiceMailRecordings",{
        name: callerName.value,
        phoneNumber:callerPhoneNumber.value,
        date: ''
      });

      await store.dispatch("setVoiceMailRecordingList", {
          offSet: store.getters.getVoiceRecordingPageNumber,
          limit: store.getters.getLimitPerPageVoiceRecording,
          name: callerName.value,
          phoneNumber:callerPhoneNumber.value,
          date: ""
        });
    }


    let timeZone = computed(() => {
      if (store.getters.getAgentGroup === "US") {
        return "EST";
      }
      return "IND";
    });
    let totalVoiceMailRecordings = computed(() => store.getters.getTotalVoiceMailRecordings);    
    const entriesPerPage = store.getters.getLimitPerPageVoiceRecording;
    const maxDisplayedTabs = 5;

    const totalTabs = computed(() =>
      Math.ceil(totalVoiceMailRecordings.value / entriesPerPage)
    );
    
    const displayedTabs = computed(() => {
      const start = Math.max(
        currentPage.value - Math.floor(maxDisplayedTabs / 2),
        1
      );
      const end = Math.min(start + maxDisplayedTabs - 1, totalTabs.value);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i).map(
        (page) => {
          if (
            page === 1 ||
            page === totalTabs.value ||
            (page >= start && page <= end)
          ) {
            return page;
          } else if (page < start) {
            return "...";
          } else if (page > end) {
            return "...";
          }
          return null;
        }
      );
    });

    const setCurrentPage = async (page) => {      
      currentPage.value = page;
      await store.dispatch("setVoiceMailRecordingList", {
        offSet: entriesPerPage * (currentPage.value - 1),
        limit: entriesPerPage,
        name: callerName.value,
        phoneNumber:callerPhoneNumber.value,
        date: ''
      });
    };

    const goToNextPages = async () => {
      const nextPages = Math.min(currentPage.value + 1, totalTabs.value);
      currentPage.value = nextPages;
      await setCurrentPage(nextPages);
    };

    const goToPreviousPages = async () => {
      const previousPages = Math.max(currentPage.value - 1, 1);
      currentPage.value = previousPages;
      await setCurrentPage(previousPages);
    };

    const goToFirstPage = async () => {
      currentPage.value = 1;
      await store.dispatch("setVoiceMailRecordingList", {
        offSet: entriesPerPage * (currentPage.value - 1),
        limit: entriesPerPage,
        name: callerName.value,
        phoneNumber:callerPhoneNumber.value,
        date: ''
      });
    };

    const goToLastPage = async () => {
      currentPage.value = totalTabs.value;
      await store.dispatch("setVoiceMailRecordingList", {
        offSet: entriesPerPage * (currentPage.value - 1),
        limit: entriesPerPage,
        name: callerName.value,
        phoneNumber:callerPhoneNumber.value,
        date: ''
      });
    };

    const openAddCustomerContactModal = async (phoneNumber) => {
      await store.dispatch("setPrimaryContactNumber", {
        contactNumber: phoneNumber,
      });
      await store.dispatch("setCallAndVoiceRecordingModal", { status: false });
      await store.dispatch("SetAddCustomerContactModal", { status: true,line:"VMR->248" });
    };

    const formatDateTime = (inputDateTime, timeZone) => {
  if (timeZone === "IND") {
    const [dateString, timeString, amPm] = inputDateTime.split(" ");

    let [month, day, year] = dateString
      .split("/")
      .map((part) => parseInt(part, 10));
    let [hour, minute, second] = timeString
      .split(":")
      .map((part) => parseInt(part, 10));

    if (amPm === "PM" && hour < 12) {
      hour += 12;
    } else if (amPm === "AM" && hour === 12) {
      hour = 0;
    }

    hour += 9;
    minute += 30;

    if (minute >= 60) {
      minute -= 60;
      hour += 1;
    }

    if (hour >= 24) {
      const date = new Date(year, month - 1, day);
      date.setDate(date.getDate() + 1);
      year = date.getFullYear();
      month = date.getMonth() + 1;
      day = date.getDate();
      hour %= 24;
    }

    const adjustedAmPm = hour >= 12 ? "PM" : "AM";

    hour = hour % 12 || 12;

    const formattedDate = `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;

    const formattedHour = hour.toString().padStart(2, "0");
    const formattedMinute = minute.toString().padStart(2, "0");
    const formattedSecond = second.toString().padStart(2, "0");
    const formattedTime = `${formattedHour}:${formattedMinute}:${formattedSecond} ${adjustedAmPm}`;

    return { date: formattedDate, time: formattedTime };
  } else {
    const [dateString, timeString, amPm] = inputDateTime.split(" ");
    const [month, day, year] = dateString.split("/").map((part) => part.padStart(2, '0'));
    let [hour, minute, second] = timeString.split(":").map((part) => part.padStart(2, '0'));

    if (amPm === "PM" && hour < 12) {
      hour = String(Number(hour) + 12).padStart(2, '0');
    } else if (amPm === "AM" && hour === "12") {
      hour = "00";
    }

    const formattedDate = `${month}/${day}/${year}`;
    const formattedTime = `${hour}:${minute}:${second} ${amPm}`;

    return { date: formattedDate, time: formattedTime };
  }
};


    return {
      voiceMailRecordingData,
      openAddCustomerContactModal,
      totalTabs,
      maxDisplayedTabs,
      currentPage,
      displayedTabs,
      setCurrentPage,
      goToNextPages,
      goToPreviousPages,
      goToFirstPage,
      goToLastPage,
      timeZone,
      formatDateTime,
      setParam,
      searchTerm,      
      updateFilteredData,
      lengthofData
    };
  },
};
</script>
  
  <style scoped>
.custom-listings {
  max-height: 400px;
  overflow-y: auto;
}
</style>