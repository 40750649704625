<template>
  <div
    :class="[isCallOngoing?'readjust':'','search-for-agents']"
    style="box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3)"
  >
  <div class="search-top" style="display: flex;justify-content: space-between;">
        <p>
          <i class="fas fa-user"></i>
          <span>Add Contact</span>
        </p>
        <i title='Close' class="fas fa-times close-edit_modal-btn" @click="closeModal"></i>
      </div>

    <div class="add-contact-form" >      
      <form class="contact-form-style" action="">
        <!-- <span class="required-msg" style="text-align: left;font-size: 10px;padding-bottom: 5px;">* Mandatory Field </span> -->
        <div class="formcontrol-wrap">
          <label for="firstname" class="form-label">
            <span class="required-msg">* </span>
            First Name 
            <span v-if="checkFirstNameLenght" class="required-msg" style="font-size: 10px;">
              {{firstNameConflictMessage}}
            </span>
        </label>
          
          <input
            type="text"
            id="firstname"
            class="form-control"
            placeholder=""
            v-model="firstName"
            required
            @input="validateFirstName"
            @dragover.prevent
            pattern="^[^<>&]*$"
          />
        </div>
        <div class="formcontrol-wrap">
          <label for="Lastname" class="form-label">
            <span class="required-msg">* </span>
            Last Name             
        </label>
          <input
            type="text"
            id="Lastname"
            class="form-control"
            placeholder=""
            v-model="lastName"
            @input="validateLastName"
            required
            @dragover.prevent
            pattern="^[^<>&]*$"
          />
        </div>
        <div class="formcontrol-wrap">
          <label for="email" class="form-label">            
            Email
            <span v-if="!validEmail" class="required-msg" style="font-size: 10px;">  {{emailConflictMessage}}  </span>
        </label>
          <input 
          type="text" 
          id="email" 
          class="form-control" 
          placeholder="" 
          v-model="email" 
          @input="validateEmail" 
          @dragover.prevent
          pattern="^[^<>&]*$"
          />
        </div>
        <div class="formcontrol-wrap">
          <label for="phone1" class="form-label">
            <span class="required-msg">* </span>
            Phone Number 1 
            <span v-if="!checkPhoneNumLenght" class="required-msg" style="font-size: 10px;"><br>{{phoneNumber1ConflictMessage}} </span>
        </label>
        <input
            type="text"
            id="phone1"
            class="form-control"
            :placeholder=visiblePlaceholder1
            v-model="phoneNumber1"
            @input="onPhoneNumber1Input"   
            @dragover.prevent         
            required   
            pattern="^[^<>&]*$"         
          />
        </div>
        <div class="formcontrol-wrap">
          <label for="phone2" class="form-label">
            Phone Number 2
            <span v-if="!checkPhoneNum2Lenght" class="required-msg" style="font-size: 10px;"><br>{{phoneNumber2ConflictMessage}} </span>          
          </label>
          <input
            type="text"
            id="phone2"
            class="form-control"
            :placeholder=visiblePlaceholder1
            v-model="phoneNumber2"
            @input="onPhoneNumber2Input"            
            @dragover.prevent
            pattern="^[^<>&]*$"
          />
        </div>
        <div class="formcontrol-wrap">
          <label for="gender" class="form-label" style="cursor: pointer;">
            Select Gender
          </label>
          <div style="position: relative;">
            <select id="gender" class="form-select" v-model="selectedGender" style="cursor: pointer;">   
              <option value="N" disabled selected>Select</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>        
            <i class="fas fa-caret-down" style="position: absolute; top: 50%; right: 8px; transform: translateY(-50%); pointer-events: none; color: #e6e6e6; z-index: 1;"></i>
          </div>
          
        </div>
        <div style="text-align: left; ">
          <label for="floatingTextarea" style="margin-bottom: 10px;">Address</label>
          <textarea
            class="form-control no-resize"
            placeholder=""
            id="floatingTextarea"
            rows="5"
            v-model="address"
            @input="onAddressInput"
            @dragover.prevent
          ></textarea>
        </div>
      </form>

    </div>      
      <div class="button-wrapper" >
        <a href="#" class="theme-button light-btn" @click="closeModal">Cancel</a>
        <a href="#" class="theme-button dark-btn" :class="[checkIfAnyFieldEmpty?'disbale-item':'activate-item']" @click="saveCustomer">Save</a>
      </div>
  </div>
</template>
<script>
import {ref,computed} from 'vue';
import store from '@/store';
export default {
  name: "add-edit-contact-form",
  setup() {
    let isCallOngoing=computed(() => store.getters.isCallOngoing);
    const primaryContactNumber=store.getters.getNewCustomerPrimaryContactNumber;
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const phoneNumber1 = ref(primaryContactNumber);
    const phoneNumber2 = ref('');        
    const selectedGender = ref('N');
    const address = ref('');
    let phoneNumber1ConflictMessage=ref('');
    let phoneNumber2ConflictMessage=ref('');

    let checkFirstNameLenght=ref(false);
    let checkLastNameLenght=ref(true);
    let checkPhoneNumLenght=ref(true);
    let checkPhoneNum2Lenght=ref(true);
    let validEmail=ref(true);
    let emailConflictMessage=ref('');
    let firstNameConflictMessage=ref('');

    const checkIfAnyFieldEmpty=computed(()=>{
            return (firstName.value.trim() === ''|| phoneNumber1.value==='' ||phoneNumber1.value.length <=10 ||
            lastName.value.trim() === ''||!validatPhoneNumberLenght || checkFirstNameLenght.value || !validEmail.value ||phoneNumber2ConflictMessage.value!=='')            
        });

    const visiblePlaceholder1=computed(()=>{
      return '+(1)-(1234567890)'
    })

    const saveCustomer = async ()=>{
        store.dispatch("addCustomerDetails",{      
            FirstName:firstName.value,
            LastName:lastName.value,
            Email:email.value,
            PhoneNumber1:phoneNumber1.value,
            PhoneNumber2:phoneNumber2.value,
            Address:address.value,
            Gender:selectedGender.value
            });
            await store.dispatch("SetAddCustomerContactModal", { status: false,line:"ACM->183"});
    }
    
    const validateFirstName = () => {      
          firstName.value = firstName.value.substring(0,150);        
            firstName.value = firstName.value.replace(/[^a-zA-Z]/g, '');
            if(firstName.value.length===0){
              firstNameConflictMessage.value='(First Name is mandatory)'
              checkFirstNameLenght.value=false;
            }
            else if(firstName.value.length>=3){
              checkFirstNameLenght.value=false                
              return false;
            }           
            else{
              firstNameConflictMessage.value='(Must have atleast 3 characters)'   
              checkFirstNameLenght.value=true
              return true;
            }
        }
        const validateLastName = () => {          
            lastName.value = lastName.value.replace(/[^a-zA-Z]/g, '');            
            lastName.value = lastName.value.substring(0,150);  
        }
        
        const validatPhoneNumberLenght = (phoneNum) => {
  const minPhoneNumberLength = 11;
  const maxPhoneNumberLength = 14;
  if (phoneNum === 1) {
    if (phoneNumber1.value.length === 0) {
      checkPhoneNumLenght.value = false;
      phoneNumber1ConflictMessage.value = '(Phone number field is required)';
      return false;
    } else if (
      phoneNumber1.value.length >= minPhoneNumberLength &&
      phoneNumber1.value.length <= maxPhoneNumberLength
    ) {
      checkPhoneNumLenght.value = true;
      phoneNumber1ConflictMessage.value = '';
      return true;
    } else {
      checkPhoneNumLenght.value = false;
      phoneNumber1ConflictMessage.value = `(Enter a phone number between ${minPhoneNumberLength} and ${maxPhoneNumberLength} digits)`;
      return false;
    }
  } else {
    if (phoneNumber2.value.length === 0) {
      checkPhoneNum2Lenght.value = true;
      phoneNumber2ConflictMessage.value = '';
      return true;
    } else if (
      phoneNumber2.value.length >= minPhoneNumberLength &&
      phoneNumber2.value.length <= maxPhoneNumberLength
    ) {
      checkPhoneNum2Lenght.value = true;
      phoneNumber2ConflictMessage.value = '';
      return true;
    } else {
      checkPhoneNum2Lenght.value = false;
      phoneNumber2ConflictMessage.value = `(Enter a phone number between ${minPhoneNumberLength} and ${maxPhoneNumberLength} digits)`;
      return false;
    }
  }
};

const checkIfDuplicateNumbers = (phoneNum) => {
  if (phoneNumber1.value === phoneNumber2.value && phoneNumber1.value !== '') {
    if (phoneNum === 1) {
      phoneNumber1ConflictMessage.value = `(Cannot be same as Phone Number 2)`;
      phoneNumber2ConflictMessage.value = '';
      checkPhoneNumLenght.value = false;
    } else if (phoneNum === 2) {
      phoneNumber2ConflictMessage.value = `(Cannot be same as Phone Number 1)`;
      phoneNumber1ConflictMessage.value = '';
      checkPhoneNum2Lenght.value = false;
    }
  } else {
    checkPhoneNumLenght.value = validatPhoneNumberLenght(1);
    checkPhoneNum2Lenght.value = validatPhoneNumberLenght(2);
    phoneNumber1ConflictMessage.value = '';
    phoneNumber2ConflictMessage.value = '';
  }
};


const onPhoneNumber1Input = () => {
  phoneNumber1.value = phoneNumber1.value.replace(/[^0-9+]/g, '');
  if (phoneNumber1.value.startsWith('+')) {
    phoneNumber1.value = '+' + phoneNumber1.value.slice(1).replace(/\+/g, '');
  } else {
    phoneNumber1.value = phoneNumber1.value.replace(/\+/g, '');
  }
  phoneNumber1.value = phoneNumber1.value.substring(0, 14);
  if (validatPhoneNumberLenght(1)) {
    checkIfDuplicateNumbers(1);
  }
};

const onPhoneNumber2Input = () => {
  phoneNumber2.value = phoneNumber2.value.replace(/[^0-9+]/g, '');
  if (phoneNumber2.value.startsWith('+')) {
    phoneNumber2.value = '+' + phoneNumber2.value.slice(1).replace(/\+/g, '');
  } else {
    phoneNumber2.value = phoneNumber2.value.replace(/\+/g, '');
  }
  phoneNumber2.value = phoneNumber2.value.substring(0, 14);
  if (validatPhoneNumberLenght(2)) {
    checkIfDuplicateNumbers(2);
  }
};


    const onAddressInput = () => {      
      address.value = address.value.substring(0,200);       
      address.value = address.value.replace(/\s+/g, ' ');         
      //address.value= address.value.replace(/\s+/g, ' ').replace(/[^\w\s.,'-]/g, '');      
    };

    const closeModal = () => {
      store.dispatch("SetAddCustomerContactModal", {
        status: false,
        line:"ACM->280"
      });
    };
    
    const validateEmail = () => {
      email.value = email.value.substring(0,150);     
      email.value = email.value.replace(/[^a-zA-Z0-9@._-]/g, '');
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email.value === "") {
        emailConflictMessage.value = "";
        email.value = email.value.trim();
        validEmail.value = true;
      } else {
        email.value = email.value.trim();

        if (emailRegex.test(email.value)) {
          emailConflictMessage.value = "";
          validEmail.value = true;
        } else {
          emailConflictMessage.value = "(Email is invalid)";
          validEmail.value = false;
        }
      }
    };

    return{
        isCallOngoing,firstName,lastName,email,phoneNumber1,phoneNumber2,selectedGender,address,
        saveCustomer,validateFirstName,validateLastName,checkIfAnyFieldEmpty,checkFirstNameLenght,checkLastNameLenght,checkPhoneNumLenght,checkPhoneNum2Lenght,
        onPhoneNumber1Input,onPhoneNumber2Input,closeModal,onAddressInput,phoneNumber1ConflictMessage,phoneNumber2ConflictMessage,
        validateEmail,visiblePlaceholder1,validEmail,emailConflictMessage,firstNameConflictMessage
    }
  },
};
</script>
<style scoped>
.required-msg{
    color: red;
}

::placeholder {
  color: rgb(215, 215, 215);
  font-size: 10px;
}
.no-resize {
  resize: none;
}
</style>