const state = {
  loginModal: false,
  transferCallModal: false,
  incomingCallModal: false,
  diallerAndContactListModal: false,
  dialler: true,
  contactList: false,
  countyCodeDropDown: false,
  agentModalStatus: false,
  breakModalStatus: false,
  transferPopUpModal: false,
  callAndVoiceMailRecordingModal: false,
  callRecording: true,
  voiceMailRecording: false,
  addEditContactFormModal: false,
  deleteEditContactFormModal: false,
  confirmDeleteContactModal: false,
  confirmDeleteAgentModal: false,
  callerQueueModal: false,
  editAgentModal: false,
  callLogModal: false,
  sessionTimeOutModal: false,
  notificationModal: false,
  confirmToSaveNewContactNumberModal :false,
};

const getters = {
  isLoginModal: state => state.loginModal,
  isTransferCallModal: state => state.transferCallModal,
  isIncomingCallModal: state => state.incomingCallModal,
  isDiallerAndContactListModal: state => state.diallerAndContactListModal,
  isShowDialler: state => state.dialler,
  isShowContactList: state => state.contactList,
  showCountyCodeDropDown: state => state.countyCodeDropDown,
  isAgentModalOpen: state => state.agentModalStatus,
  isBreakModalOpen: state => state.breakModalStatus,
  isTransferPopUpModal: state => state.transferPopUpModal,
  isCallAndVoiceMailRecordingModalOpen: state => state.callAndVoiceMailRecordingModal,
  isCallRecording: state => state.callRecording,
  isVoiceMailRecording: state => state.voiceMailRecording,
  isAddCustomerContactModal: state => state.addEditContactFormModal,
  isDeleteEditContactFormModal: state => state.deleteEditContactFormModal,
  isConfirmDeleteContactModal: state => state.confirmDeleteContactModal,
  isCallerQueueModal: state => state.callerQueueModal,
  isConfirmDeleteAgentModal: state => state.confirmDeleteAgentModal,
  isEditAgentModal: state => state.editAgentModal,
  isCallLogModal: state => state.callLogModal,
  issessionTimeOutModal: state => state.sessionTimeOutModal,
  isNotificationModal: state => state.notificationModal,
  isConfirmToSaveNewContactNumberModal: state =>state.confirmToSaveNewContactNumberModal
};

const actions = {

  resetDeviceState({ commit }) {
    commit('RESET_MODAL_STATE')
  },

  setTransferCallModal({ commit }, payload) {
    commit('SET_TRANSFER_CALL_MODAL', payload.status);
    if (payload.status) {
      commit('RESET_OTHER_MODALS', 'transferCallModal');
    }
  },

  setDiallerAndContactListModal({ commit }, payload) {
    commit('SET_DIALLER_AND_CONTACT_LIST', payload.status)
    if (payload.status) {
      commit('RESET_OTHER_MODALS', 'diallerAndContactListModal');
    }
  },

  setShowDialler({ commit }, payload) {
    commit('SET_DIALLER', payload.status);
  },

  setContactList({ commit }, payload) {
    commit('SET_CONTACTLIST', payload.status);
  },

  setCallRecordingViewStatus({ commit }, payload) {
    commit('SET_CALL_RECORDING', payload.status)
  },
  setVoiceMailRecordingViewStatus({ commit }, payload) {
    commit('SET_VOICEMAIL_RECORDING', payload.status)
  },

  SetAgentModal({ commit }, payload) {
    commit('SET_AGENT_MODAL', payload.status)
    if (payload.status) {
      commit('RESET_OTHER_MODALS', 'agentModalStatus');
    }
  },

  setBreakModal({ commit }, payload) {
    commit('SET_BREAK_MODAL', payload.status)
    if (payload.status) {
      commit('RESET_OTHER_MODALS', 'breakModalStatus');
    }
  },

  setTransferPopupModal({ commit }, payload) {
    commit('SET_TRANSFER_POPUP_MODAL', payload.status);
    if (payload.status) {
      commit('RESET_OTHER_MODALS', 'transferPopUpModal');
    }
  },

  setCallAndVoiceRecordingModal({ commit }, payload) {
    commit('SET_CALL_AND_VOICE_RECORDING_MODAL', payload.status);
    if (payload.status) {
      commit('RESET_OTHER_MODALS', 'callAndVoiceMailRecordingModal');
    }
  },
  // SetAddCustomerContactModal({ commit }, payload) {
  //   //console.log('SetAddCustomerContactModal ->',payload.line,payload.status);
  //   commit('SET_ADD_EDIT_CONTACT_FORM_MODAL', payload.status);
  //   if (payload.status) {
  //     commit('RESET_OTHER_MODALS', 'addEditContactFormModal');
  //   }
  // },
   // setConfirmToSaveNewContactNumberModal({commit},payload){
  //   commit('SET_CONFIRM_TO_SAVE_NEW_CONTACT_NUMBER_MODAL',payload.status);
  //   if (payload.status) {
  //     commit('RESET_OTHER_MODALS', 'confirmToSaveNewContactNumberModal');
  //   }
  // }


  async SetAddCustomerContactModal({ commit }, payload) {
    commit('SET_ADD_EDIT_CONTACT_FORM_MODAL', payload.status);
    if (payload.status) {
      // Ensure that other modals do not interfere
      commit('RESET_OTHER_MODALS', 'addEditContactFormModal');
    }
  },
  async setConfirmToSaveNewContactNumberModal({ commit }, payload) {
    commit('SET_CONFIRM_TO_SAVE_NEW_CONTACT_NUMBER_MODAL', payload.status);
    if (payload.status) {
      commit('RESET_OTHER_MODALS', 'confirmToSaveNewContactNumberModal');
    }
  },
  SetDeleteEditCustomerContactModal({ commit }, payload) {
    commit('SET_DELETE_EDIT_CUSTOMER_CONTACT_MODAL', payload.status);
    if (payload.status) {
      commit('RESET_OTHER_MODALS', 'deleteEditContactFormModal');
    }
  },
  setConfirmDeleteContactModal({ commit }, payload) {
    commit('SET_CONFIRM_DELETE_CONTACT_MODAL', payload.status);
    if (payload.status) {
      commit('RESET_OTHER_MODALS', 'confirmDeleteContactModal');
    }
  },
  setCallerQueueModal({ commit }, payload) {
    commit('SET_CALLER_QUEUE_MODAL', payload.status);
    if (payload.status) {
      commit('RESET_OTHER_MODALS', 'callerQueueModal');
    }
  },
  setConfirmDeleteAgentModal({ commit }, payload) {
    commit('SET_CONFIRM_DELETE_AGENT_MODAL', payload.status)
  },
  setEditAgentModal({ commit }, payload) {
    commit('SET_EDIT_AGENT_MODAL', payload.status);
  },
  setCallLogModal({ commit }, payload) {
    commit('SET_CALL_LOG_MODAL', payload.status);
  },
  setSessionTimeOutLogOutModal({ commit }, payload) {
    commit('SET_SESSION_TIME_OUT_MODAL', payload.status);
  },
  setNotificationModal({ commit }, payload) {
    commit('SET_NOTIFICATION_MODAL', payload.status);
    if (payload.status) {
      commit('RESET_OTHER_MODALS', 'notificationModal');
    }
  },
 

};

const mutations = {

  RESET_MODAL_STATE(state) {
    Object.assign(state, {
      loginModal: false,
      transferCallModal: false,
      incomingCallModal: false,
      diallerAndContactListModal: false,
      dialler: true,
      contactList: false,
      countyCodeDropDown: false,
      agentModalStatus: false,
      breakModalStatus: false,
      transferPopUpModal: false,
      callAndVoiceMailRecordingModal: false,
      callRecording: true,
      voiceMailRecording: false,
      addEditContactFormModal: false,
      deleteEditContactFormModal: false,
      confirmDeleteContactModal: false,
      confirmDeleteAgentModal: false,
      callerQueueModal: false,
      editAgentModal: false,
      callLogModal: false,
      notificationModal: false,
      confirmToSaveNewContactNumberModal:false
    })
  },
  SET_LOGIN_MODAL(state, value) {    
    state.loginModal = value;
  },
  SET_TRANSFER_CALL_MODAL(state, value) {    
    state.transferCallModal = value;
  },
  SET_INCOMING_CALL_MODAL(state, value) {    
    state.incomingCallModal = value;
  },
  SET_DIALLER_AND_CONTACT_LIST(state, value) {         
    state.diallerAndContactListModal = value;
  },
  SET_DIALLER(state, value) {
    state.dialler = value;
  },
  SET_CONTACTLIST(state, value) {
    state.contactList = value;
  },

  SET_CALL_RECORDING(state, value) {
    state.callRecording = value;
  },
  SET_VOICEMAIL_RECORDING(state, value) {
    state.voiceMailRecording = value;
  },

  SET_AGENT_MODAL(state, value) {
    state.agentModalStatus = value
  },
  SET_BREAK_MODAL(state, value) {
    state.breakModalStatus = value
  },
  SET_TRANSFER_POPUP_MODAL(state, value) {
    state.transferPopUpModal = value;
  },
  SET_CALL_AND_VOICE_RECORDING_MODAL(state, value) {
    state.callAndVoiceMailRecordingModal = value
  },
  // SET_ADD_EDIT_CONTACT_FORM_MODAL(state, value) {
  //   state.addEditContactFormModal = value
  // },
  // SET_CONFIRM_TO_SAVE_NEW_CONTACT_NUMBER_MODAL(state,value){
  //   state.confirmToSaveNewContactNumberModal=value
  // },
  // RESET_OTHER_MODALS(state, modalName) {
  //   for (const key in state) {
  //     if (key !== modalName && key !== 'transferPopUpModal' && key !== 'callerQueueModal' && typeof state[key] === 'boolean') {
  //       state[key] = false;
  //     }
  //   }
  // },
  SET_ADD_EDIT_CONTACT_FORM_MODAL(state, value) {
    state.addEditContactFormModal = value;
  },
  
  SET_CONFIRM_TO_SAVE_NEW_CONTACT_NUMBER_MODAL(state, value) {
    state.confirmToSaveNewContactNumberModal = value;
  },

  // RESET_OTHER_MODALS(state, modalName) {
  //   for (const key in state) {
  //     if (key !== modalName && key !== 'addEditContactFormModal' && key !== 'confirmToSaveNewContactNumberModal' && typeof state[key] === 'boolean') {
  //       state[key] = false;
  //     }
  //   }
  // },
  RESET_OTHER_MODALS(state, modalName) {
    const excludeModals = [
      modalName,
      'addEditContactFormModal',
      'confirmToSaveNewContactNumberModal',
      'transferPopUpModal',
      'callerQueueModal'
    ];
    for (const key in state) {
      if (!excludeModals.includes(key) && typeof state[key] === 'boolean') {
        state[key] = false;
      }
    }
  },
  SET_DELETE_EDIT_CUSTOMER_CONTACT_MODAL(state, value) {
    state.deleteEditContactFormModal = value
  },
  SET_CONFIRM_DELETE_CONTACT_MODAL(state, value) {
    state.confirmDeleteContactModal = value
  },
  SET_CALLER_QUEUE_MODAL(state, value) {
    state.callerQueueModal = value;
  },
  SET_CONFIRM_DELETE_AGENT_MODAL(state, value) {
    state.confirmDeleteAgentModal = value
  },
  SET_EDIT_AGENT_MODAL(state, value) {
    state.editAgentModal = value
  },
  SET_CALL_LOG_MODAL(state, value) {
    state.callLogModal = value
  },
  SET_SESSION_TIME_OUT_MODAL(state, value) {
    state.sessionTimeOutModal = value
  },
  SET_NOTIFICATION_MODAL(state, value) {
    state.notificationModal = value
  },
  

};


export default {
  state,
  getters,
  actions,
  mutations,
};
