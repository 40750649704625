<template>
    <div 
    :class="[isCallOngoing?'readjust':'','search-for-agents']" 
    style="box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3)">
      <div class="search-top" style="display: flex;justify-content: space-between;">
        <p>
          <i class="fas fa-user"></i>
          <span>Edit Agent</span>
        </p>
        <i title='Close' class="fas fa-times close-edit_modal-btn" @click="onCloseClick"></i>
      </div>
      <div class="add-contact-form">
        <form class="contact-form-style">
          <!-- <span class="required-msg" style="text-align: left;font-size: 10px;padding-bottom: 5px;">* Mandatory Field</span> -->
  
          <!-- First Name -->
          <div class="formcontrol-wrap">
            <label for="firstname" class="form-label">
              <span class="required-msg">*</span>First Name:
              <span v-if="!checkFirstNameLenght" class="required-msg" style="font-size: 10px">
              {{ firstNameConflictMessage }}
              </span>
            </label>
            <input 
            type="text" 
            id="firstname" 
            v-model="firstName" 
            @input="validateFirstName" 
            class="form-control" 
            @dragover.prevent 
            required
            pattern="^[^<>&]*$"
            >
          </div>
  
          <!-- Last Name -->
          <div class="formcontrol-wrap">
            <label for="lastname" class="form-label">
              <span class="required-msg">*</span>
              Last Name:
              <span class="required-msg" style="font-size: 10px">
                {{ lastNameConflictMessage }}
              </span>
            </label>
            <input type="text" id="lastname" v-model="lastName" @input="validateLastName" class="form-control" @dragover.prevent required pattern="^[^<>&]*$">
          </div>
  
          <!-- Email -->
          <div class="formcontrol-wrap">
            <label for="email" class="form-label">
              <span class="required-msg">*</span>Email:
              <span v-if="!validEmail" class="required-msg" style="font-size: 10px">{{ emailConflictMessage }}</span>
            </label>
            <input type="text" id="email" v-model="email" @input="validateEmail" @dragover.prevent class="form-control" pattern="^[^<>&]*$">
          </div>
  
          <!-- Phone Number -->
          <div class="formcontrol-wrap">
            <label for="phonenumber" class="form-label">
              <span class="required-msg">*</span>Phone Number:
              <!-- <span v-if="!checkPhoneNumLenght" class="required-msg" style="font-size: 10px;"><br>{{ phoneNumberConflictMessage }}</span> -->
              <span v-if="!checkPhoneNumLenght || duplicatePhoneNumber" class="required-msg" style="font-size: 10px;"><br>{{phoneNumberConflictMessage}} </span>
            </label>
            <input type="text" id="phonenumber" v-model="phoneNumber" @input="validatePhoneNumber" required :placeholder="visiblePlaceholder" @dragover.prevent class="form-control" pattern="^[^<>&]*$">
          </div>
  
          <!-- Agent Role -->
          <div class="formcontrol-wrap" style="position: relative;">
            <label for="agent-role" class="form-label"><span class="required-msg">* </span>Agent Role:</label>
            <select
              v-model="selectedUserRole"
              :value="roles"
              required
              style="min-width: 100%; height: 2.3em; border-radius: 4px; border-color: #dbdbdb; appearance: none; cursor: pointer;"     class="form-select"
            >
              <option value="" disabled selected>Select an Agent Role</option>
              <option v-for="role in roles" :key="role.value" :value="role.value">{{ role.label }}</option>         
            </select>
            <!-- Dropdown icon -->
            <i class="fas fa-caret-down" style="position: absolute; top: 50%; right: 8px; transform: translateY(-50%); color: #e6e6e6; z-index: 1; pointer-events: none; cursor: pointer;"></i> <!-- Add cursor: pointer; -->
          </div>
          
          <!-- Agent Group -->
          <div class="formcontrol-wrap" style="position: relative;">
            <label for="username" class="form-label"><span class="required-msg">* </span>Agent Group:</label>
            <select
              v-model="selectedUserGroup"
              :value="groups"
              placeholder="User Group"
              required
              style="min-width: 100%; height: 2.3em; border-radius: 4px; border-color: #dbdbdb; appearance: none; cursor: pointer;"     class="form-select"
            >
              <option value="" disabled selected>Select an Agent Group</option>
              <option v-for="group in groups" :key="group.value" :value="group.value">{{ group.label }}</option>
            </select>
            <!-- Dropdown icon -->
            <i class="fas fa-caret-down" style="position: absolute; top: 50%; right: 8px; transform: translateY(-50%); color: #e6e6e6; z-index: 1; pointer-events: none; cursor: pointer;"></i> <!-- Add cursor: pointer; -->
          </div>

        </form>
      </div>
      
      <!-- Buttons -->
      <div class="button-wrapper" style="margin-top: 10px; background-color: white;">
    <a href="#" class="theme-button light-btn" @click="onCloseClick">Close</a>
    <a href="#" :class="[!checkIfAnyFieldEmpty ? 'activate-item' : 'disbale-item', 'theme-button dark-btn']" @click="onEditClick">Save</a>
</div>

    </div>
  </template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    let isCallOngoing=computed(() => store.getters.isCallOngoing);
    const store = useStore();
    const agentDetails = computed(() => store.getters.getEditAgentdetails);
    const agentListData = computed(() => store.getters.getAgentsList); 
    let duplicatePhoneNumber=ref(false); 

    // Initialize form fields with agent details
    const agentGuidId = ref(agentDetails.value.AgentGuid);
    const userName = ref(agentDetails.value.UserName || "");
    const firstName = ref(agentDetails.value.FirstName || "");
    const lastName = ref(agentDetails.value.LastName || "");
    const email = ref(agentDetails.value.Email || "");
    const phoneNumber = ref(agentDetails.value.PhoneNumber || "");
    const selectedUserRole = ref(agentDetails.value.UserRole || "");
    const selectedUserGroup = ref(agentDetails.value.UserGroup || "");

    let emailConflictMessage = ref("");
    let validEmail = ref(true);
    let phoneNumberConflictMessage = ref("");
    let checkPhoneNumLenght = ref(true);
    let lastNameConflictMessage = ref('');

    let firstNameConflictMessage=ref('');

    const checkFirstNameLenght = computed(() => {
      return firstName.value.length >= 3 && firstName.value.trim() !== "";
    });

    const checkIfAnyFieldEmpty = computed(() => {
      if(firstName.value=== ""||
      lastName.value==="" || 
      !validEmail.value ||
      email.value === "" ||
      !checkPhoneNumLenght.value || 
      selectedUserGroup.value===""||
      selectedUserRole.value===''|| 
      !checkFirstNameLenght.value || 
      duplicatePhoneNumber.value)
      {
        return true
      }
      else{
        return false
      }
    });        

    const roles = [
      { value: "admin", label: "Admin" },
      { value: "user", label: "User" },
    ];

    const groups = [
      { value: "US", label: "US" },
      { value: "IND", label: "IN" },
    ];

    const onEditClick = async () => {      
      store.dispatch("updateAgentDetails", {
        AgentGuidID: agentGuidId.value,
        UserName: userName.value,
        FirstName: firstName.value,
        LastName: lastName.value,
        Email: email.value,
        PhoneNumber: phoneNumber.value,
        UserGroup: selectedUserGroup.value,
        UserRole: selectedUserRole.value,
      });
      store.dispatch("setEditAgentModal", { status: false });
      store.dispatch("SetAgentModal", {status: true});
      store.dispatch("setShowDialler", { status: true });    
    };

    const onCloseClick = () => {
      store.dispatch("setEditAgentModal", { status: false });
      store.dispatch("SetAgentModal", {status: true});
      store.dispatch("setShowDialler", { status: true });    
    };

    const validatPhoneNumberLenght = () => {
      const minPhoneNumberLength = 11;
      const maxPhoneNumberLength = 14;
      if (
        phoneNumber.value.length >= minPhoneNumberLength &&
        phoneNumber.value.length <= maxPhoneNumberLength
      ) {
        checkPhoneNumLenght.value = true;
        phoneNumberConflictMessage.value = "";
        return true;
      } else if (phoneNumber.value === "") {
        checkPhoneNumLenght.value = false;
        phoneNumberConflictMessage.value = "(Phone Number is mandatory)";
        return true;
      } else {
        checkPhoneNumLenght.value = false;
        phoneNumberConflictMessage.value = `(Enter a phone number between ${minPhoneNumberLength} and ${maxPhoneNumberLength} digits)`;
        return false;
      }
    };

    const validatePhoneNumber = () => {
  phoneNumber.value = phoneNumber.value.replace(/[^0-9+]/g, "");
  if (phoneNumber.value.startsWith("+")) {
    phoneNumber.value = "+" + phoneNumber.value.slice(1).replace(/\+/g, "");
  } else {
    phoneNumber.value = phoneNumber.value.replace(/\+/g, "");
  }
  phoneNumber.value = phoneNumber.value.substring(0, 14);     

  if(validatPhoneNumberLenght())
  {
    if (phoneNumber.value !== agentDetails.value.PhoneNumber) {
      checkIfDuplicateNumberExists(phoneNumber.value);
    } else {
      duplicatePhoneNumber.value = false;
      phoneNumberConflictMessage.value = '';
    }
  }
};


    const validateFirstName = () => {
      firstName.value = firstName.value.substring(0,150);     
      firstName.value = firstName.value.replace(/[^a-zA-Z]/g, "");
      if(firstName.value.length===0){
          firstNameConflictMessage.value='(First Name is mandatory)'
          checkFirstNameLenght.value=false;
        }
      if(firstName.value.length>=3){
                checkFirstNameLenght.value=true                                
            }           
            else{
              firstNameConflictMessage.value='(Must have atleast 3 characters)'   
              checkFirstNameLenght.value=false                
      }
    };

    const validateLastName = () => {
      lastName.value = lastName.value.substring(0,150);     
      lastName.value = lastName.value.replace(/[^a-zA-Z]/g, "");
      if(lastName.value.length === 0){             
        lastNameConflictMessage.value='(Last Name is mandatory)';
      }else{        
        lastNameConflictMessage.value=''
      } 
    };

    const validateEmail = () => {
      email.value = email.value.substring(0,150);     
      email.value = email.value.replace(/[^a-zA-Z0-9@._-]/g, "");
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email.value === "") {        
        emailConflictMessage.value = "(Email is mandatory)";
        email.value = email.value.trim();
        validEmail.value = false;
      } else {
        email.value = email.value.trim();

        if (emailRegex.test(email.value)) {
          emailConflictMessage.value = "";
          validEmail.value = true;
        } else {
          emailConflictMessage.value = "(Email is invalid)";
          validEmail.value = false;
        }
      }
    };

    const visiblePlaceholder = computed(() => {
      return "+(1)-(1234567890)";
    });

    // const isSaveButtonActive = computed(() => {       
    //   return (changesMade.value && checkIfAnyFieldEmpty.value);
    // });

    const checkIfDuplicateNumberExists = (fullPhoneNumber) => {     
      for (const agent of agentListData.value) {
        if (agent.PhoneNumber === fullPhoneNumber) {
      duplicatePhoneNumber.value=true;
      phoneNumberConflictMessage.value = '(Phone number already exists)';
      return;
    }
    else{
      duplicatePhoneNumber.value=false
    }
  }  

  phoneNumberConflictMessage.value = '';
};

    return {
      isCallOngoing,
      firstName,
      lastName,
      email,
      phoneNumber,
      selectedUserRole,
      selectedUserGroup,
      roles,
      groups,
      onEditClick,
      onCloseClick,
      checkIfAnyFieldEmpty,
      validateFirstName,
      validateLastName,
      validatePhoneNumber,
      checkFirstNameLenght,
      validateEmail,
      emailConflictMessage,
      validEmail,
      agentDetails,
      validatPhoneNumberLenght,
      checkPhoneNumLenght,
      phoneNumberConflictMessage,
      visiblePlaceholder,
      //isSaveButtonActive,
      duplicatePhoneNumber,
      lastNameConflictMessage,
      firstNameConflictMessage
    };
  },
};
</script>

  <style scoped>
  .agent-form {
    max-width: 321px;
    min-width: 321px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #f9f9f9;
    z-index: 10;
    position: absolute;
    top: 140%;
    left: 0%;
    right: 3%;
    min-height: 300px;
    max-height: 420px;
    overflow: auto;
  }
  .form-select {
    min-width: 100%;
    height: 2.3em;
    border-radius: 4px;
    border-color: #dbdbdb;
    appearance: none;
    overflow-y: hidden; /* Add this line to hide the scrollbar */
  }
  
  .Agent-title {
    font-size: large;
    font-weight: 0;
  }
  .form-group {
    text-align: left;
    margin: 10px 0;
    margin-right: 15px;
  }
  
  .label {
    text-align: left;
    display: block;
    font-weight: bolder;
  }
  
  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .button-group {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
  }
  
  button {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  button:nth-child(1) {
    background: #053466;
    color: #fff;
  }
  
  button:nth-child(2) {
    background: #053466;
    color: white;
  }
  
  .form-title {
    text-align: left;
    font-size: 1.5em;
  }
  .required-msg {
    color: red;
  }
  ::placeholder {
    color: rgb(215, 215, 215);
    font-size: 10px;
  }
  </style>