<template>
    <div
    :class="[isCallOngoing?'readjust':'','search-for-agents']"
    style="box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3); "
  >
        <div class="search-top" style="display: flex;justify-content: space-between;">
        <p>
          <i class="fa-solid fa-mug-hot"></i>
          <span>Going on Break</span>
        </p>
      </div>
        <div class="add-contact-form test" >      
      <form class="contact-form-style" action="">        
        <div style="text-align: left; ">                 
          <label for="inputBox" class="form-label">
            <span class="required-msg"> * </span>
            Reason
            <span v-if="reason !== ''" class="required-msg" style="font-size: 10px;">{{ isReasonEmptyOrSpaces }} </span>

        </label>
          <textarea
          class="breakReason"   
          v-model="reason" 
          minlength="3" 
          maxlength="100"
          placeholder="Enter your break reason here" 
          autofocus
          @input="validateBreakReason"
          ></textarea>
        </div>
      </form>

    </div>      
      <div class="button-wrapper" >
       <a href="#" class="theme-button light-btn" @click="onModalClose">Close</a>
        <!-- <a href="#" class="theme-button dark-btn" :class="[isReasonEmptyOrSpaces?'disbale-item':'activate-item','break-btn']"  @click="OnSubmitClick">Submit</a> -->
        <a href="#" class="theme-button dark-btn" :class="[isReasonEmptyOrSpaces?'disbale-item':'activate-item','break-btn']"  @click="OnSubmitClick">Submit</a>
      </div>

    </div>
    <!-- </div> -->
    <!-- </div> -->
</template>
<script>
import {ref,computed } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "break-modal",
    setup() {
        let isCallOngoing=computed(() => store.getters.isCallOngoing);
        const store = useStore();        
        const reason = ref('');
        const user = store.getters.getUser;
        const OnSubmitClick = () => {
            if(!isCallOngoing.value){
                    store.dispatch("setBreakReason", {
                    breakReason: reason.value,
                });
                            
                store.dispatch('SetAgentOfflineBreak',{agentIdentity:store.getters.getUser});    
                store.getters.currentDevice.unregister();
                store.getters.currentDevice.on('unregistered', () => {
                    console.log('The device is no longer able to receive calls.')
                });            
                
                store.dispatch("goOnBreak", { agentIdentity: user, breakReason: reason.value })
                store.dispatch('setBreakModal', { status: false });
            }
            else{
                store.dispatch('SetAgentOfflineBreak',{agentIdentity:store.getters.getUser});
            }
        }
        const onModalClose = () => {
            store.dispatch('setBreakModal', { status: false });
        };
        const isReasonEmptyOrSpaces = computed(() => {
            const trimmedReason = reason.value.trim();
            const hasMinLength = trimmedReason.length >= 3;
            const consecutiveSpaces = /\s\s/.test(reason.value);
    
    if (!hasMinLength) {
        return ' (Should have at least 3 characters)';
    } else if (consecutiveSpaces) {
        return ' (Should not contain consecutive spaces)';
    } else {
        return ''; 
    }
});   

const validateBreakReason =()=> {
    reason.value = reason.value.replace(/\s+/g, ' ');    
}

        return {
            isCallOngoing,
            reason,
            OnSubmitClick,
            onModalClose,
            user,   
            isReasonEmptyOrSpaces,
            validateBreakReason   
        };
    }
};
</script>

<style scoped>
.break-btn {
    background-color: #001143;
    color: #fff;
    border: none;
    padding: 12px 25px;
    border-radius: 3px;
    cursor: pointer;
    outline: 0;
    font-size: 12px;
}

.close-btn {
    color: grey;
    background-color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 3px;
    cursor: pointer;
    outline: 0;
    font-size: 12px;
    margin-right: 10px;
}

.BreakModal {
    position: absolute;
    margin-top: 10px;
    max-width: 415px;
    height: 330px;
    width: 100%;
    background-color: #F9F9F9;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
}

.modal-card-foot {
    margin-top: 14px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f5f5f5;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 20px;
    position: relative;
}

/*.modal-card-body {
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: 20px;
    position: relative;
}

.breakReason {
     display: block;
    max-width: 100%;
    min-width: 100%;
    padding: calc(0.75em - 1px);
    resize: vertical;
    background-color: #fff;
    border-color: #dbdbdb; 
    max-height: 40em;
    min-height: 8em;
    display: block;
    max-width: 100%;
    min-width: 100%;
    padding: calc(0.75em - 1px);
    resize: vertical;
    border-radius: 5px;
    border-color: #dbdbdb;;
    
    display: block;
    cursor: pointer;
    
   
    max-width: 100%;
    min-width: 100%;
    max-height: 40em;
    min-height: 8em;
    padding: calc(0.75em - 1px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}
*/
.modal-card-body {
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: 20px;
    position: relative;
    display: flex; /* Add this line to use flexbox */
    flex-direction: column; /* Add this line to stack child elements vertically */
}

.breakReason {
    display: block;
    cursor: text; 
    max-width: 100%;
    min-width: 100%;
    max-height: 40em;
    min-height: 8em;
    padding: calc(0.75em - 1px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-top: 10px; /* Add this line to give margin at the top */
}
.modal-card-title {
    color: #363636;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 1.5rem;
    line-height: 1;
}

.modal-card-head {
    border-bottom: 1px solid #dbdbdb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f5f5f5;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 20px;
    position: relative;
}
.label {
    color: #363636;
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5em;
    cursor: default;
    position: left;
}
.required-msg{
    color: red;
}
.test{
    overflow-y: unset;
}
.add-contact-form {
    overflow-y: scroll;
    max-height: 315px;
}
</style>