<template>
                    <!-- component reused here : additional class added "voicemail-callrecord-wrapp" -->
                    <div class="dial-and-contact-wrapper voicemail-callrecord-wrapp position-absolute " style="box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3) ">
                        <ul class="nav nav-tabs" id="myTab2" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button :class="[isCallRecordingClicked ? 'active' : '', 'nav-link']" id="contactlist-tab1" data-bs-toggle="tab"
                                    data-bs-target="#contactlist1" type="button" role="tab" aria-controls="contactlist1"
                                    aria-selected="false" @click="ChatCallRecordingViewStatus('call')">Calls Recording</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button :class="[!isCallRecordingClicked ? 'active' : '', 'nav-link']" id="dial-tab1" data-bs-toggle="tab"
                                    data-bs-target="#dial1" type="button" role="tab" aria-controls="dial1"
                                    aria-selected="true" @click="ChatCallRecordingViewStatus('voiceMail')">Voicemail Recording</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <!-- for contact listing -->
                            <CallRecording v-if="isCallRecordingClicked"></CallRecording>

                            <!-- for dial1ing area  -->
                            <VoiceMailRecording v-if="!isCallRecordingClicked"></VoiceMailRecording>
                        </div>
                        <!-- dial and contact section ends -->
                    </div>
       
</template>
<script>
import {computed} from "vue";
import store from '@/store';
import CallRecording from "@/components/CallRecording.vue";
import VoiceMailRecording from "@/components/VoiceMailRecording.vue";
export default {
  name: "callAndVoiceMailRecording",
  components: {
    CallRecording,
    VoiceMailRecording
  },
  setup() {         
    let isCallRecordingClicked = computed(() => store.getters.isCallRecording);           
    const ChatCallRecordingViewStatus = (type) => {
      switch (type) {
        case "call":                
          store.dispatch("setCallRecordingViewStatus", { status: true });   
          store.dispatch("setVoiceMailRecordingViewStatus", { status: false});      
          store.dispatch("setVoiceMailRecordingList", {
            offSet: store.getters.getVoiceRecordingPageNumber,
            limit: store.getters.getLimitPerPageVoiceRecording,
          });
          store.dispatch("setCallRecordingsList", {
            offSet: store.getters.getCallRecordingPageNumber,
            limit: store.getters.getLimitPerPageCallRecording,
          });       
          break;
        case "voiceMail":
         
          store.dispatch("setVoiceMailRecordingViewStatus", { status: true});
          store.dispatch("setCallRecordingViewStatus", { status: false});           
          break;
      }
    };
    return{      
      ChatCallRecordingViewStatus,
      isCallRecordingClicked,      
    }    
  },
};
</script>