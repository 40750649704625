import { $http } from "@/axios";

const state = {
  listOfCallLogs: []
};

const getters = {
  getListOfCallLogs: state => state.listOfCallLogs
};

const actions = {
  async setCallLogsList({ commit }, payload) {
    try {
      const response = await $http.get("Call/GetCallLogDetails", {
        params: { phoneNumber: payload.number }
      });
      commit("SET_CALL_LOGS_LIST", response.data.agents);
    } catch (error) {
      console.error("Error fetching call logs:", error);
    }
  },
  async resetCallLogsList({ commit }) {
    commit("RESET_CALL_LOGS_LIST");
  }
};

const mutations = {
  SET_CALL_LOGS_LIST(state, logs) {
    state.listOfCallLogs = logs;
  },
  RESET_CALL_LOGS_LIST(state) {
    state.listOfCallLogs = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
