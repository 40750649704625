<template>
  <div
  :class="[isCallOngoing?'readjust':'','search-for-agents']"
    style="box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3)"
  >
    <div
      class="search-top"
      style="display: flex; justify-content: space-between"
    >
      <p>
        <i class="fas fa-user"></i>
        <span>Edit Contact</span>
      </p>
      <i title="Back" class="fas fa-arrow-left back-btn" @click="goBack"></i>
    </div>

    <div class="add-contact-form">      
      <form class="contact-form-style" action="">
        <!-- <span class="required-msg" style="text-align: left;font-size: 10px;padding-bottom: 5px;">* Mandatory Field</span> -->
        <div class="formcontrol-wrap">
          <label for="firstname" class="form-label">
            <span class="required-msg">* </span>
            First Name
            <span
              v-if="checkFirstNameLenght"
              class="required-msg"
              style="font-size: 10px"
              >{{firstNameConflictMessage}}
            </span>
          </label>

          <input
            type="text"
            id="firstname"
            class="form-control"
            placeholder=""
            v-model="firstName"
            required
            @dragover.prevent
            @input="validateFirstName"
            pattern="^[^<>&]*$"
          />
        </div>
        <div class="formcontrol-wrap">
          <label for="Lastname" class="form-label">
            <span class="required-msg">* </span>
            Last Name             
        </label>
          <input
            type="text"
            id="Lastname"
            class="form-control"
            placeholder=""
            v-model="lastName"
            @input="validateLastName"
            @dragover.prevent
            required
            pattern="^[^<>&]*$"
          />
        </div>
        <div class="formcontrol-wrap">
          <label for="email" class="form-label">
            Email
            <span v-if="!validEmail" class="required-msg" style="font-size: 10px;">  {{emailConflictMessage}}  </span>
          </label>
          <input
            type="text"
            id="email"
            class="form-control"
            placeholder=""
            v-model="email"
            @input="validateEmail"
            @dragover.prevent
            pattern="^[^<>&]*$"
          />
        </div>
        <div class="formcontrol-wrap">
          <label for="phone1" class="form-label">
            <span class="required-msg">* </span>
            Phone Number 1
            <span v-if="!checkPhoneNumLenght" class="required-msg" style="font-size: 10px;"><br>{{phoneNumber1ConflictMessage}} </span>
          </label>
          <input
            type="text"
            id="phone1"
            class="form-control"
            placeholder=""
            v-model="phoneNumber1"
            @input="onPhoneNumber1Input"  
            @dragover.prevent
            required
            pattern="^[^<>&]*$"
          />
        </div>
        <div class="formcontrol-wrap">
          <label for="phone2" class="form-label">
            Phone Number 2 
            <span v-if="!checkPhoneNum2Lenght" class="required-msg" style="font-size: 10px;"><br>{{phoneNumber2ConflictMessage}} </span>          
          </label>
          <input
            type="text"
            id="phone2"
            class="form-control"
            placeholder=""
            v-model="phoneNumber2"
            @input="onPhoneNumber2Input"  
            @dragover.prevent
            pattern="^[^<>&]*$"
          />
        </div>
        <div class="formcontrol-wrap">
          <label for="gender" class="form-label" style="cursor: pointer;">
            Select Gender
          </label>
          <div style="position: relative;">
            <select id="gender" class="form-select" v-model="selectedGender" style="cursor: pointer;">   
              <option value="N" disabled selected>Select</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>        
            <i class="fas fa-caret-down" style="position: absolute; top: 50%; right: 8px; transform: translateY(-50%); pointer-events: none; color: #e6e6e6; z-index: 1;"></i>
          </div>
          
        </div>
        <div style="text-align: left">
          <label for="floatingTextarea" style="margin-bottom: 10px"
            >Address</label
          >
          <textarea
            class="form-control no-resize"
            placeholder=""
            id="floatingTextarea"
            rows="5"
            v-model="address"
            @input="onAddressInput"
            @dragover.prevent
          ></textarea>
        </div>
      </form>
    </div>
    <div class="button-wrapper">
        <a
          href="#"
          class="theme-button light-btn"
          @click="deleteCustomerContactDetails"
          >Delete</a
        >
        <a
          href="#"
          class="theme-button dark-btn"
          :class="[checkIfAnyFieldEmpty || !changesMade ? 'disbale-item' : 'activate-item']"
          @click="updateCustomerDetails"
          >Update</a
        >
      </div>
  </div>
</template>
  <script>
import { ref, computed } from "vue";
import store from "@/store";
export default {
  name: "delete-edit-contact-modal",
  setup() {
    let isCallOngoing=computed(() => store.getters.isCallOngoing);
    const customerDetails = computed(() => store.getters.getCustomerDetailsByName);    
    const firstName = ref(customerDetails.value.firstName);
    const lastName = ref(customerDetails.value.lastName);
    const email = ref(customerDetails.value.email);
    const phoneNumber1 = ref(customerDetails.value.phoneNumber1);
    const phoneNumber2 = ref(customerDetails.value.phoneNumber2 || "");    
    // const selectedGender = ref(customerDetails.value.gender === "M" ? "M": "F");
    const selectedGender = ref(customerDetails.value.gender=== "M" ? "M" : customerDetails.value.gender === "F" ? "F" : 'N');

    const address = ref(customerDetails.value.address);

    let checkFirstNameLenght = ref(false);
    let checkLastNameLenght = ref(true);
    let checkPhoneNumLenght = ref(true);
    let phoneNumber1ConflictMessage=ref('');
    let validEmail=ref(true);
    let emailConflictMessage=ref('');
    let phoneNumber2ConflictMessage=ref('');
    let checkPhoneNum2Lenght=ref(true);

    let firstNameConflictMessage=ref('');

    const checkIfAnyFieldEmpty = computed(() => {
      return (
        firstName.value.trim() === "" ||
        phoneNumber1.value === "" ||
        lastName.value.trim() === "" ||        
        !validatPhoneNumberLenght ||
        phoneNumber1.value.length <=10||
        checkFirstNameLenght.value ||
        !validEmail.value||phoneNumber2ConflictMessage.value!==''
      );
    });

    const checkIfDuplicateNumbers = (phoneNum) => {
  if (phoneNumber1.value === phoneNumber2.value && phoneNumber1.value !== '') {
    if (phoneNum === 1) {
      phoneNumber1ConflictMessage.value = `(Cannot be same as Phone Number 2)`;
      phoneNumber2ConflictMessage.value = '';
      checkPhoneNumLenght.value = false;
    } else if (phoneNum === 2) {
      phoneNumber2ConflictMessage.value = `(Cannot be same as Phone Number 1)`;
      phoneNumber1ConflictMessage.value = '';
      checkPhoneNum2Lenght.value = false;
    }
  } else {
    checkPhoneNumLenght.value = validatPhoneNumberLenght(1);
    checkPhoneNum2Lenght.value = validatPhoneNumberLenght(2);
    phoneNumber1ConflictMessage.value = '';
    phoneNumber2ConflictMessage.value = '';
  }
};

const onPhoneNumber1Input = () => {
  phoneNumber1.value = phoneNumber1.value.replace(/[^0-9+]/g, '');
  if (phoneNumber1.value.startsWith('+')) {
    phoneNumber1.value = '+' + phoneNumber1.value.slice(1).replace(/\+/g, '');
  } else {
    phoneNumber1.value = phoneNumber1.value.replace(/\+/g, '');
  }
  phoneNumber1.value = phoneNumber1.value.substring(0, 14);
  if (validatPhoneNumberLenght(1)) {
    checkIfDuplicateNumbers(1);
  }
};

const onPhoneNumber2Input = () => {
  phoneNumber2.value = phoneNumber2.value.replace(/[^0-9+]/g, '');
  if (phoneNumber2.value.startsWith('+')) {
    phoneNumber2.value = '+' + phoneNumber2.value.slice(1).replace(/\+/g, '');
  } else {
    phoneNumber2.value = phoneNumber2.value.replace(/\+/g, '');
  }
  phoneNumber2.value = phoneNumber2.value.substring(0, 14);
  if (validatPhoneNumberLenght(2)) {
    checkIfDuplicateNumbers(2);
  }
};
 const goBack = () => {
  store.dispatch("SetDeleteEditCustomerContactModal", { status: false });
   store.dispatch("setDiallerAndContactListModal", { status: true });
 };


    // const closeModal = () => {
    //   store.dispatch("SetDeleteEditCustomerContactModal", {
    //     status: !store.getters.isDeleteEditContactFormModal,
    //   });
    // };

    const updateCustomerDetails = async () => {
      store.dispatch("editCustomerDetails", {
        CustomerGuid: customerDetails.value.customerGuid,
        FirstName: firstName.value,
        LastName: lastName.value,
        Email: email.value,
        PhoneNumber1: phoneNumber1.value,
        PhoneNumber2: phoneNumber2.value,
        Address: address.value,
        Gender: selectedGender.value,
      });
      store.dispatch("SetDeleteEditCustomerContactModal", {
        status: !store.getters.isDeleteEditContactFormModal,
      });
    };

    const validateFirstName = () => {           
      firstName.value = firstName.value.substring(0,150);     
            firstName.value = firstName.value.replace(/[^a-zA-Z]/g, '');
                    if(firstName.value.length===0){
          firstNameConflictMessage.value='(First Name is mandatory)'
          checkFirstNameLenght.value=false;
        }
        else if(firstName.value.length>=3){
                checkFirstNameLenght.value=false                
                return false;
            }           
            else{
              firstNameConflictMessage.value='(Must have atleast 3 characters)'     
                checkFirstNameLenght.value=true
                return true;
            }
        }
        const validateLastName = () => {
            lastName.value = lastName.value.replace(/[^a-zA-Z]/g, ''); 
            lastName.value = lastName.value.substring(0,150);             
        }
                
        const validatPhoneNumberLenght = (phoneNum) => {
  const minPhoneNumberLength = 11;
  const maxPhoneNumberLength = 14;
  if (phoneNum === 1) {
    if (phoneNumber1.value.length === 0) {
      checkPhoneNumLenght.value = false;
      phoneNumber1ConflictMessage.value = '(Phone number field is required)';
      return false;
    } else if (
      phoneNumber1.value.length >= minPhoneNumberLength &&
      phoneNumber1.value.length <= maxPhoneNumberLength
    ) {
      checkPhoneNumLenght.value = true;
      phoneNumber1ConflictMessage.value = '';
      return true;
    } else {
      checkPhoneNumLenght.value = false;
      phoneNumber1ConflictMessage.value = `(Enter a phone number between ${minPhoneNumberLength} and ${maxPhoneNumberLength} digits)`;
      return false;
    }
  } else {
    if (phoneNumber2.value.length === 0) {
      checkPhoneNum2Lenght.value = true;
      phoneNumber2ConflictMessage.value = '';
      return true;
    } else if (
      phoneNumber2.value.length >= minPhoneNumberLength &&
      phoneNumber2.value.length <= maxPhoneNumberLength
    ) {
      checkPhoneNum2Lenght.value = true;
      phoneNumber2ConflictMessage.value = '';
      return true;
    } else {
      checkPhoneNum2Lenght.value = false;
      phoneNumber2ConflictMessage.value = `(Enter a phone number between ${minPhoneNumberLength} and ${maxPhoneNumberLength} digits)`;
      return false;
    }
  }
};

    const deleteCustomerContactDetails = async() => {
      store.dispatch("setDeleteContactCustomerGUID", {customerGuid: customerDetails.value.customerGuid});
      store.dispatch("SetDeleteEditCustomerContactModal", { status: false});
      store.dispatch("setConfirmDeleteContactModal",{status:true});      
    };

    const onAddressInput = () => {
      address.value = address.value.substring(0,200);  
      address.value = address.value.replace(/\s+/g, ' ');
      //address.value= address.value.replace(/\s+/g, ' ').replace(/[^\w\s.,'-]/g, '');
    };    

    const validateEmail = () => {
      email.value = email.value.substring(0,150);     
      email.value = email.value.replace(/[^a-zA-Z0-9@._-]/g, '');
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email.value === "") {
        emailConflictMessage.value = "";
        email.value = email.value.trim();
        validEmail.value = true;
      } else {
        email.value = email.value.trim();

        if (emailRegex.test(email.value)) {
          emailConflictMessage.value = "";
          validEmail.value = true;
        } else {
          emailConflictMessage.value = "(Email is invalid)";
          validEmail.value = false;
        }
      }
    };

    const originalData = {
      firstName: customerDetails.value.firstName,
      lastName: customerDetails.value.lastName,
      email: customerDetails.value.email,
      phoneNumber1: customerDetails.value.phoneNumber1,
      phoneNumber2: customerDetails.value.phoneNumber2 || "",
      //selectedGender: customerDetails.value.gender === "M" ? "M" : "F",
      selectedGender: customerDetails.value.gender === "M" ? "M" : customerDetails.value.gender === "F" ? "F" : "N",
      address: customerDetails.value.address,
    };    

    const changesMade = computed(() => {
      return (
        firstName.value !== originalData.firstName ||
        lastName.value !== originalData.lastName ||
        email.value !== originalData.email ||
        phoneNumber1.value !== originalData.phoneNumber1 ||
        phoneNumber2.value !== originalData.phoneNumber2 ||
        selectedGender.value !== originalData.selectedGender ||
        address.value !== originalData.address
      );
    });

    return {
      isCallOngoing,
      firstName,
      lastName,
      email,
      phoneNumber1,
      phoneNumber2,
      selectedGender,
      address,
      updateCustomerDetails,
      validateFirstName,
      validateLastName,
      checkIfAnyFieldEmpty,
      checkFirstNameLenght,
      checkLastNameLenght,
      checkPhoneNumLenght,
      onPhoneNumber1Input,
      onPhoneNumber2Input,
      deleteCustomerContactDetails,
      
      onAddressInput,
      validateEmail,
      changesMade,
      phoneNumber1ConflictMessage,
      validEmail,emailConflictMessage,
      checkPhoneNum2Lenght,
      phoneNumber2ConflictMessage,
      firstNameConflictMessage,
      goBack

    };
  },
};
</script>
  <style scoped>
.required-msg {
  color: red;
}
.close-edit_modal-btn {
  margin-bottom: 0;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
::placeholder {
  color: rgb(215, 215, 215);
  font-size: 10px;
}
.no-resize {
  resize: none;
}
</style>