<template>
  <div>    
    <span :class="[onGoingCallStatus? 'duration':'d-none']">
    {{
      formattedDuration
    }}</span>
  </div>
</template>
  
  <script>
import store from "@/store";
import { ref, computed, watch, onBeforeUnmount } from "vue";

export default {
  name: "CallTimer",
  setup() {
    let onGoingCallStatus = computed(() => store.getters.isCallOngoing);    
    const timerRunning = computed(() => store.getters.getTimerStatus);
    const timerStopped = computed(() => store.getters.getStopTimerStatus);

    const timer = ref(0);
    let intervalRef = null;
    
    const startTimer = () => {
      if (!intervalRef) {
        intervalRef = setInterval(() => {
          timer.value++;
        }, 1000);
      }
    };

    const pauseTimer = () => {
      if (intervalRef) {
        clearInterval(intervalRef);
        intervalRef = null;
      }
    };
    
    const formattedDuration = computed(() => {
      const hours = String(Math.floor(timer.value / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((timer.value % 3600) / 60)).padStart(
        2,
        "0"
      );
      const seconds = String(timer.value % 60).padStart(2, "0");

      return `${hours}:${minutes}:${seconds}`;
    });
    
    watch(timerRunning, (newVal) => {      
      if (newVal) {
        startTimer();
      } else {
        pauseTimer();
      }
    });
    
    watch(timerStopped, (newVal) => {
      if (newVal===true) {
        timer.value = 0;
        stopTimer();
        
      }
    });
        
    watch(onGoingCallStatus, (newVal) => {
      if (!newVal) {
        resetTimer();
      }
    });

    const resetTimer = () => {
        timer.value = 0;
      };

    const stopTimer = () => {        
      if (!intervalRef) {        
        clearInterval(intervalRef);
        intervalRef = null;
        timer.value = 0; 
      }      
    };

    onBeforeUnmount(() => {
      if (intervalRef) {
        clearInterval(intervalRef);
      }
    });

    return {
      formattedDuration, 
      onGoingCallStatus,
    };
  },
};
</script>
  