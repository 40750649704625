<template>  
  <header class="main-header intial-state" style="width: 100%;">
    <div class="container-fluid" style="display: flex; justify-content: space-around;">
      <div class="header-inner-wrapper" style="justify-content: space-between;">
        <ToolsBar></ToolsBar>
        <div title ="Notification" :class="[isReady ?'': 'd-none','powerbtn-section']" :style="{ marginLeft: onGoingCallStatus ? '30%' : '50%' }" @click="openCloseNotificationModal">
          <a href="#">
            <img v-if="checkLenght" src="@/assets/images/bell-notification.svg" alt="bell notification icon">
            <img v-else src="@/assets/images/bell.svg" alt="bell icon">            
          </a>
        </div>        

      </div>
    </div>
  </header>          
  <div class="body-background-image">
      <img src="@/assets/images/bitcure-body-bg.svg" alt="">
    </div>  
</template>
<script>
import store from '@/store';
import {computed} from "vue";
import ToolsBar from './ToolsBar.vue';

export default {
  components: { 
    ToolsBar,     
  },
    name:"nav-bar",    
    setup() {   
      let isReady = computed(() => store.getters.isDeviceReady);
      let onGoingCallStatus = computed(() => store.getters.isCallOngoing);
      
      const openCloseNotificationModal =()=>{
        store.dispatch('setNotificationModal',{status:!store.getters.isNotificationModal});
      }

      let checkLenght = computed(() => {
        const missedCallList = store.getters.getMissedCallNotificationList;
        const voiceMailList = store.getters.getVoiceMailNotificationList;
        if((store.getters.getMissedCallNotificationList).length>0 || (store.getters.getVoiceMailNotificationList).length>0)
        {
          for (const missedCall of missedCallList) {
            if (!missedCall.IsEnabled) {              
              return true; 
            }
          }
          for (const voiceMail of voiceMailList) {
            if (!voiceMail.IsEnabled) {
              return true;
            }
          }
        }
        return false; 
      });


      return{
        openCloseNotificationModal,
        isReady,
        checkLenght,
        onGoingCallStatus
      }
    },
}
</script>
<style scoped>
.body-background-image{
  background: #ECEFF4;    
    display: flex;
    justify-content: center;
    align-items: center;    
}
.body-background-image img{  
  max-height: 100%;
}
</style>