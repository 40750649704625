import { $httpSignalR } from "@/axios";
import { HubConnectionBuilder, HttpTransportType, LogLevel } from "@microsoft/signalr";
import { toRaw } from "vue";

const state = {
  signalRConnection: '',
  signalRAgent: '',
  signalRMessage: '',
  controlOnTransferCall:true,
  transferingAgentName:'',
  voiceCallNotification:false,
  callerQueueIncomingPhonenNumber:'',  
  outboundCallAccepted:false
};

const getters = {
  getSignalRConnection: state => state.signalRConnection,
  getcontrolOnTransferCallStatus: state=>state.controlOnTransferCall,
  getTransferingAgentName :state=>state.transferingAgentName,
  getVoiceCallNotification :state=>state.voiceCallNotification,
  getCallerQueueIncomingPhonenUmber:state=>state.callerQueueIncomingPhonenNumber,  
  getOutBoundCallAccepted : state=>state.outboundCallAccepted,
};

const actions = {
  async initializeSignalR({commit,rootState, dispatch }) {
      const connection = new HubConnectionBuilder()
      .withUrl($httpSignalR+'notificationhub', 
      {  skipNegotiation:false
        ,transport:HttpTransportType.LongPolling|HttpTransportType.ServerSentEvents})
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();                  

    connection.on('ReceiveNotification', async (agent, message) => {      
      console.log("Check Rec:", agent, message.slice(0, 100));
      if(agent===rootState.devices.agent && message=== 'TRANFER_CALL_ACCEPTED_STATUS')
      {
        dispatch("setTransferCallAcceptedStatus",{status:true});
      }
      if(agent===rootState.devices.agent && message=== 'AGENT_DISCONNECT_CALL')
      {
         dispatch("setControlOnTransfer", {status: true});
         dispatch("setDisableConferenceButton",{status:false}); 
      }
      if(message === 'INCOMING_TRANSFER_CALL_UNHOLD')
      {
         dispatch("UpdateCustomerOnHoldStatus", {status: false});
      }
      if(message==='INCOMING_CALL_ALL_AGENTS_BUSY')
      {        
        commit('SET_CALLERQUEUE_PHONENUMBER',{agent});
      }
      if(message=== 'NEW_RECORDING_RECEIVED')
      {
        dispatch('showCallAndVoiceRecNotification',{status:true});
      }       
      else if(message === 'OUTBOUND_CALL_RECEIVED')
      { 
        commit('SET_OUTBOUND_CALL_RECEIVED',{status:true});                                                              
        dispatch('setEnableTransferCall',{status:true});
      }
      if(message === 'AGENTS_IN_CONFERENCE')
      {
        let phoneNumber = '+' + agent.trim();
        if(phoneNumber === rootState.devices.connection.parameters.From)
        {      
          const messageObject={action:message}
          dispatch('setConditions', {messageObject});
        }           
      }
      else
      {        
        const messageObject = JSON.parse(message);                   
        let agentName='';
        if(messageObject.action==="RESET_TRANSFERRED_AGENT_ON_DROP" && agent===rootState.devices.agent){          
          await dispatch("hangUpAgent", {CallSid:rootState.devices.connection.parameters.CallSid,incoming: false,value:rootState.users.user,line:"SR->77"});          
          await dispatch("setConnection", {connection: null,incoming: false,AgentIdentity:rootState.users.user});                                    
          await dispatch("setStopTimerStatus", { status: true });           
          await dispatch("hangUpClient", {connection: null,incoming: false,value:rootState.users.user,line:"SR->80"});          
          dispatch("setTimerStatus", { status: false });          
          dispatch("setTransferPopupModal",{status:false}); 
          dispatch("setPrimaryContactNumber",{contactNumber:""}); 
          dispatch("setTransferCallAccepted",{showTransferCallFeatures:false});
          dispatch('setEnableTransferCall',{status:false});  
          dispatch('setControlOnTransfer',{status:true});          
        }        
        if(messageObject.action==="CALL_ENDED")
        {           
          let phoneNumber = '+' + agent.trim();          
          if(rootState.devices.connection!==null){
            if(rootState.devices.connection.parameters.From===phoneNumber){                 
              await dispatch("hangUpAgent", {CallSid:rootState.devices.connection.parameters.CallSid,incoming: false,value:rootState.users.user,line:"SR->93"});
              await dispatch("hangUpClient", {connection: null,incoming: false,value:rootState.users.user,line:"SR->94"});               
            }
          }
          await dispatch('popIncomingCaller',{phoneNumber:phoneNumber,line:'SR-> 98'});   
                                      
        }
        if(messageObject.action==='POP_FROM_INCOMING_CALLERS_LIST')
        {                
          await dispatch('popIncomingCaller',{CallSid:messageObject.CallSid,line:'SR-> 103'});          
        } 
        if (agent.startsWith("client:"))
        {
          agentName = agent.substring("client:".length);
        }
        else{    
          agentName = agent
        }
  
        if(agentName===toRaw(rootState.devices.agent))
        {                        
              dispatch('setConditions', {messageObject});
        }
        else if(agentName!==toRaw(rootState.devices.agent) && (messageObject.from===rootState.devices.connection.parameters.From))
        {
          dispatch('setConditions', {messageObject});          
        }
      }
    });

    try {
      await connection.start();
      console.log('SignalR connected!');
      commit("SET_SIGNAL_CONNECTION", connection);

    } catch (error) {
      console.error('SignalR connection failed:', error);
    }
    commit('');
  },

  setoutboundCallRecieved({commit},payload){
    commit('SET_OUTBOUND_CALL_RECEIVED',{status:payload.status});
  },

  showCallAndVoiceRecNotification({commit},payload){
    commit("SET_VOICE_CALL_RECORDING_NOTIFICATION",payload.status);
  },
  async sendNotification({ commit, state }, payload) {        
    const response = toRaw(state.signalRConnection);
    if (response && response.state === "Connected") {
        const messageString = JSON.stringify(payload.message);
      response.invoke("SendNotification", payload.agent,messageString);
    }
    else {
      console.log("connection closed");
    }
    commit('');
  },
  async setConditions({ dispatch, commit ,rootState}, { messageObject }) {                      
      if (messageObject.action === 'INCOMING_TRANSFER_CALL_WARM_TRANSFER') 
      {   
        if(messageObject.transferingAgentName!==rootState.devices.agent)
        {
          commit('SET_CONTROL_ON_TRANSFER_CALL', false); 
        }                     
        commit('SET_TRANSFERING_AGENT_INFORMATION', messageObject.transferingAgentName);         
      } 
      else if (messageObject.action === 'INCOMING_TRANSFER_CALL_COMPLETE_TRANSFER')
       {
        commit('SET_CONTROL_ON_TRANSFER_CALL', true);      
        await dispatch("UpdateCustomerOnHoldStatus", {status: messageObject.callOnHoldStatusValue});                      
        }
      else if(messageObject.action === 'DISCONNECT_WARM_TRANSFERCALL')
      {              
        await dispatch("setTransferPopupModal", { status: false });                   
        await dispatch("setTransferCallAccepted",{showTransferCallFeatures:false});
        await dispatch("setConferenceBtnDisabled",{status:false})
        //hangupCall only when not warm transfer call and 
        // if(state.controlOnTransferCall){
        //   await dispatch("hangUpCall", {connection: rootState.devices.connection,incoming: false,agentIdentity: rootState.devices.agent});                                     
        // }        
      }
      else if(messageObject.action === 'INCOMING_WARM_TRANSFER_CALL_ACCEPTED')
      {        
        await dispatch("setTransferCallAccepted", { showTransferCallFeatures: messageObject.showTransferCallFeatures });  
        await dispatch("setTransferCallAcceptedStatus",{status:true});        
      }     
      else if(messageObject.action==='AGENTS_IN_CONFERENCE')
      {
        await dispatch("UpdateCustomerOnHoldStatus", {status: true });
      } 
      else if(messageObject.action==='INCOMING_CALL_ACCEPTED')
      {   
        let phNumber=rootState.devices.connection.parameters.From;   
        let agentIdentity=rootState.devices.agent; 
        let currentConnection= rootState.devices.connection;  
        if(messageObject.agent!==toRaw(agentIdentity) && (messageObject.from===phNumber))
        {
          await dispatch("callHoldOrRelease", {connection:currentConnection ,status: true,line:"SR->196"});
        }
      }
      else if(messageObject.action ==='UNANSWERED_CALL')
      {    
        await dispatch('popIncomingCaller',{CallSid:messageObject.callSid,line:'SR-> 198'});            
      }
      else if(messageObject.action==='UNANSWERED_WARM_TRANSFER'){
        let currentConnection= rootState.devices.connection; 
        await dispatch('setTransferPopupModal',{status:false});     
        await dispatch("setTransferCallAccepted",{showTransferCallFeatures:false});
        await dispatch("callHoldOrRelease", {connection:currentConnection ,status: false,line:"SR->202"});
      }
      else if(messageObject.action==='WARM_TRANSFER_CALL_SID'){
        await dispatch('setWarmTransferCallSID',{warmCallSid:messageObject.callSid});
      }
  },
  setControlOnTransfer({ commit}, payload) 
  { 
    commit('SET_CONTROL_ON_TRANSFER_CALL',payload.status);
  }  
  
};

const mutations = {
  SET_SIGNAL_CONNECTION(state, response) {
    state.signalRConnection = response;   
  },
  SET_CONTROL_ON_TRANSFER_CALL(state,response){
    state.controlOnTransferCall=response
  },
  SET_TRANSFERING_AGENT_INFORMATION(state,response){
    state.transferingAgentName=response
  },
  SET_VOICE_CALL_RECORDING_NOTIFICATION(state,response){
    state.voiceCallNotification=response
  },
  SET_CALLERQUEUE_PHONENUMBER(state,response){
    state.callerQueueIncomingPhonenNumber=response.agent    
  },
  SET_OUTBOUND_CALL_RECEIVED(state,response){
    state.outboundCallAccepted=response
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};