<template>
<div class="modal fade modal-styles info-not-found-modal deletecontactmodal show" id="deletecontactmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style="display: block;">
            <div class="modal-dialog">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cancelDelete"><i class="fas fa-times"></i></button>
                <div class="modal-content">

                    <div class="info-notfound-inner">
                        <span><i class="fas fa-trash"></i></span>
                        <p style="font-size: 12px;">Are you sure, you want to Delete the Contact?</p>
                        <div class="button-wrapper">
                            <a href="#" class="theme-button light-btn" @click="cancelDelete">Cancel</a>
                            <a href="#" class="theme-button dark-btn" @click="confirmDelete">Confirm </a>
                           
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- deletecontact modal ends here  -->
</template>
<script>
import store from '@/store';
export default {
    name:'confirm-delexte-contact-modal',
    setup() {

        const confirmDelete=()=>{
            store.dispatch("deleteCustomerDetails", {customerGuid: store.getters.getDeleteContactCustomerGUID});  
            store.dispatch("setConfirmDeleteContactModal",{status:!store.getters.isConfirmDeleteContactModal});    
        }
        const cancelDelete=()=>{
            store.dispatch("setDeleteContactCustomerGUID", {customerGuid: ""});
            store.dispatch("setConfirmDeleteContactModal",{status:!store.getters.isConfirmDeleteContactModal});               
            store.dispatch("SetDeleteEditCustomerContactModal", { status: true});    
        }
        
        return {
            confirmDelete,
            cancelDelete
        }
        
    },
}
</script>
<style scoped>

.modal-dialog{    
    box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3)
}
.modal-content{
    margin-top: 100px;
}
</style>