<template>
    <div
    :class="[isCallOngoing?'readjust':'','search-for-agents']"
      style="box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3)"
    >
      <div class="dial-and-contact-wrapper position-absolute" style="box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3)">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              :class="[!isAddAgentClicked ? 'active' : '', 'nav-link']"
              id="contactlist-tab"
              data-bs-toggle="tab"
              data-bs-target="#contactlist"
              type="button"
              role="tab"
              aria-controls="contactlist"
              aria-selected="false"
              @click="ChangeIsDiallerStatus('contactList')"
            >
              Add Agent
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              :class="[isAddAgentClicked ? 'active' : '', 'nav-link']"
              id="dial-tab"
              data-bs-toggle="tab"
              data-bs-target="#dial"
              type="button"
              role="tab"
              aria-controls="dial"
              aria-selected="true"
              @click="ChangeIsDiallerStatus('dialler')"
            >
              Agent List
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <!-- for contact listing -->
          <div v-if="!isAddAgentClicked">
            <AddNewAgentModal></AddNewAgentModal>
          </div>
  
          <!-- for dialing area  -->
          <div v-if="isAddAgentClicked">
            <AllAgentList></AllAgentList>
          </div>
        </div>
        <!-- dial and contact section ends -->
      </div>
    </div>
  </template>
  <script>
  import { computed,ref} from "vue";
  import store from "@/store";  
import AddNewAgentModal from "./AddNewAgentModal.vue";
import AllAgentList from "./AllAgentList.vue";
  export default {
    name: "dialler-and-contactList-modal",
    components: {
    AddNewAgentModal,
    AllAgentList
},
    setup() {                
  
      let isCallOngoing=computed(() => store.getters.isCallOngoing);
      let clearInput=ref(true);
      let isAddAgentClicked = computed(() => store.getters.isShowDialler);
      const closeDialler=()=>{
          store.dispatch("setDiallerAndContactListModal",{status:false})
      }
  
      const ChangeIsDiallerStatus = (type) => {
        switch (type) {
          case "dialler":
            store.dispatch("setShowDialler", { status: true });   
            store.dispatch("setAgentsList");       
            break;
          case "contactList":
            store.dispatch("setShowDialler", { status: false });
            break;
        }
      };
      
  
  
      return {
          closeDialler,
        ChangeIsDiallerStatus,
        isAddAgentClicked,
        clearInput,
        isCallOngoing
      };
    },
  };
  </script>