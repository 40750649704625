import {$http} from "@/axios";
const state = {
    callRecordings: [], 
    voiceMailRecordings:[],
    voiceRecordingPageNumber:0,
    callRecordingPageNumber:0,
    limitPerPageCallRecording:4,    
    limitPerPageVoiceRecording:4,
    totalCallRecordings:0,
    totalVoiceMailRecordings:0,
  };
  
  const getters = {
    getCallRecordings: state => state.callRecordings,
    getVoiceMailRecordings:state=>state.voiceMailRecordings,
    getVoiceRecordingPageNumber:state =>state.voiceRecordingPageNumber,
    getCallRecordingPageNumber:state =>state.callRecordingPageNumber,
    getLimitPerPageCallRecording:state =>state.limitPerPageCallRecording,
    getLimitPerPageVoiceRecording:state=>state.limitPerPageVoiceRecording,
    getTotalCallRecordings:state=>state.totalCallRecordings,
    getTotalVoiceMailRecordings:state=>state.totalVoiceMailRecordings,

  };
  
  const actions = {   

    resetCallAndVoiceRecordingState({commit}){
      commit('RESET_CALLANDVOICEMAILRECORDING_STATE');
    },

    async setCallRecordingsList({commit},payload) {     

        const response = await $http.post("Call/GetCallRecording",{
          "offSet":payload.offSet,
          "limit":payload.limit,
          "name":payload.name,
          "phoneNumber":payload.phoneNumber,
          "date":payload.date
        });               
        commit("SET_CALL_RECORDING_LIST", response.data);                            
    },
    async emptyCallRecordingList({commit}){
      commit('EMPTY_CALL_REC_LIST');
    },
    async emptyVoiceMailRecordingList({commit}){
      commit('EMPTY_VOICE_REC_LIST');
    },
    async setVoiceMailRecordingList({commit},payload) {               
        const response = await $http.post("Call/GetVoiceMailRecording",{
          "offSet":payload.offSet,
          "limit":payload.limit,
          "name":payload.name,
          "phoneNumber":payload.phoneNumber,
          "date":payload.date
        });                   
        commit("SET_VOICEMAIL_RECORDING_LIST", response.data);                                               
    },
    setVoiceRecordingPageNumber({commit},payload){
      commit("SET_VOICE_RECORDING_PAGE_NUMBER",payload.pageNumber);
    },
    setCallRecordingPageNumber({commit},payload){
      commit("SET_CALL_RECORDING_PAGE_NUMBER",payload.pageNumber);
    },
    async setTotalCallRecordings({commit},payload){
      const response=await $http.get("Call/GetRecordingCount",      {
        params: {
          RecordingType:"Call",
          name:payload.name,
          date:payload.date,
          phoneNumber:payload.phoneNumber
        }
      }
    );      
      commit('SET_TOTAL_CALL_RECORDING',response.data)      
    },
    async setTotalVoiceMailRecordings({commit},payload){
      const response=await $http.get("Call/GetRecordingCount",
      {
        params: {
          RecordingType:"VoiceMail",
          name:payload.name,
          date:payload.date,
          phoneNumber:payload.phoneNumber
        }
      }
      );
      console.log("VoiceMailLogsCount:",response.data)
      commit('SET_TOTAL_VOICE_MAIL_RECORDING',response.data);      
    }
  };
  
  const mutations = {

    RESET_CALLANDVOICEMAILRECORDING_STATE(state) {      
      Object.assign(state, {        
        callRecordings: [], 
        voiceMailRecordings:[],
        voiceRecordingPageNumber:0,
        callRecordingPageNumber:0,
        limitPerPageCallRecording:5,    
        limitPerPageVoiceRecording:5,
        totalCallRecordings:0,
        totalVoiceMailRecordings:0,

      })
    },

    SET_CALL_RECORDING_LIST(state, response) {      
      state.callRecordings=[]      
      state.callRecordings = response;                  
    },   
    SET_VOICEMAIL_RECORDING_LIST(state,response){
      state.voiceMailRecordings=[]
      state.voiceMailRecordings= response             
    },
    SET_VOICE_RECORDING_PAGE_NUMBER(state,response){
      state.voiceRecordingPageNumber=response
    },
    SET_CALL_RECORDING_PAGE_NUMBER(state,response){
      state.callRecordingPageNumber=response
    },   
    SET_TOTAL_CALL_RECORDING(state,response){
      state.totalCallRecordings=response
    },
    SET_TOTAL_VOICE_MAIL_RECORDING(state,response){
      state.totalVoiceMailRecordings=response
    },
    EMPTY_VOICE_REC_LIST(state){
      state.voiceMailRecordings=[]
    }, 
    EMPTY_CALL_REC_LIST(state){
      state.callRecordings=[] 
    } 
  };
  
  export default {
    state,
    getters,
    actions,
    mutations,
  };
  