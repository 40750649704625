<template>
  <div id="app">
    <Vidle @idle="onidle" :duration="120" class="hide-timer" />
    <SessionTimeOutLogOut v-if="showSessionTimeOutmodal"></SessionTimeOutLogOut>    
    <router-view></router-view>
  </div>
</template>

<script>
import Vidle from 'v-idle'
import {onMounted,computed} from 'vue';
import store from './store';
import SessionTimeOutLogOut from './modals/SessionTimeOutLogOut.vue';
export default {
  name: 'App',
  components:{
    Vidle,
    SessionTimeOutLogOut
  },
  setup(){   
    const showSessionTimeOutmodal=computed(()=>store.getters.issessionTimeOutModal);     
    let onGoingCallStatus = computed(() => store.getters.isCallOngoing);
    let isIncoming = computed(() => store.getters.incomingState);

        onMounted(() => {          
          const params = new URLSearchParams(window.location.search);          
          const param1 = params.get("param1");                    

          login(param1);      
          store.dispatch('setMissedCallNotificationList',{agentIdentity:param1});
          store.dispatch('setVoiceMailNotificationList',{agentIdentity:param1});          
        });

        const login= async(agentName) =>{
          try{                
                await store.dispatch('login',{user:agentName});                 
                store.commit('SET_LOGIN_MODAL', false);                   
            }catch(error){
                console.log(error)
            } 
        } 
        
        const onidle=()=>{

          if(store.getters.isCurrentAgentOnline){
            if(!isIncoming.value && !onGoingCallStatus.value)
            {
              store.dispatch('SetAgentOffline',{agentIdentity:store.getters.getUser}); 
              if(store.getters.currentDevice!=null)
              {
                store.getters.currentDevice.unregister();               
                store.getters.currentDevice.on('unregistered', () => {
                  console.log('The device is no longer able to receive calls.')
                }); 
              }
              store.dispatch('resetDeviceState');
              store.dispatch('resetModalState');
              store.dispatch('resetUserState');
              store.dispatch("setSessionTimeOutLogOutModal",{status:true});   
          } 
            }
                   
        }

        const handleBeforeUnload = async() => {                      
          store.getters.getSignalRConnection.invoke("LogoutAgent", store.getters.getUser);        
          store.dispatch('SetAgentOffline',{agentIdentity:store.getters.getUser}); 
          store.getters.currentDevice.unregister();               
          store.getters.currentDevice.on('unregistered', () => {
            console.log('The device is no longer able to receive calls.')
          }); 

          store.dispatch('resetDeviceState');
          store.dispatch('resetModalState');
          store.dispatch('resetUserState');

        };    
        
        window.addEventListener('beforeunload', handleBeforeUnload);

      return{
        handleBeforeUnload,
        onidle,
        showSessionTimeOutmodal
      }
    }    

}

  
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.hide-timer {
    display: none;
}
</style>
<style>
@import './assets/scss/bootstrap.min.css';
@import './assets/scss/main.css';
@import './assets/scss/custom.css';
</style>
