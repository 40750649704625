<template>
  <a
    href="#"
    :class="[
      isReady ? '' : 'd-none',
      'add-contact me-3',
      isCustomerNotSaved ? 'notify' : '',
    ]"
    data-bs-toggle="modal"
    data-bs-target="#addcontactmodal"
    @click="OpenAddCustomerContactModal"
  >
    <i class="fa-solid fa-user-plus"></i>Contact
  </a>
  <div class="call-outer-wrapper">
    <div class="call-actions-wrapper ongoing-call">
      <ul class="list-unstyled mb-0 p-0 d-flex">
        <li class="call-icon">
          <a
            href="#"
            :class="[isIncoming ? 'ring' : '']"
            :title="
              isReady ? (onGoingCallStatus ? 'Busy' : 'Active') : 'Offline'
            "
            ><i
              :class="[
                !isReady && !onGoingCallStatus
                  ? 'gold'
                  : isReady && onGoingCallStatus
                  ? 'red'
                  : 'green',
                'fas fa-user',
              ]"
            ></i
          ></a>
          <a
            href="#"
            :class="[isReady ? 'd-none' : '', 'theme-button green-btn']"
            @click="onReadyClick"
            style="text-decoration: none"
            >Ready</a
          >
          <CallTimer></CallTimer>
        </li>
        <li
          :class="[!isReady ? 'disbale-item' : 'activate-items', 'calculator']"
          title="Dialer and Contact"
        >
          <a href="#" @click="OpenDialerAndContactListModal"
            ><i class="fas fa-calculator"></i
          ></a>
        </li>

        <li
          :class="[
            onGoingCallStatus && !callOnHoldStatus
              ? hasControlOnTransferCall
                ? activateHoldBtns || isOutboundCallAccepted
                  ? 'activate'
                  : 'disbale-item'
                : 'disbale-item'
              : 'd-none',
          ]"
          title="Hold or Release"
        >
          <a href="#" @click="CallOnHoldOrRelease(true)"
            ><i class="fa-solid fa-pause"></i
          ></a>
        </li>
        <li
          :class="[
            onGoingCallStatus && callOnHoldStatus
              ? hasControlOnTransferCall
                ? activateHoldBtns || isOutboundCallAccepted
                  ? 'activate'
                  : 'disbale-item'
                : 'disbale-item'
              : 'd-none',
          ]"
          title="Hold or Release"
        >
          <a href="#" @click="CallOnHoldOrRelease(false)"
            ><i class="fas fa-play btn-enabled"></i
          ></a>
        </li>

        <li
          :class="[
            onGoingCallStatus && !muteStatus ? 'activate-item' : 'd-none',
          ]"
          title="Mute Or Unmute"
        >
          <a href="#" @click="MuteOrUnmuteCall(true)"
            ><i class="fa-solid fa-microphone"></i
          ></a>
        </li>
        <li
          :class="[
            onGoingCallStatus && muteStatus ? 'activate-item' : 'd-none',
          ]"
          title="Mute Or Unmute"
        >
          <a href="#" @click="MuteOrUnmuteCall(false)"
            ><i class="fas fa-microphone-slash btn-enabled"></i>
          </a>
        </li>

        <li
          :class="[
            isIncoming && !onGoingCallStatus ? 'activate-item' : 'disbale-item',
            'call-icon-2',
          ]"
          title="Receive Call"
        >
          <a href="#" @click="OnRecieveCallClick"
            ><i class="fas fa-phone"></i
          ></a>
        </li>
        <li
          :class="[
            isIncoming || onGoingCallStatus ? 'activate-item' : 'disbale-item',
            'phone-slash',
          ]"
          title="Hangup call"
        >
          <a href="#" @click="OnDisconnectCallClick"
            ><i class="fas fa-phone-slash"></i
          ></a>
        </li>

        <li
          :class="[
            onGoingCallStatus
              ? 'activate-item'
              : isReady
              ? 'activate-item'
              : 'disbale-item',
            'arrows-left-right',
          ]"
          title="Transfer"
        >
          <a href="#" @click="OpenTransferCallModal"
            ><i class="fa-solid fa-arrow-right-arrow-left"></i
          ></a>
        </li>

        <li
          :class="[
            isReady && !onGoingCallStatus ? 'activate-item' : 'disbale-item',
            'mug-icon',
          ]"
          title="Break"
        >
          <a href="#" @click="OpenBreakModal"
            ><i class="fa-solid fa-mug-hot"></i
          ></a>
        </li>
        <li
          :class="[
            isAgentAdmin ? (isReady ? '' : 'disbale-item') : 'd-none',
            'users-icon',
          ]"
          title="Add agent"
        >
          <a href="#" @click="OpenAgentModal"
            ><i class="fa-solid fa-users"></i
          ></a>
        </li>
        <li
          :class="[isReady ? '' : 'disbale-item', 'voicemail']"
          title="Call and Voicemail Recording"
        >
          <a href="#" @click="OpenCallAndVoiceMailRecordingModal">
            <i class="fa-solid fa-voicemail"></i>
          </a>
        </li>
      </ul>
    </div>
    <TransferCallModal v-if="showTransferCallModal"></TransferCallModal>
    <DiallerAndContactListModal
      v-if="showDiallerAndContactListModal"
    ></DiallerAndContactListModal>
    <BreakModal v-if="showBreakModal"></BreakModal>
    <AgentDetailsModal v-if="showAgentModal"></AgentDetailsModal>
    <TransferPopUpModal v-if="showTransferPopUpModal"></TransferPopUpModal>
    <CallAndVoiceMailRecording
      v-if="showCallVoiceMailRecording"
    ></CallAndVoiceMailRecording>
    <AddEditContactFormModal
      v-if="showAddEditContactFormModal"
    ></AddEditContactFormModal>
    <DeleteEditContactModal
      v-if="showDeleteEditContactFormModal"
    ></DeleteEditContactModal>
    <ConfirmDeleteContactModal
      v-if="showConfirmDeleteContactModal"
    ></ConfirmDeleteContactModal>
    <ConfirmDeleteAgentModal
      v-if="showConfirmDeleteAgentModal"
    ></ConfirmDeleteAgentModal>
    <EditAgentModal v-if="showEditAgentModal"></EditAgentModal>
    <CallerQueueModal v-if="showCallerQueueModal"></CallerQueueModal>
    <CallLog v-if="showCallLogModal"></CallLog>
    <NotificationModal v-if="showNotificationModal"></NotificationModal>
    <ConfirmToSaveNewContactNumberModal
      v-if="showConfirmToSaveNewContactNumberModal"
    ></ConfirmToSaveNewContactNumberModal>
  </div>
</template>
<script>
import store from "@/store";
import CallTimer from "./CallTimer.vue";
import TransferCallModal from "@/modals/TransferCallModal.vue";
import DiallerAndContactListModal from "@/modals/DiallerAndContactListModal.vue";
import BreakModal from "@/modals/BreakModal.vue";
import AgentDetailsModal from "@/modals/AgentDetailsModal.vue";
import TransferPopUpModal from "@/modals/TransferPopUpModal.vue";
import CallAndVoiceMailRecording from "@/modals/CallAndVoiceMailRecording.vue";
import AddEditContactFormModal from "@/modals/AddEditContactFormModal.vue";
import DeleteEditContactModal from "@/modals/DeleteEditContactModal.vue";
import ConfirmDeleteContactModal from "@/modals/ConfirmDeleteContactModal.vue";
import CallerQueueModal from "@/modals/CallerQueueModal.vue";
import ConfirmDeleteAgentModal from "@/modals/ConfirmDeleteAgentModal.vue";
import EditAgentModal from "@/modals/EditAgentModal.vue";
import CallLog from "@/modals/CallLog.vue";
import NotificationModal from "@/modals/NotificationModal.vue";
import ConfirmToSaveNewContactNumberModal from "@/modals/ConfirmToSaveNewContactNumberModal.vue";
import { computed, isProxy, toRaw, watch } from "vue";

export default {
  name: "tool-bar",
  components: {
    CallTimer,
    TransferCallModal,
    DiallerAndContactListModal,
    BreakModal,
    AgentDetailsModal,
    TransferPopUpModal,
    CallAndVoiceMailRecording,
    AddEditContactFormModal,
    DeleteEditContactModal,
    ConfirmDeleteContactModal,
    ConfirmDeleteAgentModal,
    CallerQueueModal,
    EditAgentModal,
    CallLog,
    NotificationModal,
    ConfirmToSaveNewContactNumberModal,
  },
  setup() {
    const user = computed(() => store.getters.getUser);
    let isAgentAdmin = computed(() => store.getters.isAgentAdmin);
    let isReady = computed(() => store.getters.isDeviceReady);
    let onGoingCallStatus = computed(() => store.getters.isCallOngoing);
    let isIncoming = computed(() => store.getters.incomingState);
    let callOnHoldStatus = computed(() => store.getters.isCallOnHold);
    let muteStatus = computed(() => store.getters.isCallMuted);
    let timerStatus = computed(() => store.getters.getTimerStatus);
    let showTransferCallModal = computed(
      () => store.getters.isTransferCallModal
    );
    let showDiallerAndContactListModal = computed(
      () => store.getters.isDiallerAndContactListModal
    );
    let showBreakModal = computed(() => store.getters.isBreakModalOpen);
    let showAgentModal = computed(() => store.getters.isAgentModalOpen);
    let showTransferPopUpModal = computed(
      () => store.getters.isTransferPopUpModal
    );
    let showCallVoiceMailRecording = computed(
      () => store.getters.isCallAndVoiceMailRecordingModalOpen
    );
    let showAddEditContactFormModal = computed(
      () => store.getters.isAddCustomerContactModal
    );
    let showDeleteEditContactFormModal = computed(
      () => store.getters.isDeleteEditContactFormModal
    );
    let showConfirmDeleteContactModal = computed(
      () => store.getters.isConfirmDeleteContactModal
    );
    let showConfirmToSaveNewContactNumberModal = computed(
      () => store.getters.isConfirmToSaveNewContactNumberModal
    );
    let isContactSaved = computed(() => {
      if (store.getters.getCallingCustomerName !== "") {
        return true;
      } else {
        return false;
      }
    });
    let hasControlOnTransferCall = computed(
      () => store.getters.getcontrolOnTransferCallStatus
    );
    let incomingConnections = [];
    let showCallerQueueModal = computed(() => store.getters.isCallerQueueModal);
    let showConfirmDeleteAgentModal = computed(
      () => store.getters.isConfirmDeleteAgentModal
    );
    let showEditAgentModal = computed(() => store.getters.isEditAgentModal);
    let conferenceBtnIsDisabled = computed(
      () => store.getters.isConferenceBtnDisabled
    );
    let isCustomerNotSaved = computed(
      () => store.getters.getIsCustomerNotSaved
    );
    let currentConnection = computed(() => store.getters.currentConnection);
    let showCallLogModal = computed(() => store.getters.isCallLogModal);
    let showNotificationModal = computed(
      () => store.getters.isNotificationModal
    );

    const onReadyClick = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const agentName = params.get("param1");
        await store.dispatch("login", { user: agentName });
        store.commit("SET_LOGIN_MODAL", false);
      } catch (error) {
        console.log(error);
      }

      try {
        await store.dispatch("getTwilioToken", user);
        store.getters.currentDevice.register();
        store.getters.currentDevice.on("registered", () => {
          console.log("The device is ready to receive incoming calls.");
        });

        await store.dispatch("initializeSignalR");

        await store.getters.currentDevice.on("incoming", async (call) => {
          await store.dispatch("pushIncomingCaller", { connection: call });

          if (store.getters.currentConnection === null) {
            await store.dispatch("setConnection", {
              connection: call,
              incoming: true,
              AgentIdentity: store.getters.getUser,
              line: "TB->297",
            });

            // store.getters.currentConnection.on("accept", async () => {
            //   console.log('h1');
            //   if (store.getters.currentConnection.customParameters) {
            //     if (store.getters.currentConnection.customParameters.get("isWarmCall") === "true")
            //     {
            //       store.dispatch("UpdateCustomerOnHoldStatus", {status: true,});
            //       store.dispatch("sendNotification", {
            //         agent: store.getters.currentConnection.customParameters.get(
            //           "transferringAgentIdentity"
            //         ),
            //         message: {
            //           action: "INCOMING_WARM_TRANSFER_CALL_ACCEPTED",
            //           showTransferCallFeatures: true,
            //           connection: store.getters.currentConnection,
            //         },
            //       });
            //     } else {
            //       console.log('h2');
            //       store.dispatch("sendNotification", {
            //         agent: store.getters.currentConnection.parameters.To,
            //         message: {
            //           action: "INCOMING_CALL_ACCEPTED",
            //           from: store.getters.currentConnection.parameters.From,
            //           agent: store.getters.currentAgent,
            //         },
            //       });
            //     }
            //   }

            //   store.dispatch("setStopTimerStatus", { status: false });
            //   store.dispatch("setTimerStatus", { status: true });
            //   store.dispatch("setIsCallOnGoing", { onGoingCall: true });
            //   store.dispatch("setIncoming", { status: false, line:"TB->375" });
            //   await store.dispatch("sendNotification", {
            //     agent: "AllAgents",
            //     message: {
            //       action: "POP_FROM_INCOMING_CALLERS_LIST",
            //       CallSid: store.getters.currentConnection.parameters.CallSid,
            //     },
            //   });
            //   if (store.getters.getCallingCustomerName === "Unknown") {
            //     const saveContact = confirm(
            //       "Customer contact is not saved. Would you like to save it ?"
            //     );
            //     if (saveContact) {
            //       OpenAddCustomerContactModal();
            //     }
            //   }
            // });

            store.getters.currentConnection.on("disconnect", async () => {
              transferControlBeforeDisconnect();
              store.dispatch("popIncomingCaller", {
                CallSid: store.getters.currentConnection.parameters.CallSid,
                line: "TB-> 352",
              });
              await store.dispatch("hangUpCall", {
                CallSid: store.getters.currentConnection.parameters.CallSid,
                incoming: false,
                agentIdentity: store.getters.getUser,
                line: "TB->356",
              });
              await store.dispatch("hangUpClient", {
                connection: null,
                incoming: false,
                value: store.getters.getUser,
                line: "TB->362",
              });
              ResetOnDisconnect();
            });

            store.getters.currentConnection.on("reject", async () => {
              store.dispatch("popIncomingCaller", {
                CallSid: store.getters.currentConnection.parameters.CallSid,
                line: "TB-> 368",
              });
              if (
                store.getters.currentConnection.customParameters.get(
                  "isWarmCall"
                ) === "true"
              ) {
                store.dispatch("sendNotification", {
                  agent: store.getters.currentConnection.customParameters.get(
                    "transferringAgentIdentity"
                  ),
                  message: {
                    action: "DISCONNECT_WARM_TRANSFERCALL",
                    line: "TB->375",
                  },
                });
              }
              await store.dispatch("hangUpAgent", {
                CallSid: store.getters.currentConnection.parameters.CallSid,
                incoming: false,
                value: store.getters.getUser,
                line: "TB->383",
              });
              ResetOnDisconnect();
            });

            store.getters.currentConnection.on("cancel", async () => {
              store.dispatch("popIncomingCaller", {
                CallSid: store.getters.currentConnection.parameters.CallSid,
                line: "Toolbar-> 278",
              });
              if (!onGoingCallStatus.value) {
                await store.dispatch("hangUpClient", {
                  connection: null,
                  incoming: false,
                  value: store.getters.getUser,
                  line: "TB->398",
                });
                ResetOnDisconnect();
              }
            });
          }
          if (
            store.getters.getLengthOfCallerQueueList >= 1 &&
            store.getters.currentConnection.parameters.CallSid !==
              call.parameters.CallSid
          ) {
            store.dispatch("setCallerQueueModal", { status: true });
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    const OnRecieveCallClick = async () => {
      if (store.getters.incomingState) {
        await store.getters.currentConnection.accept();
        store.getters.currentConnection.on("accept", async () => {
          if (store.getters.currentConnection.customParameters) {
            if (
              store.getters.currentConnection.customParameters.get(
                "isWarmCall"
              ) === "true"
            ) {
              store.dispatch("UpdateCustomerOnHoldStatus", { status: true });
              store.dispatch("sendNotification", {
                agent: store.getters.currentConnection.customParameters.get(
                  "transferringAgentIdentity"
                ),
                message: {
                  action: "INCOMING_WARM_TRANSFER_CALL_ACCEPTED",
                  showTransferCallFeatures: true,
                  connection: store.getters.currentConnection,
                },
              });
            } else {
              store.dispatch("sendNotification", {
                agent: store.getters.currentConnection.parameters.To,
                message: {
                  action: "INCOMING_CALL_ACCEPTED",
                  from: store.getters.currentConnection.parameters.From,
                  agent: store.getters.currentAgent,
                },
              });
            }
          }

          store.dispatch("setStopTimerStatus", { status: false });
          store.dispatch("setTimerStatus", { status: true });
          store.dispatch("setIsCallOnGoing", { onGoingCall: true });
          store.dispatch("setIncoming", { status: false, line: "TB->375" });

          // await store.dispatch("sendNotification",{agent:"AllAgents",message:{action:'POP_FROM_INCOMING_CALLERS_LIST',CallSid:store.getters.currentConnection.parameters.CallSid}})
          //     if (store.getters.getCallingCustomerName === 'Unknown') {
          //       const saveContact = confirm('Customer contact is not saved. Would you like to save it ?');
          //       if (saveContact) {
          //         await store.dispatch("SetAddCustomerContactModal", { status: true});
          //       }
          //     }
          await store.dispatch("sendNotification", {
            agent: "AllAgents",
            message: {
              action: "POP_FROM_INCOMING_CALLERS_LIST",
              CallSid: store.getters.currentConnection.parameters.CallSid,
            },
          });

          if (store.getters.getCallingCustomerName === "Unknown") {
            const saveContact = confirm(
              "Customer contact is not saved. Would you like to save it ?"
            );
            if (saveContact) {
              await store.dispatch("SetAddCustomerContactModal", {
                status: true,
              });
            }
          }
        });
        if (isProxy(store.getters.currentConnection)) {
          const rawData = toRaw(
            store.getters.currentConnection.parameters.From
          );
          store.dispatch("setPrimaryContactNumber", { contactNumber: rawData });
          store
            .dispatch("isCustomerKnown", { phoneNumber: rawData })
            .then(() => {
              alert(
                "Incoming Call from " +
                  rawData +
                  " - " +
                  store.getters.getCallingCustomerName
              );
            });
        }
      }
    };

    const transferControlBeforeDisconnect = async () => {
      if (store.getters.getTransferCallAccepted) {
        await store.dispatch("sendNotification", {
          agent: store.getters.getTranferedCallToAgentIdentity,
          message: {
            action: "INCOMING_TRANSFER_CALL_COMPLETE_TRANSFER",
            callOnHoldStatusValue: store.getters.isCallOnHold,
          },
        });
        await store.dispatch("setTransferPopupModal", { status: false });
        await store.dispatch("setTransferCallAccepted", {
          showTransferCallFeatures: false,
        });
        await store.dispatch("setConferenceBtnDisabled", { status: false });
      }
    };
    const ResetOnDisconnect = async () => {
      transferControlBeforeDisconnect();
      store.dispatch("setIsCallOnGoing", { onGoingCall: false });
      store.dispatch("setStopTimerStatus", { status: true });
      store.dispatch("setTimerStatus", { status: false });
      store.dispatch("setTransferPopupModal", { status: false });
      store.dispatch("setPrimaryContactNumber", { contactNumber: "" });
      store.dispatch("setEnableTransferCall", { status: false });
      store.dispatch("setControlOnTransfer", { status: true });
      store.dispatch("setTransferCallAcceptedStatus", { status: false });
      store.dispatch("setoutboundCallRecieved", { status: false });
      await store.dispatch("setConnection", {
        connection: null,
        incoming: false,
        AgentIdentity: store.getters.getUser,
        line: "TB->475",
      });
    };

    const OnDisconnectCallClick = async () => {
      //When call is ongoing
      if (store.getters.isCallOngoing) {
        if (store.getters.currentConnection.customParameters) {
          //When agent recieved a warm transfer call and has controll on the call
          if (
            store.getters.currentConnection.customParameters.get(
              "isWarmCall"
            ) === "true" &&
            store.getters.getcontrolOnTransferCallStatus
          ) {
            await store.dispatch("sendNotification", {
              agent: store.getters.currentConnection.customParameters.get(
                "transferringAgentIdentity"
              ),
              message: {
                action: "DISCONNECT_WARM_TRANSFERCALL",
                line: "TB->355",
              },
            });
            await store.getters.currentConnection.disconnect();
            store.getters.currentConnection.on("disconnect", async () => {
              transferControlBeforeDisconnect();
              await store.dispatch("popIncomingCaller", {
                CallSid: store.getters.currentConnection.parameters.CallSid,
                line: "TB-> 352",
              });
              await store.dispatch("hangUpCall", {
                CallSid: store.getters.currentConnection.parameters.CallSid,
                incoming: false,
                agentIdentity: store.getters.getUser,
                line: "TB->356",
              });
              await store.dispatch("hangUpClient", {
                connection: null,
                incoming: false,
                value: store.getters.getUser,
                line: "TB->362",
              });
              ResetOnDisconnect();
            });
          }
          //When agent recieved a warm transfer call and has no controll on the call
          else if (
            store.getters.currentConnection.customParameters.get(
              "isWarmCall"
            ) === "true" &&
            !store.getters.getcontrolOnTransferCallStatus
          ) {
            store.dispatch("sendNotification", {
              agent: store.getters.currentConnection.customParameters.get(
                "transferringAgentIdentity"
              ),
              message: {
                action: "DISCONNECT_WARM_TRANSFERCALL",
                line: "TB->361",
              },
            });
            store.dispatch("hangUpClient", {
              connection: null,
              incoming: false,
              value: store.getters.getUser,
              line: "TB->512",
            });
            store.dispatch("hangUpAgent", {
              CallSid: store.getters.currentConnection.parameters.CallSid,
              incoming: false,
              value: store.getters.getUser,
              line: "TB->518",
            });
            store.dispatch("setConnection", {
              connection: null,
              incoming: false,
              AgentIdentity: store.getters.getUser,
              line: "TB->531",
            });
            ResetOnDisconnect();
          }
          //When agent recieved a normal call and wants to end the call
          else {
            store.dispatch("hangUpCall", {
              CallSid: store.getters.currentConnection.parameters.CallSid,
              incoming: false,
              agentIdentity: store.getters.getUser,
              line: "TB->534",
            });
            transferControlBeforeDisconnect();
            await store.getters.currentConnection.disconnect();
            store.getters.currentConnection.on("disconnect", async () => {
              await store.dispatch("popIncomingCaller", {
                CallSid: store.getters.currentConnection.parameters.CallSid,
                line: "TB-> 352",
              });
              await store.dispatch("hangUpCall", {
                CallSid: store.getters.currentConnection.parameters.CallSid,
                incoming: false,
                agentIdentity: store.getters.getUser,
                line: "TB->356",
              });
              await store.dispatch("hangUpClient", {
                connection: null,
                incoming: false,
                value: store.getters.getUser,
                line: "TB->362",
              });
              ResetOnDisconnect();
            });
          }
        }

        //Additional check to ensure the connection is resetted to original null state to recieved new calls.
        if (store.getters.currentConnection !== null) {
          store.dispatch("hangUpClient", {
            connection: null,
            incoming: false,
            value: store.getters.getUser,
            line: "TB->546",
          });
          store.dispatch("setConnection", {
            connection: null,
            incoming: false,
            AgentIdentity: store.getters.getUser,
            line: "TB->559",
          });
        }
      }
      //When call is incoming but not accepted by agent and not ongoing
      else {
        store.dispatch("popIncomingCaller", {
          CallSid: store.getters.currentConnection.parameters.CallSid,
          line: "TB-> 368",
        });
        await store.getters.currentConnection.reject();
        store.getters.currentConnection.on("reject", async () => {
          if (
            store.getters.currentConnection.customParameters.get(
              "isWarmCall"
            ) === "true"
          ) {
            store.dispatch("sendNotification", {
              agent: store.getters.currentConnection.customParameters.get(
                "transferringAgentIdentity"
              ),
              message: {
                action: "DISCONNECT_WARM_TRANSFERCALL",
                line: "TB->375",
              },
            });
          }
          await store.dispatch("hangUpAgent", {
            CallSid: store.getters.currentConnection.parameters.CallSid,
            incoming: false,
            value: store.getters.getUser,
            line: "TB->383",
          });
          ResetOnDisconnect();
        });

        store.dispatch("setConnection", {
          connection: null,
          incoming: false,
          AgentIdentity: store.getters.getUser,
          line: "TB->572",
        });
      }
    };

    const CallOnHoldOrRelease = async (payload) => {
      try {
        if (payload) {
          store.dispatch("setTimerStatus", { status: false });
          await store.dispatch("callHoldOrRelease", {
            connection: store.getters.currentConnection,
            status: payload,
            line: "TB->394",
          });
        } else {
          store.dispatch("setTimerStatus", { status: true });
          await store.dispatch("callHoldOrRelease", {
            connection: store.getters.currentConnection,
            status: payload,
            line: "TB->397",
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const MuteOrUnmuteCall = async () => {
      try {
        if (store.getters.isCallMuted) {
          await store.dispatch("callMuteOrUnmute", {
            connection: store.getters.currentConnection,
            status: false,
          });
        } else {
          await store.dispatch("callMuteOrUnmute", {
            connection: store.getters.currentConnection,
            status: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const OpenTransferCallModal = async () => {
      try {
        store.dispatch("fetchAllInternalAgents");
        store.dispatch("setTransferCallModal", {
          status: !store.getters.isTransferCallModal,
        });
      } catch (error) {
        console.log(error);
      }
    };

    const OpenDialerAndContactListModal = async () => {
      try {
        store.dispatch("setDiallerAndContactListModal", {
          status: !store.getters.isDiallerAndContactListModal,
        });
        await store.dispatch("setCustomerList");
      } catch (error) {
        console.log(error);
      }
    };

    const OpenBreakModal = async () => {
      try {
        store.dispatch("setBreakModal", {
          status: !store.getters.isBreakModalOpen,
        });
      } catch (error) {
        console.log(error);
      }
    };

    const OpenAgentModal = async () => {
      try {
        store.dispatch("SetAgentModal", {
          status: !store.getters.isAgentModalOpen,
        });
        await store.dispatch("setAgentsList");
      } catch (error) {
        console.log(error);
      }
    };

    const OpenCallAndVoiceMailRecordingModal = async () => {
      try {
        await store.dispatch("setVoiceMailRecordingList", {
          offSet: store.getters.getVoiceRecordingPageNumber,
          limit: store.getters.getLimitPerPageVoiceRecording,
        });
        await store.dispatch("setCallRecordingsList", {
          offSet: store.getters.getCallRecordingPageNumber,
          limit: store.getters.getLimitPerPageCallRecording,
        });
        await store.dispatch("setTotalCallRecordings", {
          name: "",
          phoneNumber: "",
          date: "",
        });
        await store.dispatch("setTotalVoiceMailRecordings", {
          name: "",
          phoneNumber: "",
          date: "",
        });
        store.dispatch("setCallAndVoiceRecordingModal", {
          status: !store.getters.isCallAndVoiceMailRecordingModalOpen,
        });
      } catch (error) {
        console.log(error);
      }
    };

    const OpenAddCustomerContactModal = async () => {
      if (!onGoingCallStatus.value) {
        store.dispatch("setPrimaryNumberToNull", { phoneNo: "" });
        await store.dispatch("SetAddCustomerContactModal", {
          status: !store.getters.isAddCustomerContactModal,
          line: "TB->675",
        });
      } else {
        await store.dispatch("SetAddCustomerContactModal", {
          status: !store.getters.isAddCustomerContactModal,
          line: "TB->680",
        });
      }
    };

    const isOutboundCallAccepted = computed(() => {
      if (
        store.getters.getOutBoundCall &&
        store.getters.getOutBoundCallAccepted
      ) {
        return true;
      } else {
        return false;
      }
    });

    const activateHoldBtns = computed(() => {
      if (
        (!store.getters.getTransferCallAccepted &&
          !conferenceBtnIsDisabled.value) ||
        (store.getters.getTransferCallAccepted && conferenceBtnIsDisabled.value)
      ) {
        return true;
      } else if (
        store.getters.getTransferCallAccepted &&
        !conferenceBtnIsDisabled.value
      ) {
        return false;
      } else {
        return true;
      }
    });

    watch(
      () => store.getters.currentConnection,
      (currentConnection) => {
        if (currentConnection === null) {
          if (store.getters.getLengthOfCallerQueueList >= 1) {
            setTimeout(async () => {
              const firstCaller = store.getters.getFirstCallFromThisList;
              if (isProxy(firstCaller)) {
                const rawData = toRaw(firstCaller.call);
                await store.dispatch("setConnection", {
                  connection: rawData,
                  incoming: true,
                  AgentIdentity: store.getters.getUser,
                  line: "TB->736",
                });
              }
            }, 2000); // 2000 milliseconds = 2 seconds
          }
        }
      }
    );

    // watch(
    //   currentConnection,
    //   async (newCurrentConnection) => {
    //     if (newCurrentConnection === null) {
    //       console.log(store.getters.getFirstCallFromThisList);
    //       if (store.getters.getLengthOfCallerQueueList >= 1) {
    //         await store.dispatch("setConnection", {
    //           connection: store.getters.getFirstCallFromThisList.call,
    //           incoming: true,
    //           AgentIdentity: store.getters.getUser,
    //           line: "TB->730"
    //         });
    //       }
    //     }
    //   }
    // );

    return {
      onReadyClick,
      isReady,
      OnRecieveCallClick,
      OnDisconnectCallClick,
      onGoingCallStatus,
      isIncoming,
      callOnHoldStatus,
      CallOnHoldOrRelease,
      muteStatus,
      MuteOrUnmuteCall,
      timerStatus,
      OpenTransferCallModal,
      showTransferCallModal,
      showDiallerAndContactListModal,
      OpenDialerAndContactListModal,
      showBreakModal,
      OpenBreakModal,
      showAgentModal,
      OpenAgentModal,
      showTransferPopUpModal,
      isAgentAdmin,
      OpenCallAndVoiceMailRecordingModal,
      showCallVoiceMailRecording,
      OpenAddCustomerContactModal,
      showAddEditContactFormModal,
      showDeleteEditContactFormModal,
      isContactSaved,
      showConfirmDeleteContactModal,
      hasControlOnTransferCall,
      incomingConnections,
      showCallerQueueModal,
      showConfirmDeleteAgentModal,
      showEditAgentModal,
      conferenceBtnIsDisabled,
      ResetOnDisconnect,
      activateHoldBtns,
      isCustomerNotSaved,
      currentConnection,
      showCallLogModal,
      isOutboundCallAccepted,
      showNotificationModal,
      showConfirmToSaveNewContactNumberModal,
    };
  },
};
</script>

<style scoped>
.green {
  color: green;
}
.red {
  color: red;
}
.gold {
  color: gold;
}
.notify {
  color: red;
}
</style>
