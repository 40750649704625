import { $http, $appIDValue, $httpAF, $authorizationValue } from "@/axios";
import { Device } from "@twilio/voice-sdk";
import { toRaw } from "vue";
const state = {
  ready: false,
  incoming: false,
  token: "",
  device: null,
  connection: null,
  user: "",
  agent: "",
  internalAgents: [],
  onGoingCall: false,
  hold: false,
  mute: false,
  timer: false,
  stopTimer: false,
  transferStatus: false,
  outboundPhoneNumber: "", //+15412363254,,
  callOutgoing: false,
  isOutBoundCall: false,
  breakReason: null,
  userInitiatedBreak: false,
  numberValidity: false,
  countryCode: "1",
  country: "US",
  outBoundPhoneNumberWithoutCC: "",
  tranferedCallToAgentIdentity: "",
  transferAgentIsOffline: false,
  transferCallAccepted: false,
  incomingCallersQueue: [],
  lengthOfIncomingCallerQueue: 0,
  transferButtonEnable: false,
  agentsList: [],
  deleteAgentID: "",
  editAgentDetails: {},
  conferenceBtnDisabled: false,
  isCustomerNotSaved: false,
  missedCallNotificationList: [],
  voiceMailNotificationList: [],
  warmTransferCallSid: "",
};

const getters = {
  isDeviceReady: (state) => state.ready,
  currentToken: (state) => state.token,
  currentDevice: (state) => state.device,
  currentConnection: (state) => state.connection,
  currentUser: (state) => state.user,
  currentAgent: (state) => state.agent,
  incomingState: (state) => state.incoming,
  isCallOngoing: (state) => state.onGoingCall,
  isCallOnHold: (state) => state.hold,
  isCallMuted: (state) => state.mute,
  getTimerStatus: (state) => state.timer,
  getStopTimerStatus: (state) => state.stopTimer,
  getOutBoundPhoneNumber: (state) => state.outboundPhoneNumber,
  isCallOutgoing: (state) => state.callOutgoing,
  getOutBoundCall: (state) => state.isOutBoundCall,
  getNumberValidityStatus: (state) => state.numberValidity,
  getCountry: (state) => state.country,
  getCountryCode: (state) => state.countryCode,
  getOutBoundPhoneNumberWithoutCC: (state) =>
    state.outBoundPhoneNumberWithoutCC,
  getTranferedCallToAgentIdentity: (state) =>
    state.tranferedCallToAgentIdentity,
  isTransferToOfflineAgent: (state) => state.transferAgentIsOffline,
  getTransferCallAccepted: (state) => state.transferCallAccepted,
  getIncomingCallersQueue: (state) => state.incomingCallersQueue,
  getLengthOfCallerQueueList: (state) => state.lengthOfIncomingCallerQueue,
  getTransferButtonEnable: (state) => state.transferButtonEnable,
  getAgentsList: (state) => state.agentsList,
  getDeteleAgentId: (state) => state.deleteAgentID,
  getEditAgentdetails: (state) => state.editAgentDetails,
  isConferenceBtnDisabled: (state) => state.conferenceBtnDisabled,
  getIsCustomerNotSaved: (state) => state.isCustomerNotSaved,
  getFirstCallFromThisList: (state) => state.incomingCallersQueue[0],
  getMissedCallNotificationList: (state) => state.missedCallNotificationList,
  getVoiceMailNotificationList: (state) => state.voiceMailNotificationList,
  getWarmTransferCallSID: (state) => state.warmTransferCallSid,
};

const actions = {
  resetDeviceState({ commit }) {
    commit("RESET_DEVICE_STATE");
  },

  async getTwilioToken({ commit, dispatch }, payload) {
    const deviceOptions = {
      disableAudioContextSounds: false,
      allowIncomingWhileBusy: true,
    };
    const tokenResponse = await $http.get("TwilioToken/GetInboundVoiceToken", {
      params: {
        identity: payload.value,
        twimlAppId: $appIDValue,
      },
    });

    if (tokenResponse.status === 200) {
      await $http.post("Call/SetAgentOnline", { agentIdentity: payload.value });
      await dispatch("setCurrentAgentOnline", { status: true });
      commit("SET_DEVICE", new Device(tokenResponse.data.token, deviceOptions));
      commit("SET_TOKEN", tokenResponse.data.token);
      commit("SET_AGENT", payload.value);
      commit("SET_DEVICE_READY", true);
    }
  },

  async setConnection({ commit }, payload) {
    //console.log(payload.line,payload.connection);
    commit("SET_CONNECTION", payload.connection);
    commit("SET_INCOMING", payload.incoming);
    commit("SET_AGENT", payload.AgentIdentity);
  },

  async hangUpCall({ commit }, payload) {
    //console.log(payload.line," hangUpCall ",payload);
    commit("SET_INCOMING", payload.incoming);
    await $http.post("Call/HangupCustomer", {
      agentCallSid: payload.CallSid,
      agentIdentity: payload.agentIdentity,
    });
    commit("SET_TIME_STATUS", false);
    commit("SET_STOP_TIMER", true);
    commit("SET_HOLD_STATUS", false);
    commit("SET_MUTE_STATUS", false);
  },

  async setIsCallOnGoing({ commit, dispatch }, payload) {
    if (payload.onGoingCall) {
      await $http.post("Call/SetAgentBusy", { agentIdentity: state.agent });
    } else {
      await $http.post("Call/SetAgentOnline", { agentIdentity: state.agent });
      await dispatch("setCurrentAgentOnline", { status: true });
    }
    commit("SET_IS_CALL_ONGOING", payload.onGoingCall);
  },

  async setReady({ commit }, payload) {
    commit("SET_DEVICE_READY", payload.value);
  },

  async callHoldOrRelease({ commit }, payload) {
    if (payload.status) {
      await $http.post("Call/PutOnHold", {
        agentCallSid: payload.connection.parameters.CallSid,
      });
    } else {
      await $http.post("Call/ReleaseHold", {
        agentCallSid: payload.connection.parameters.CallSid,
      });
    }
    commit("SET_HOLD_STATUS", payload.status);
  },

  async UpdateCustomerOnHoldStatus({ commit }, payload) {
    commit("SET_HOLD_STATUS", payload.status);
  },

  async callMuteOrUnmute({ commit }, payload) {
    if (!state.mute) {
      await $http.post("Call/MuteCall", {
        pathCallSid: payload.connection.parameters.CallSid,
      });
    } else {
      await $http.post("Call/UnmuteCall", {
        pathCallSid: payload.connection.parameters.CallSid,
      });
    }
    commit("SET_MUTE_STATUS", payload.status);
  },

  async setTimerStatus({ commit }, payload) {
    commit("SET_TIME_STATUS", payload.status);
  },
  async setStopTimerStatus({ commit }, payload) {
    commit("SET_STOP_TIMER", payload.status);
  },

  async hangUpClient({ commit, dispatch }, payload) {
    //console.log(payload.line," hangUpClient ",payload);
    commit("SET_IS_CALL_ONGOING", false);
    commit("SET_INCOMING", payload.incoming);
    commit("SET_MUTE_STATUS", false);
    commit("SET_HOLD_STATUS", false);
    await $http.post("Call/SetAgentOnline", { agentIdentity: payload.value });
    await dispatch("setCurrentAgentOnline", { status: true });
    commit("SET_CONNECTION", null);
    commit("SET_TIME_STATUS", false);
    commit("SET_STOP_TIMER", true);
    await dispatch("setTransferPopupModal", { status: false });
  },

  setIncoming({ commit }, payload) {
    //console.log("setIncoming",payload.line);
    commit("SET_INCOMING", payload.status);
  },

  async setWarmTransferCallSID({ commit }, payload) {
    commit("SET_WARM_TRANSFER_CALLSID", payload.warmCallSid);
  },

  async hangUpAgent({ commit, dispatch }, payload) {
    //console.log(payload.line," hangUpAgent ",payload);
    commit("SET_IS_CALL_ONGOING", false);
    commit("SET_INCOMING", false);
    await $http.post("Call/HangUpAnAgent", { agentCallSid: payload.CallSid });
    await $http.post("Call/SetAgentOnline", { agentIdentity: payload.value });
    await dispatch("setCurrentAgentOnline", { status: true });
    commit("SET_MUTE_STATUS", false);
    commit("SET_HOLD_STATUS", false);
    commit("SET_CONNECTION", null);
    commit("SET_TIME_STATUS", false);
    commit("SET_STOP_TIMER", true);
    await dispatch("setTransferPopupModal", { status: false });
  },

  async resetAgentUI({ commit, dispatch }) {
    commit("SET_IS_CALL_ONGOING", false);
    commit("SET_INCOMING", false);
    commit("SET_MUTE_STATUS", false);
    commit("SET_HOLD_STATUS", false);
    commit("SET_CONNECTION", null);
    commit("SET_TIME_STATUS", false);
    commit("SET_STOP_TIMER", true);
    await dispatch("setTransferPopupModal", { status: false });
  },

  async setMissedCallNotificationList({ commit, dispatch }, payload) {
    try {
      const response = await $http.get("Call/GetMissedCallDetails", {
        params: {
          agentIdentity: payload.agentIdentity,
        },
      });

      const enrichedMissedCalls = await Promise.all(
        response.data.map(async (missedCall) => {
          const customerName = await dispatch(
            "getCustomerDetailsByPhoneNumber",
            { phoneNumber: missedCall.PhoneNumber }
          );
          return { ...missedCall, customerName };
        })
      );

      commit("SET_MISSED_CALL_NOTIFICATION_LIST", enrichedMissedCalls);
    } catch (error) {
      console.error(
        "An error occurred while fetching missed call details:",
        error
      );
    }
  },

  async setVoiceMailNotificationList({ commit }, payload) {
    try {
      const response = await $http.get("Call/GetVoiceRecordingDetails", {
        params: {
          agentIdentity: payload.agentIdentity,
        },
      });

      // Iterate over each voice mail item and fetch customer name for each phone number
      const enrichedVoiceMails = await Promise.all(
        response.data.map(async (voiceMail) => {
          const customerName = await this.dispatch(
            "getCustomerDetailsByPhoneNumber",
            { phoneNumber: voiceMail.PhoneNumber }
          );
          return { ...voiceMail, customerName };
        })
      );

      commit("SET_VOICE_MAIL_NOTIFICATION_LIST", enrichedVoiceMails);
    } catch (error) {
      console.error(
        "An error occurred while fetching voice mail details:",
        error
      );
      // Handle error, e.g., show error message to the user
    }
  },

  async TransferCall({ commit }, payload) {
    //  await dispatch("setDisableConferenceButton",{status:true});

    let customerPhNo = "";
    if (state.isOutBoundCall) {
      if (Array.isArray(state.outboundPhoneNumber)) {
        customerPhNo = state.outboundPhoneNumber[0];
      } else {
        customerPhNo = state.outboundPhoneNumber;
      }
    } else {
      customerPhNo = state.connection.parameters.From;
    }

    await $http.post("Call/WarmTransferCall", {
      transferringAgentIdentity: state.agent,
      transferToAgentIdentity: payload.agentIdentity,
      transfererCallSid: state.connection.parameters.CallSid,
      customerPhone: customerPhNo,
      isLoggedIn: payload.isLoggedIn,
    });

    commit("SET_TRANSFER_STATUS", true);
    commit("SET_MUTE_STATUS", false);
    commit("SET_HOLD_STATUS", true);
    commit("SET_TRANSFER_CALL_AGENT_NAME", payload.agentIdentity);
  },

  async MakeOutBoundCalls() {
    await state.device.connect({
      params: {
        To: state.outboundPhoneNumber,
        From: state.agent,
        Direction: "outbound",
      },
    });
  },

  setOutBoundPhoneNumber({ commit }, payload) {
    commit("SET_OUTBOUND_PHONENUMBER", payload);
  },
  setOutBoundStatus({ commit }, payload) {
    commit("SET_OUTBOUND_STATUS", payload);
  },

  setBreakReason({ commit }, payload) {
    commit("SET_BREAK_REASON", payload);
  },
  async goOnBreak({ commit }, payload) {
    commit("SET_DEVICE", null);
    commit("SET_CONNECTION", null);
    commit("RESET_DEVICE");
    await $http.post("Call/AddBreakReason", {
      AgentIdentity: payload.agentIdentity,
      BreakReason: payload.breakReason,
    });
  },
  setAgentName({ commit }, payload) {
    commit("SET_AGENT", payload.agentName);
  },

  async validatePhone({ commit }, payload) {
    const validationResponse = await $http.post("Call/ValidateNumber", payload);
    commit("SET_NUMBER_VALIDITY", validationResponse.data);
  },
  setCountry({ commit }, payload) {
    commit("SET_COUNTRY", payload.country);
  },
  setCountrycode({ commit }, payload) {
    commit("SET_COUNTRY_CODE", payload.cc);
  },
  setPhoneNumberWithoutCountryCode({ commit }, payload) {
    commit("SET_OUTBOUND_PHONENUMBER_WITHOUT_CC", payload);
  },
  async setDropTransferedAgent({ commit }, payload) {
    await $http.post("Call/HangUpAnAgent", { agentCallSid: payload.callSID });
    await $http.post("Call/HangupOneAgent", {
      agentIdentity: payload.agentIdentity,
      agentCallSid: payload.callSID,
    });
    commit("SET_TRANSFER_STATUS", false);
  },
  async setTransferAgentIsOfline({ commit }, payload) {
    commit("SET_TRANSFER_AGENT_IS_OFFLINE", payload.status);
  },
  async setTransferCallAcceptedStatus({ commit }, payload) {
    commit("SET_TRANSFER_CALL_ACCEPTED_STATUS", payload.status);
  },
  async setTransferCallAccepted({ commit }, payload) {
    commit("SET_TRANSFER_CALL_ACCEPTED", payload.showTransferCallFeatures);
    commit("SET_TRANSFER_CALL_ACCEPTED_STATUS", payload.status);
  },

  async pushIncomingCaller({ commit, dispatch, state }, payload) {
    try {
      // Call dispatch with await and wrap it in a try-catch block
      const response = await dispatch("getCustomerDetailsByPhoneNumber", {
        phoneNumber: payload.connection.parameters.From,
      });

      // Prepare call object
      const call = {
        name: response,
        call: toRaw(payload.connection),
        phoneNumber: payload.connection.parameters.From,
      };

      // Check if the CallSid already exists in the queue
      const phoneNumberExists = state.incomingCallersQueue.some(
        (call) =>
          call.call.parameters.CallSid === payload.connection.parameters.CallSid
      );
      // If the phone number doesn't exist, push it into the queue
      if (!phoneNumberExists) {
        // Remove any existing entry with the same phone number but any differernt CallSid
        await commit(
          "POP_INCOMING_CALLER_QUEUE_BY_PHONENUMBER",
          payload.connection.parameters.From
        );
        commit("PUSH_INCOMING_CALLER_QUEUE", call);
        commit("SET_LENGHT_OF_CALLER_QUEUE_LIST");
      }
    } catch (error) {
      console.error("Error fetching customer details:", error);
      // Handle error if needed
    }
  },
  async popIncomingCaller({ commit, dispatch }, payload) {
    //console.log("popIncomingCaller :",payload.line) ;
    if (payload.CallSid) {
      commit("POP_INCOMING_CALLER_QUEUE_BY_CALLSID", payload.CallSid);
      if (state.lengthOfIncomingCallerQueue >= 1) {
        dispatch("setCallerQueueModal", { status: true });
      }
    } else if (payload.phoneNumber) {
      commit("POP_INCOMING_CALLER_QUEUE_BY_PHONENUMBER", payload.phoneNumber);
      if (state.lengthOfIncomingCallerQueue >= 1) {
        dispatch("setCallerQueueModal", { status: true });
      }
    } else if (payload.connection) {
      commit("POP_INCOMING_CALLER_QUEUE", payload.connection);
    }
    commit("SET_LENGHT_OF_CALLER_QUEUE_LIST");
  },
  async setEnableTransferCall({ commit }, payload) {
    await commit("SET_TRANSFER_BUTTON_ENABLED", payload.status);
  },
  async setAgentsList({ commit }) {
    const response = await $http.get("Call/RetrieveActiveAgents");
    commit("SET_AGENTS_LIST", response.data);
  },
  async setDeleteAgentIdentity({ commit }, payload) {
    commit("SET_DELETE_AGENT_ID", payload.agentIdentity);
  },
  //  async deleteAgentDetails({commit},payload){
  //   console.log("identityyyy",payload.agentIdentity)
  //    const response = await $http.post("Call/DeleteActiveAgent",
  //     {
  //       "agentIdentity":payload.agentIdentity
  //     }
  //   )
  //   if(response.status===200){
  //     alert(response.data.message);
  //   }
  //  commit('')
  // },

  async deleteAgentDetails({ commit }, payload) {
    let afResponse;
    try {
      console.log("txtUsername", payload.agentIdentity);
     const identity=`${payload.agentIdentity}`
      afResponse = await $httpAF.post(
        "Forms/api/appforms",
        {
          TenantId: "default",
          FormId: "9AB4ABEB-77DF-45FB-A821-98C8F27A50A0",
          FormName: null,
          ExecuteFormLoadRule: false,
          RuleNames: [
            {
              RuleName: "On_Formload",
            },
          ],
          APIDataList: [
            {
              ControlName: "txtUsername",
              Value: identity,
              Rows: null,
            },
          ],
          OverrideValidation: false,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: $authorizationValue,
          },
        }
      );
      console.log("valuee",identity)
    } catch (error) {
      console.error("Error deleting agent details:", error);
    } finally {
      console.log("Appform Response:", afResponse.data);

      console.log("txtUsername", payload.agentIdentity);

      if (afResponse.status === 200 && afResponse.data) {
        console.log("agentIdentity", payload.agentIdentity);
        const response = await $http.post("Call/DeleteActiveAgent", {
          agentIdentity: payload.agentIdentity,
        });

        if (response.status === 200 && response.data) {
          alert(response.data.message);
        } else {
          alert("Failed to delete agent from Call/DeleteActiveAgent");
        }
      } else {
        alert("Failed to delete agent details from Forms/api/appforms");
        console.error(
          "Appform deletion failed:",
          afResponse.data.message || "Unknown error"
        );
      }
    }
    commit("");
  },

  async SetEditAgentDetails({ commit }, payload) {
    commit("SET_EDIT_AGENT_DETAILS", payload.agent);
  },
  async updateAgentDetails({ commit }, payload) {
    const response = await $http.post("Call/EditActiveAgent", {
      agentGuid: payload.AgentGuidID,
      agentIdentity: payload.UserName,
      firstName: payload.FirstName,
      lastName: payload.LastName,
      email: payload.Email,
      phoneNumber: payload.PhoneNumber,
      agentGroup: payload.UserGroup,
      agentRole: payload.UserRole,
    });
    if (response.status === 200) {
      alert(response.data.message);
    }
    commit("");
  },
  async checkAgentsInConference({ commit }, payload) {
    await $http.post("Call/AgentsInConference", {
      AgentCallSid: payload.AgentCallSid,
      customerPhone: payload.customerPhone,
    });
    commit("");
  },
  setConferenceBtnDisabled({ commit }, payload) {
    commit("SET_CONFERENCE_BTN_DISABLED", payload.status);
  },
  async setMarkAllAsRead({ commit }, payload) {
    await $http.post("Call/UpdateMarkAsReadFlag", {
      agentIdentity: payload.agentIdentity,
    });
    commit("");
  },
};

const mutations = {
  RESET_DEVICE_STATE(state) {
    Object.assign(state, {
      ready: false,
      incoming: false,
      token: "",
      device: null,
      connection: null,
      user: "",
      agent: "",
      internalAgents: [],
      onGoingCall: false,
      hold: false,
      mute: false,
      timer: false,
      stopTimer: false,
      transferStatus: false,
      outboundPhoneNumber: "", //+15412363254,,
      callOutgoing: false,
      isOutBoundCall: false,
      breakReason: null,
      userInitiatedBreak: false,
      numberValidity: false,
      countryCode: "1",
      country: "US",
      outBoundPhoneNumberWithoutCC: "",
      tranferedCallToAgentIdentity: "",
      transferAgentIsOffline: false,
      transferCallAccepted: false,
      incomingCallersQueue: [],
      transferButtonEnable: false,
      agentsList: [],
      deleteAgentID: "",
      editAgentDetails: {},
      conferenceBtnDisabled: false,
    });
  },
  SET_AGENT(state, agent) {
    state.agent = agent;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_DEVICE(state, device) {
    state.device = device;
  },
  SET_DEVICE_READY(state, value) {
    state.ready = value;
  },
  SET_CONNECTION(state, connection) {
    state.connection = connection;
  },
  SET_INCOMING(state, incoming) {
    state.incoming = incoming;
  },
  SET_IS_CALL_ONGOING(state, onGoingCall) {
    state.onGoingCall = onGoingCall;
  },
  SET_HOLD_STATUS(state, status) {
    state.hold = status;
  },
  SET_MUTE_STATUS(state, status) {
    state.mute = status;
  },
  RESET_DEVICE(state) {
    state.token = "";
    state.device = null;
    state.ready = false;
    state.incoming = false;
    state.connection = null;
    state.internalAgents = [];
  },
  SET_TIME_STATUS(state, status) {
    state.timer = status;
  },
  SET_STOP_TIMER(state, status) {
    state.stopTimer = status;
  },
  SET_TRANSFER_STATUS(state, status) {
    state.transferStatus = status;
  },
  SET_CALL_OUTGOING(state, status) {
    state.callOutgoing = status;
  },
  SET_OUTBOUND_PHONENUMBER(state, payload) {
    state.outboundPhoneNumber = payload;
  },
  SET_OUTBOUND_STATUS(state, payload) {
    state.isOutBoundCall = payload;
  },
  SET_BREAK_REASON(state, reason) {
    state.breakReason = reason.breakReason;
    state.userInitiatedBreak = true;
  },
  SET_NUMBER_VALIDITY(state, status) {
    state.numberValidity = status;
  },
  SET_COUNTRY(state, country) {
    state.country = country;
  },
  SET_COUNTRY_CODE(state, cc) {
    state.countryCode = cc;
  },
  SET_OUTBOUND_PHONENUMBER_WITHOUT_CC(state, outBoundPhNoWithoutCC) {
    state.outBoundPhoneNumberWithoutCC = outBoundPhNoWithoutCC;
  },
  SET_TRANSFER_CALL_AGENT_NAME(state, tranferedCallToAgentIdentity) {
    state.tranferedCallToAgentIdentity = tranferedCallToAgentIdentity;
  },
  SET_TRANSFER_AGENT_IS_OFFLINE(state, response) {
    state.transferAgentIsOffline = response;
  },
  SET_TRANSFER_CALL_ACCEPTED(state, response) {
    state.transferCallAccepted = response;
  },
  SET_TRANSFER_CALL_ACCEPTED_STATUS(state, status) {
    state.transferCallAccepted = status;
  },

  PUSH_INCOMING_CALLER_QUEUE(state, response) {
    state.incomingCallersQueue.push(response);
  },
  POP_INCOMING_CALLER_QUEUE(state, response) {
    const indexToRemove = state.incomingCallersQueue.findIndex(
      (call) => call.phoneNumber === response.phoneNumber
    );
    if (indexToRemove !== -1) {
      state.incomingCallersQueue.splice(indexToRemove, 1);
    }
  },
  SET_LENGHT_OF_CALLER_QUEUE_LIST(state) {
    state.lengthOfIncomingCallerQueue = state.incomingCallersQueue.length;
  },
  SET_TRANSFER_BUTTON_ENABLED(state, status) {
    state.transferButtonEnable = status;
  },

  SET_AGENTS_LIST(state, response) {
    state.agentsList = response;
  },
  SET_DELETE_AGENT_ID(state, response) {
    state.deleteAgentID = response;
  },
  SET_EDIT_AGENT_DETAILS(state, response) {
    state.editAgentDetails = response;
  },
  SET_CONFERENCE_BTN_DISABLED(state, response) {
    state.conferenceBtnDisabled = response;
  },
  POP_INCOMING_CALLER_QUEUE_BY_PHONENUMBER(state, response) {
    const indexToRemove = state.incomingCallersQueue.findIndex(
      (call) => call.phoneNumber === response
    );
    if (indexToRemove !== -1) {
      state.incomingCallersQueue.splice(indexToRemove, 1);
    }
  },
  POP_INCOMING_CALLER_QUEUE_BY_CALLSID(state, response) {
    const indexToRemove = state.incomingCallersQueue.findIndex(
      (call) => call.call.parameters.CallSid === response
    );
    if (indexToRemove !== -1) {
      state.incomingCallersQueue.splice(indexToRemove, 1);
    }
  },
  SET_MISSED_CALL_NOTIFICATION_LIST(state, response) {
    state.missedCallNotificationList = response;
  },
  SET_VOICE_MAIL_NOTIFICATION_LIST(state, response) {
    state.voiceMailNotificationList = response;
  },
  SET_WARM_TRANSFER_CALLSID(state, response) {
    state.warmTransferCallSid = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
