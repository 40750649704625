<template>
    <NavBar></NavBar>
</template>
<script>
import NavBar from '@/components/NavBar.vue';

export default {
    name:"login-view",
    components:{
        NavBar
    },
    setup() {
        
    },
}
</script>