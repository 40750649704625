<template>
    <div class="call-history-modal right" id="calldetailsmodal" tabindex="-1"
      aria-labelledby="calldetailsmodalLabel" aria-hidden="true">
      <div class="modal-dialog">        
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal">
          <i class="fas fa-times" style="color: grey;"></i>
        </button>
        <div class="modal-content">
          <div :class="[(callLogList.length <= 0 || checkIfAllDataEmpty )?'call-history-wrapper-no-scroller':'call-history-wrapper','p-15 br-6']">
            <!-- Show loading message if no data sis available -->
            <template v-if="callLogList.length <= 0">
              <div class="each-call-wrap-no-scroller">
                <div class="each-call-history d-flex align-items-center" style="width: 200px;">   
                  Call log loading...                     
                </div>
              </div>
            </template>
            <!-- Iterate over each month -->
            <template v-else>
                <template v-if="checkIfAllDataEmpty">
                    <div class="each-call-wrap-no-scroller">
                        <div class="each-call-history d-flex align-items-center" style="width: 200px;">   
                          No caller logs found!                     
                        </div>
                    </div>
                </template>
  
              <template v-for="(calls, monthYear) in callLogList" :key="monthYear">
                <!-- Only render if there are calls in this group -->
                <template v-if="calls.length > 0">
                  <!-- Extract month and year from monthYear -->
                  <div class="single-month-call">
                    <p class="single-month-name m-0" style="text-align: left; font-weight: bolder;">{{ getMonthNameAndYear(monthYear) }}</p>
                    <!-- Iterate over call logs for the month -->
                    <div class="each-call-wrap">
                      <template v-for="call in calls" :key="call.ParticipantSid">
                        <div class="d-flex">
                          <a href="#" style="margin-left: 15px; margin-top: 44px; wi">
                <img  title="Outgoing Call" class="callType" v-if="call.Direction==='Outbound'" src="@/assets/images/phone-outgoing.svg" alt="img">
                <img title="Incoming Call" class="callType" v-if="call.Direction==='Inbound'" src="@/assets/images/phone-incoming.svg" alt="img">
              </a>

              <div class="each-call-history d-flex align-items-center">
                          <div class="daydate">
                            <span class="d-block">{{ getDayOfWeek(call.Date) }}</span>
                            <span class="d-block">{{ getDateOfMonth(call.Date) }}</span>
                          </div>
                          <div class="d-flex align-items-center">
                            <div class="single-history call-duration">
                              <div class="d-flex">
                                <div><i class="fas fa-clock"></i></div>
                                <div>
                                  <span>Call duration</span>
                                  <p class="font-medium">{{ formatDuration(call.CallDuration) }}</p>
                                </div>
                              </div>
                            </div>
                            <div class="single-history receive-call">
                              <div class="d-flex ms-2">
                                <div><i class="fa-solid fa-user"></i></div>
                                <div>
                                  <span v-if="call.Direction === 'Outbound'">Called by</span>
                                  <span v-else>Received By</span>
                                  <p class="font-medium" :title="call.AgentToRecievedCall">
                                    {{ call.AgentToRecievedCall.slice(0, 9) }}
                                    <span v-if="call.AgentToRecievedCall.length > 9">..</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="single-history call-transfer">
                              <div class="d-flex ms-2">
                                <div><i class="fas fa-exchange-alt"></i></div>
                                <div>
                                  <span>Transferred to</span>
                                  <p class="font-medium  cursor-pointer" :title="call.CallTransferedTo">{{ getFirstRecipient(call.CallTransferedTo).slice(0,9) }}<span v-if="call.CallTransferedTo.length>9">..</span></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import store from "@/store";
  import { computed } from "vue";
  
  export default {
    setup() {
      const callLogList = computed(() => store.getters.getListOfCallLogs);      
      const getDayOfWeek = dateString => {
        const date = new Date(dateString);
        const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        return daysOfWeek[date.getDay()];
      };
  
      const getDateOfMonth = dateString => {
        const date = new Date(dateString);
        // return date.getDate();
        const dayOfMonth = date.getDate();
        return dayOfMonth < 10 ? '0' + dayOfMonth : '' + dayOfMonth;
      };
  
      const formatDuration = duration => {
        // Convert duration to hh:mm:ss format
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = duration % 60;
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      };
       
      const getFirstRecipient = recipients => {
        if (!recipients) return '-';
        const recipientList = recipients.split(' ');
        if (recipientList.length > 1) {          
          return recipientList[0] + '...'; 
        } else {
          return recipientList[0]; 
        }
      };
        
    //   const getMonthNameAndYear = monthYear => {
    //     const [month, year] = monthYear.split('-'); 
    //     const date = new Date(`${year}-${month}-01`); 
    //   const monthName = date.toLocaleString('default', { month: 'long' }); 
    //   return `${monthName} ${year}`; 
    // };
    const getMonthNameAndYear = monthYear => {
  const [year, month] = monthYear.split('-'); 
  const date = new Date(year, month - 1); 
  const monthName = date.toLocaleString('default', { month: 'long' });
  return `${monthName} ${year}`; 
};


const checkIfAllDataEmpty = computed(() => {
  for (const monthYear in callLogList.value) {
    const calls = callLogList.value[monthYear];
    if (calls.length <= 0) {            
      return true;
    }
    else{
      return false;
    }    
  }
  return false;    
});

    const closeModal = () => {
      store.dispatch('setCallLogModal', { status: false });
    };

    return {
      callLogList,
      getDayOfWeek,
      getDateOfMonth,
      formatDuration,
      getMonthNameAndYear,
      getFirstRecipient,
      closeModal,
      checkIfAllDataEmpty
    };
  }
};
</script>

<style scoped>
.call-history-wrapper {
  min-width: 300px;
  max-width: 375px;
  overflow-y: scroll;  
  max-height: 490px;
}
.call-history-wrapper-no-scroller{
  min-width: 375px;
  max-width: 375px;
  max-height: 600px;
}
.each-call-history {
  margin-bottom: 10px; /* Adjust spacing between each call history */
}
.callType{
  width: 25px;
    height: 25px;
    border: 1px solid #dfdcdc;
    padding: 4px;
    border-radius: 5px;
}
.modal-content{
  max-height: 550px;
  max-width: 380px;
}
</style>
  