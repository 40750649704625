<template>
    <!-- onqueue div starts here  -->
    <div
      class="modal-content"
      style="border: none; box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3)"
    >
      <ul class="on-queue-users mb-0" style="   margin-left: 80%;">
        <!-- <li v-for="(caller, index) in incomingCallerList" :key="index" :class="[isItOnToolbar(caller.call.parameters.From)?'d-none':'','d-flex']"> -->
        <li v-for="(caller, index) in incomingCallerList" :key="index" :class="[isItOnToolbar(caller.call.parameters.CallSid) ? 'd-none' : '', 'd-flex']">
          <a href="#"><i class="fas fa-phone"></i></a>
          <div class="user-profile">
            <p class="name mb-0">{{ caller.name.length>15?caller.name.substring(0,15)+'..':caller.name}}</p>            
            <span>Incoming audio call - <span v-if="caller.name==='Unknown'">{{ caller.call.parameters.From }}</span></span>
          </div>
          <div class="call-action">
            <ul class="d-flex align-items-center">
              <li :class="[!onGoingCallStatus ? 'activate-item':'disbale-item']">
                <a   href="#" @click="recieveCall(caller)"
                  ><i  class='fas fa-phone' style="background: #43ca88"></i
                ></a>
              </li>
              <li :class="[!onGoingCallStatus ? 'activate-item':'disbale-item']">
                <a  href="#" @click="rejectCall(caller)"
                  ><i class='fas fa-phone-slash' style="background: red"></i
                ></a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <!-- onqueue div ends here  -->
  </template>
    
    <script>
  import { computed, watchEffect } from "vue";
  import store from "@/store"; // Adjust this path based on your project structure
  
  export default {
    name: "caller-queue-modal",
  
    setup() {
      let callerQueueLenght = computed (()=>store.getters.getLengthOfCallerQueueList);
      let onGoingCallStatus = computed(() => store.getters.isCallOngoing);
      let incomingCallerList = computed(() => store.getters.getIncomingCallersQueue); 
      
      let isItOnToolbar = computed(() => {
        if (store.getters.currentConnection) {
          return (phNumber) => {
            //console.log(store.getters.currentConnection);
            return store.getters.currentConnection.parameters.CallSid === phNumber;
          };
        } else {
          // If currentConnection is null, return a function that always returns false
          return () => false;
        }
      });

      const recieveCall=async(caller)=>{                           
              await store.dispatch("setConnection", {connection: caller.call,incoming: true,AgentIdentity: store.getters.getUser});                                                                              
              store.getters.currentConnection.accept(); 
                   
              await store.dispatch("isCustomerKnown", { phoneNumber: caller.call.parameters.From }).then(() => {
                      alert('Incoming Call from ' + caller.call.parameters.From + ' - ' + store.getters.getCallingCustomerName);
              });

              store.getters.currentConnection.on("accept", async () => {  
                store.dispatch("setIsCallOnGoing", { onGoingCall: true });                                                  
                  if(store.getters.currentConnection.customParameters)
                  {
                    if(store.getters.currentConnection.customParameters.get("isWarmCall")==="true"){              
                      store.dispatch("UpdateCustomerOnHoldStatus",{status:true});                            
                      store.dispatch("sendNotification",{agent:store.getters.currentConnection.customParameters.get("transferringAgentIdentity"),message:{action:"INCOMING_WARM_TRANSFER_CALL_ACCEPTED",showTransferCallFeatures:true,connection:store.getters.currentConnection}})     
                      //store.dispatch("sendNotification",{agent:store.getters.getUser,message:"TRANFER_CALL_ACCEPTED_STATUS"});  
                    }
                    else
                    {
                      store.dispatch("sendNotification",{agent:store.getters.currentConnection.parameters.To,message:{action:'INCOMING_CALL_ACCEPTED',from:store.getters.currentConnection.parameters.From,agent:store.getters.currentAgent}})                                                  
                    } 
                  }
                           
                  store.dispatch("setStopTimerStatus", { status: false });
                  store.dispatch("setTimerStatus", { status: true });            
                  store.dispatch("setIsCallOnGoing", { onGoingCall: true });
                  store.dispatch("setIncoming", { status: false, line:"CQ->85" });
                  //store.dispatch("popIncomingCaller",{phoneNumber:call.parameters.From});   
              //     await store.dispatch("sendNotification",{agent:"AllAgents",message:{action:'POP_FROM_INCOMING_CALLERS_LIST',CallSid:caller.call.parameters.CallSid}})                                                                                                        

              //     // if (store.getters.getCallingCustomerName === 'Unknown') {                    
              //     //    // const saveContact = confirm('Customer contact is not saved. Would you like to save it ?');
              //     //    // if (saveContact) {
              //     //    //   await store.dispatch("SetAddCustomerContactModal", { status: true,line:"CQ->92"});
              //     //    // }
              //     //    store.dispatch("setConfirmToSaveNewContactNumberModal", { status: true});
              //     //  }

               


              // })

              await store.dispatch("sendNotification",{agent:"AllAgents",message:{action:'POP_FROM_INCOMING_CALLERS_LIST',CallSid:caller.call.parameters.CallSid}})                                                                                                        

if (store.getters.getCallingCustomerName === 'Unknown') {                    
  const saveContact = confirm('Customer contact is not saved. Would you like to save it ?');
  if (saveContact) {
    await store.dispatch("SetAddCustomerContactModal", { status: true});
  }
}
})
      } 
      
      const OpenAddCustomerContactModal =async()=>{      
      if(!onGoingCallStatus.value){
          store.dispatch("setPrimaryNumberToNull", { phoneNo: "" });
          await store.dispatch("SetAddCustomerContactModal", { status: !store.getters.isAddCustomerContactModal,line:"CQ->101"});
        } else{
          await store.dispatch("SetAddCustomerContactModal", { status: !store.getters.isAddCustomerContactModal,line:"CQ->103"});
        }     
      }

      const rejectCall=async (caller)=>{     
        
          if (store.getters.isCallOngoing) {                                 
          store.getters.currentConnection.disconnect();          

          store.getters.currentConnection.on("disconnect", async() => 
          {             
            if(store.getters.currentConnection.customParameters)
            {
              if(store.getters.currentConnection.customParameters.get("isWarmCall")==="true")
              {
                store.dispatch("sendNotification",{agent:store.getters.currentConnection.customParameters.get("transferringAgentIdentity"),message:{action:"DISCONNECT_WARM_TRANSFERCALL",line:"CQ->118"}})     
                await store.dispatch("setConnection", {connection: null,incoming: false,AgentIdentity: store.getters.getUser});                  
              }
            }                  
                  store.dispatch("hangUpCall", {CallSid: store.getters.currentConnection.parameters.CallSid,incoming: false,agentIdentity: store.getters.getUser,line:"CQ->122"});
                  store.dispatch("hangUpClient", {connection:null,incoming: false,value:store.getters.getUser,line:"CQ->123"});
                  store.dispatch("setIsCallOnGoing", { onGoingCall: false });
                  store.dispatch("setStopTimerStatus", { status: true });
                  store.dispatch("setTimerStatus", { status: false });                  
                  store.dispatch("setTransferPopupModal",{status:false}); 
                  store.dispatch("setPrimaryContactNumber",{contactNumber:""}); 
                  store.dispatch("setTransferCallAccepted",{showTransferCallFeatures:false});
                  store.dispatch('setEnableTransferCall',{status:false});  
                  store.dispatch('setControlOnTransfer',{status:true});
                  store.dispatch("setConnection", {connection: null,incoming: false,AgentIdentity: store.getters.getUser});
                });
          store.dispatch("popIncomingCaller",{CallSid:caller.call.parameters.CallSid,line:'CQ-> 119'});
        }
        else {                
          caller.call.reject();

          caller.call.on("reject", async () => {                       
          if(caller.call.customParameters){
            if(caller.call.customParameters.get("isWarmCall")==="true"){              
              store.dispatch("sendNotification",{agent:caller.call.customParameters.get("transferringAgentIdentity"),message:{action:"DISCONNECT_WARM_TRANSFERCALL",line:"CQ->142"}})     
            }
          }
          store.dispatch("hangUpAgent", {CallSid: caller.call.parameters.CallSid,incoming: false,value:store.getters.getUser,line:"CQ->145"});
          store.dispatch("setIsCallOnGoing", { onGoingCall: false });
          store.dispatch("popIncomingCaller",{CallSid:caller.call.parameters.CallSid,line:'CQ-> 133'});
          store.dispatch('setEnableTransferCall',{status:false}); 
        });      


          store.dispatch("popIncomingCaller",{CallSid:caller.call.parameters.CallSid,line:'CQ-> 138'});
        }                

        caller.call.on("cancel", async () => {                                 
          store.dispatch("hangUpClient", {connection: null,incoming: false,value:store.getters.getUser,line:"CQ->156"});
          store.dispatch("setIncoming", { status: false, line:"CQ->157" });
          store.dispatch("popIncomingCaller",{CallSid:caller.CallSid.parameters.From,line:'CQ-> 145'});
          store.dispatch('setEnableTransferCall',{status:false});
          await store.dispatch("setConnection", {connection: null,incoming: false,AgentIdentity: store.getters.getUser});
        });         
      }
  
      watchEffect(() => {
      if (callerQueueLenght.value === 0) {
        store.dispatch('setCallerQueueModal', { status: false });
      }
    });
      return {
        incomingCallerList,     
        recieveCall,
        rejectCall,
        onGoingCallStatus,
        isItOnToolbar,
        OpenAddCustomerContactModal 
      };
    },
  };
  </script>
    