import {$http} from "@/axios";
const state = {
    customerList: [],  
    customerDetails:{
      customerGuid:'',
      firstName:'',
      lastName:'',
      phoneNumber1:'',
      phoneNumber2:'',
      gender:'',
      email:'',
      address:'',
    },
    primaryContactNumber:"",
    deleteContactCustomerGUID:"",
    callingCustomerName:"",

  };
  
  const getters = {
    getContactList: state => state.customerList,
    getCustomerDetailsByName:state=>state.customerDetails,
    getNewCustomerPrimaryContactNumber: state=> state.primaryContactNumber,
    getDeleteContactCustomerGUID: state=> state.deleteContactCustomerGUID,
    getCallingCustomerName: state=>state.callingCustomerName,
  };
  
  const actions = {   

    resetCustomerState({commit}){
      commit('RESET_CUSTOMER_STATE');
    },

    async setCustomerList({commit}) {        
        const response = await $http.get("Call/SearchCustomers");            
        commit("SET_CUSTOMER_LIST", response.data);         
    },

    async getCustomerDetailsByName({commit},payload){      
      const response = await $http.get("Call/SearchCustomers?name="+payload.name);  
      response.data.forEach(element => {
        if(element.CustomerGuid===payload.customerGuid){          
          commit('SET_EDIT_CUSTOMER_DETAILS_FORM',element);
        }
      });               
    },

    async addCustomerDetails({commit},payload){         
      const response=await $http.post("Call/AddNewContact",{
        "FirstName":payload.FirstName,
        "LastName":payload.LastName,
        "Email":payload.Email,
        "PhoneNumber1":payload.PhoneNumber1,
        "PhoneNumber2":payload.PhoneNumber2,
        "Address":payload.Address,
        "Gender":payload.Gender
        });    
        if(response.status===200){
          alert(response.data.message,".");
        }
        commit('')
    },

    async editCustomerDetails({commit},payload)
    {
      const response = await $http.post("Call/EditContact",{
        "CustomerGuid": payload.CustomerGuid,        
        "FirstName":payload.FirstName,
        "LastName":payload.LastName,
        "Email":payload.Email,
        "PhoneNumber1":payload.PhoneNumber1,
        "PhoneNumber2":payload.PhoneNumber2,
        "Address":payload.Address,
        "Gender":payload.Gender

    })
    if(response.status===200){
      alert(response.data.message);
    }  
    commit('')
    },
    
    async deleteCustomerDetails({commit},payload){
      const response =await $http.get("Call/DeleteContact?CustomerGuid="+payload.customerGuid)
      if(response.status===200){
        alert(response.data.message);
      }
      commit('')
    },

    async setPrimaryContactNumber({commit},payload){
      commit("SET_PRIMARY_CONTACT_NUMBER",payload.contactNumber);
    },
    async setDeleteContactCustomerGUID({commit},payload){
      commit("SET_DELETE_CONTACT_CUSTOMER_GUID",payload.customerGuid)
    },
    async isCustomerKnown({commit},payload){            
      const response = await $http.get("Call/GetCustomerName", {
        params: {
          phoneNumber: payload.phoneNumber
        }
      });          
      if(response.data!==''){        
        commit("SET_CUSTOMER_NAME",response.data);                 
      }else{        
        commit("SET_CUSTOMER_NAME","Unknown");                
      }      
    },
    async setPrimaryNumberToNull({commit},payload){
      commit('SET_PRIMARY_NUMBER_TO_NULL',payload.phoneNo)
    },
    async getCustomerDetailsByPhoneNumber({commit},payload){  
      commit('')      
      const response = await $http.get("Call/GetCustomerName", {
        params: {
          phoneNumber: payload.phoneNumber
        }
      }); 
      if(response.data!==''){        
        return response.data
      }else{            
        return 'Unknown'    
      }      
    },
  };
  
  const mutations = {

    RESET_CUSTOMER_STATE(state) {      
      Object.assign(state, {        
        customerList: [],  
        customerDetails:{
          customerGuid:'',
          firstName:'',
          lastName:'',
          phoneNumber1:'',
          phoneNumber2:'',
          gender:'',
          email:'',
          address:'',
        },
        primaryContactNumber:"",
        deleteContactCustomerGUID:"",
        callingCustomerName:""
      })
    },

    SET_CUSTOMER_LIST(state, response) {
        state.customerList = response;        
    }, 
    SET_EDIT_CUSTOMER_DETAILS_FORM(state,response){      
      state.customerDetails = {
        customerGuid: response.CustomerGuid,
        firstName: response.FirstName ,
        lastName: response.LastName ,
        phoneNumber1: response.PhoneNumber1 ,
        phoneNumber2: response.PhoneNumber2,
        gender: response.Gender ,
        email: response.Email,
        address: response.Address,
      };      
    },
    SET_PRIMARY_CONTACT_NUMBER(state,response){
      state.primaryContactNumber=response
    },
    SET_DELETE_CONTACT_CUSTOMER_GUID(state,response){
      state.deleteContactCustomerGUID=response
    },
    SET_CUSTOMER_NAME(state,response){
      state.callingCustomerName=response;
    },
    SET_PRIMARY_NUMBER_TO_NULL(state){
      state.phoneNumber1='';
      state.primaryContactNumber=''      
    }  

  };
  
  export default {
    state,
    getters,
    actions,
    mutations,
  };
  