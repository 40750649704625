<template>
    <!-- onqueue div starts here  -->
    
        <div class="modal-content" style="border: none;right: 90%;">
            <ul class="on-queue-users mb-0" >
                <li class="d-flex">
                    <a href="#"><i class="fas fa-phone"></i></a>
                    <div class="user-profile">
                        <p class="name mb-0">{{ showTransfferedCallAgentIdentity.length>12?showTransfferedCallAgentIdentity.substring(0,12)+'..':showTransfferedCallAgentIdentity }}</p>
                        <!-- <CallTimer v-if="isTransferAccepted"></CallTimer> -->
                    </div>
                    <div class="call-action">
                        <ul class="d-flex align-items-center list-unstyled">
                            <li class="call-icon d-none"><a href="#"><i class="fas fa-phone"></i></a></li>
                            <li class="phone-slash d-none"><a href="#"><i class="fas fa-phone-slash"></i></a>
                            </li>
                            <li :class="[isTransferAccepted ? isTransferOffline?'disbale-item': !disableConferenceBtn? ' ' :'disbale-item':'disbale-item','user-icon']" ><a href="#"><i class="fa-solid fa-users" @click="resumeCustomerToCall" title="Conference Call"></i></a></li>
                            <li class="arrows-icon"><a href="#"><i
                                        :class="[isTransferAccepted?'':'disbale-item','fa-solid fa-arrow-right-arrow-left']" @click="completeTransfer" title="Direct Transfer"></i></a></li>
                            <li class="times-icon"><a href="#"><i class="fas fa-times" @click="cancelTransfer" title="End Loop"></i></a></li>
                        </ul>
                    </div>
                </li>
                </ul>
        </div>                  
<!-- onqueue div ends here  -->
</template>
<style scoped>
a{
text-decoration: none;
}
</style>
<script>
//import CallTimer from '@/components/CallTimer.vue'
import {computed,watch} from "vue";
import store from '@/store';
export default {
components: { //CallTimer 
},
setup() {
let disableConferenceBtn=computed(()=>store.getters.isConferenceBtnDisabled);        
let showTransfferedCallAgentIdentity=computed(() => store.getters.getTranferedCallToAgentIdentity); 
let isTransferOffline= computed(()=>store.getters.isTransferToOfflineAgent); 
let isTransferAccepted=computed(()=>store.getters.getTransferCallAccepted)

const cancelTransfer=async ()=>
{              
    store.dispatch("sendNotification",{agent:store.getters.getTranferedCallToAgentIdentity,message:{action:"RESET_TRANSFERRED_AGENT_ON_DROP"}})     
    store.dispatch("setDropTransferedAgent",{ agentIdentity:store.getters.getTranferedCallToAgentIdentity,callSID: store.getters.getWarmTransferCallSID});      
    store.dispatch("setTransferPopupModal",{status:false});  
    store.dispatch("setTransferCallAccepted",{showTransferCallFeatures:false});
    store.dispatch("setConferenceBtnDisabled",{status:false});    
    await store.dispatch("callHoldOrRelease", {connection: store.getters.currentConnection,status: true,line:'TransferPopUpModal->52'});                   
}

const completeTransfer= async ()=>{
    if(store.getters.getOutBoundCall)
    {              
        store.dispatch("hangUpAgent", {CallSid: store.getters.currentConnection.parameters.CallSid,incoming: false,value:store.getters.getUser,line:"TP->57"});     
    }
    else
    {        
        store.dispatch("hangUpAgent", {CallSid: store.getters.currentConnection.parameters.CallSid,incoming: false,value:store.getters.getUser,line:"TP>61"});     
    }    

    store.dispatch("setTransferPopupModal",{status:false});           
    await store.dispatch("sendNotification",{agent:store.getters.getTranferedCallToAgentIdentity,message:{action:"INCOMING_TRANSFER_CALL_COMPLETE_TRANSFER",callOnHoldStatusValue:store.getters.isCallOnHold}})     
    await store.dispatch("setTransferCallAccepted",{showTransferCallFeatures:false});
    await store.dispatch("setConferenceBtnDisabled",{status:false});
    await store.dispatch("setConnection", {connection: null,incoming: false,AgentIdentity: store.getters.getUser});                    
}
const resumeCustomerToCall=async()=>{    
   await store.dispatch("callHoldOrRelease", {connection: store.getters.currentConnection,status: false,line:'TransferPopUpModal->63'});
   disableConferenceBtn.value=true;
   await store.dispatch("setConferenceBtnDisabled",{status:true})
}

watch(() => disableConferenceBtn, () => {console.log("")});

return {
    showTransfferedCallAgentIdentity,
    cancelTransfer,
    completeTransfer,
    resumeCustomerToCall,
    disableConferenceBtn,
    isTransferOffline,
    isTransferAccepted,    
}

},
}
</script>