<template>
  <div class="add-contact-form">
    <form class="contact-form-style" action="">
      <!-- <span class="required-msg" style="text-align: left;font-size: 10px;padding-bottom: 5px;">* Mandatory Field </span> -->

      <div class="formcontrol-wrap">
        <label for="firstname" class="form-label">
          <span class="required-msg">* </span>
          First Name:
          <span
            v-if="!checkFirstNameLenght"
            class="required-msg"
            style="font-size: 10px"
            >{{ firstNameConflictMessage }}
          </span></label
        >
        <input
          type="text"
          id="firstname"
          v-model="firstName"
          @input="validateFirsttName"
          class="form-control"
          @dragover.prevent
          required
          pattern="^[^<>&]*$"
        />
        <div class="formcontrol-wrap">
          <label for="lastname" class="form-label">
            <span class="required-msg">* </span>
            Last Name:
            <span class="required-msg" style="font-size: 10px">{{
              lastNameConflictMessage
            }}</span>
          </label>
          <input
            type="text"
            id="lastname"
            v-model="lastName"
            @input="validateLastName"
            class="form-control"
            @dragover.prevent
            required
            pattern="^[^<>&]*$"
          />
        </div>
        <div class="formcontrol-wrap">
          <label for="email" class="form-label">
            <span class="required-msg">* </span>
            Email:
            <span
              v-if="!validEmail"
              class="required-msg"
              style="font-size: 10px"
              >{{ emailConflictMessage }}</span
            >
          </label>
          <input
            type="text"
            id="email"
            v-model="email"
            @input="validateEmail"
            class="form-control"
            @dragover.prevent
            pattern="^[^<>&]*$"
          />
        </div>
        <div class="formcontrol-wrap">
          <label for="phonenumber" class="form-label"
            ><span class="required-msg">* </span>Phone Number:
            <span
              v-if="!checkPhoneNumLenght || duplicatePhoneNumber"
              class="required-msg"
              style="font-size: 10px"
              ><br />{{ phoneNumberConflictMessage }}
            </span>
          </label>
          <div style="display: flex">
            <select
              name="countryCode"
              v-model="selectedCountry"
              class="custom-select country-select"
              style="font-size: 9px"
              @change="updateCountryCode"
            >
              <option
                v-for="country in countries"
                :key="country.cc"
                :value="country"
              >
                {{ `(+${country.cc})\n${country.name}` }}
              </option>
            </select>

            <input
              type="text"
              id="phonenumber"
              v-model="phoneNumber"
              @input="validatePhoneNumber"
              @dragover.prevent
              @keydown.enter.prevent
              @keydown="restrictInput"
              @paste="handlePaste"
              required
              :placeholder="visiblePlaceholder"
              class="form-control"
              style="width: 75%"
              pattern="^[^<>&]*$"
            />
          </div>
        </div>
        <div class="formcontrol-wrap">
          <label for="username" class="form-label"
            ><span class="required-msg">* </span>Username:
            <span
              v-if="isUsernameConflicting"
              class="required-msg"
              style="font-size: 10px"
              >{{ usernameConflictMessage }}
            </span></label
          >
          <input
            type="text"
            id="username"
            v-model="userName"
            @keyup="checkUsername"
            class="form-control"
            @dragover.prevent
            required
            pattern="^[^<>&]*$"
          />
        </div>
        <div class="formcontrol-wrap">
          <label for="password" class="form-label">
            <span class="required-msg">* </span>Password:
            <span
              v-if="isPasswordConflicting"
              class="required-msg"
              style="font-size: 10px"
              >{{ passwordConflictMessage }}
            </span>
            </label>
          <input
            type="password"
            id="password"
            v-model="password"
            required
            class="form-control"
            @dragover.prevent
            pattern="^[^<>&]*$"
            @input="checkPassword"
          />
        </div>

        <div class="formcontrol-wrap" style="position: relative">
          <label for="agent-role" class="form-label">
            <span class="required-msg">* </span>Agent Role:
          </label>
          <select
            v-model="selectedUserRole"
            :value="roles"
            required
            style="
              min-width: 100%;
              height: 2.3em;
              border-radius: 4px;
              border-color: #dbdbdb;
              appearance: none;
              cursor: pointer; /* Add this line */
            "
            class="form-select"
          >
            <option value="" disabled selected>Select an Agent Role</option>
            <option v-for="role in roles" :key="role.value" :value="role.value">
              {{ role.label }}
            </option>
          </select>
          <!-- Dropdown icon -->
          <i
            class="fas fa-caret-down"
            style="
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              pointer-events: none;
              color: #e6e6e6;
              z-index: 1;
              cursor: pointer; /* Add this line */
            "
          ></i>
        </div>
        
        <div class="formcontrol-wrap" style="position: relative">
          <label for="username" class="form-label">
            <span class="required-msg">* </span>Agent Group:
          </label>
          <select
            v-model="selectedUserGroup"
            :value="groups"
            placeholder="User Group"
            required
            style="
              min-width: 100%;
              height: 2.3em;
              border-radius: 4px;
              border-color: #dbdbdb;
              appearance: none;
              cursor: pointer; /* Add this line */
            "
            class="form-select"
          >
            <option value="" disabled selected>Select an Agent Group</option>
            <option v-for="group in groups" :key="group.value" :value="group.value">
              {{ group.label }}
            </option>
          </select>
          <!-- Dropdown icon -->
          <i
            class="fas fa-caret-down"
            style="
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              pointer-events: none;
              color: #e6e6e6;
              z-index: 1;
              cursor: pointer; /* Add this line */
            "
          ></i>
        </div>
      </div>
    </form>
  </div>
  <div class="button-wrapper" style="margin-top: 10px; background-color: white">
    <a href="#" class="theme-button light-btn" @click="onCloseClick">Close</a>
    <a
      href="#"
      class="theme-button dark-btn"
      :class="[checkIfAnyFieldEmpty ? 'disbale-item' : 'activate-item']"
      @click="onAddClick"
      >Add Agent</a
    >
  </div>
</template>
  <script>
import { ref, computed, isProxy, toRaw } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    const agentListData = computed(() => store.getters.getAgentsList);
    const userName = ref("");
    const password = ref("");
    const firstName = ref("");
    const lastName = ref("");
    const email = ref("");
    const phoneNumber = ref("");
    const selectedUserRole = ref("");
    const selectedUserGroup = ref("");
    let emailConflictMessage = ref("");
    let validEmail = ref(true);

    let isPasswordConflicting = ref(false);
    let firstNameConflictMessage = ref("");
    let phoneNumberConflictMessage = ref("");
    let checkPhoneNumLenght = ref(false);
    let duplicatePhoneNumber = ref(false);
    let lastNameConflictMessage = ref("");
    let usernameConflictMessage = ref("");
    let passwordConflictMessage = ref("");

    let phNumberLenght = ref(10);
    const selectedCountry = ref({ name: "US", cc: "1" });
    const countries = [
      { name: "IN", cc: "91" },
      { name: "US", cc: "1" },
      { name: "GB", cc: "44" },
      { name: "CO", cc: "57" },
      { name: "EC", cc: "593" },
      { name: "EE", cc: "372" },
      { name: "DE", cc: "49" },
      { name: "HK", cc: "852" },
      { name: "IE", cc: "353" },
      { name: "SG", cc: "65" },
      { name: "ES", cc: "34" },
      { name: "BR", cc: "55" },
    ];

    const updatephNumberLenght = () => {
      switch (selectedCountry.value.cc) {
        case "91":
        case "1":
        case "44":
        case "57":
        case "49":
          phNumberLenght.value = 10;
          break;
        case "593":
        case "353":
        case "34":
          phNumberLenght.value = 9;
          break;
        case "372":
        case "852":
        case "65":
          phNumberLenght.value = 8;
          break;
        case "55":
          phNumberLenght.value = 11;
          break;
      }
      phoneNumber.value = "";
    };

    const checkIfAnyFieldEmpty = computed(() => {
      return (
        firstName.value.trim() === "" ||
        lastName.value.trim() === "" ||
        userName.value.trim() === "" ||
        password.value.trim() === "" ||
        email.value.trim() === "" ||
        phoneNumber.value.trim() === "" ||
        selectedUserRole.value.trim() === "" ||
        selectedUserGroup.value.trim() === "" ||
        isUsernameConflicting.value ||
        isPasswordConflicting.value ||
        !validateFirstNameLenght() ||
        !validEmail.value ||
        !checkPhoneNumLenght.value ||
        duplicatePhoneNumber.value
      );
    });

    let listOfActiveAndInactiveAgents = computed(
      () => store.getters.allInternalAgents
    );
    let isUsernameConflicting = ref(false);
    let checkFirstNameLenght = ref(true);

    const roles = [
      { value: "admin", label: "Admin" },
      { value: "user", label: "User" },
    ];

    const groups = [
      { value: "US", label: "US" },
      { value: "IN", label: "IN" },
    ];

    const onAddClick = () => {
      store.dispatch("addUser", {
        UserName: userName.value,
        Password: password.value,
        FirstName: firstName.value,
        LastName: lastName.value,
        UserRole: selectedUserRole.value,
        Email: email.value,
        PhoneNumber: "+" + selectedCountry.value.cc + phoneNumber.value,
        UserGroup: selectedUserGroup.value,
      }).then(() => {
    store.dispatch("setAgentsList");
    store.dispatch("setShowDialler", { status: true }); 
  }).catch(error => {
    console.error("Error adding user:", error);
  });
    };
    const onCloseClick = () => {
      // store.dispatch("SetAgentModal", { status: false });
      store.dispatch("setShowDialler", { status: true }); 
    };
    const checkUsername = async () => {
      userName.value = userName.value.replace(
        /<[^>]+>|[^a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/g,
        ""
      );
      userName.value = userName.value.substring(0, 50);

      if (userName.value.length === 0) {
        isUsernameConflicting.value = true;
        usernameConflictMessage.value = "(Username is mandatory)";
      } else {
        await store.dispatch("fetchAllInternalAgents");
        if (isProxy(listOfActiveAndInactiveAgents.value)) {
          const rawData = toRaw(listOfActiveAndInactiveAgents.value);
          const usernames = rawData.map((agent) =>
            agent.UserName.toLowerCase()
          );
          const exist = usernames.includes(userName.value.toLowerCase());
          if (exist) {
            isUsernameConflicting.value = exist;
            usernameConflictMessage.value = "(Try another username)";
          } else {
            isUsernameConflicting.value = false;
            usernameConflictMessage.value = "";
          }
        }
      }
    };

    const validatPhoneNumberLenght = () => {
      if (phoneNumber.value.length === phNumberLenght.value) {
        checkPhoneNumLenght.value = true;
        phoneNumberConflictMessage.value = "";
        return true;
      } else if (phoneNumber.value === "") {
        checkPhoneNumLenght.value = false;
        phoneNumberConflictMessage.value = "(Phone Number is mandatory)";
        return false;
      } else {
        checkPhoneNumLenght.value = false;
        phoneNumberConflictMessage.value = `(Phone Number must be ${phNumberLenght.value} digits)`;
        return false;
      }
    };

    const restrictInput = (event) => {
      const key = event.key;
      if (!/^\d$|^Backspace$/.test(key)) {
        event.preventDefault();
      }
    };

    const handlePaste = (event) => {
      const pastedText = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      if (!/^\d+$/.test(pastedText)) {
        event.preventDefault();
      }
    };
    const updateCountryCode = () => {
      updatephNumberLenght();
    };

    const checkIfDuplicateNumberExists = () => {
      const fullPhoneNumber =
        "+" + selectedCountry.value.cc + phoneNumber.value;

      for (const agent of agentListData.value) {
        if (agent.PhoneNumber === fullPhoneNumber) {
          duplicatePhoneNumber.value = true;
          phoneNumberConflictMessage.value = "(Phone number already exists)";
          return;
        } else {
          duplicatePhoneNumber.value = false;
        }
      }

      phoneNumberConflictMessage.value = "";
    };

    const validatePhoneNumber = () => {
      phoneNumber.value = phoneNumber.value.replace(/[^0-9]/g, "");
      phoneNumber.value = phoneNumber.value.substring(0, phNumberLenght.value);
      if (validatPhoneNumberLenght()) {
        checkIfDuplicateNumberExists();
      }
    };

    const validateFirstNameLenght = () => {
      if (firstName.value.length >= 3) {
        checkFirstNameLenght.value = true;
        return true;
      } else {
        checkFirstNameLenght.value = false;
        return false;
      }
    };

    const validateFirsttName = () => {      
      firstName.value = firstName.value.substring(0, 150);
      firstName.value = firstName.value.replace(/[^a-zA-Z]/g, "");
      if (firstName.value.length === 0) {
        firstNameConflictMessage.value = "(First Name is mandatory)";
        checkFirstNameLenght.value = false;
      } else if (firstName.value.length >= 3) {
        checkFirstNameLenght.value = true;
      } else {
        firstNameConflictMessage.value = "(Must have atleast 3 characters)";
        checkFirstNameLenght.value = false;
      }
    };

    const validateLastName = () => {
      lastName.value = lastName.value.substring(0, 150);
      lastName.value = lastName.value.replace(/[^a-zA-Z]/g, "");
      if (lastName.value.length === 0) {
        lastNameConflictMessage.value = "(Last Name is mandatory)";
      } else {
        lastNameConflictMessage.value = "";
      }
    };

    const validateEmail = () => {
      email.value = email.value.substring(0, 150);
      email.value = email.value.replace(/[^a-zA-Z0-9@._-]/g, "");
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email.value === "") {
        emailConflictMessage.value = "(Email is mandatory)";
        email.value = email.value.trim();
        validEmail.value = false;
      } else {
        email.value = email.value.trim();

        if (emailRegex.test(email.value)) {
          emailConflictMessage.value = "";
          validEmail.value = true;
        } else {
          emailConflictMessage.value = "(Email is invalid)";
          validEmail.value = false;
        }
      }
    };

    const checkPassword= ()=>{
      if(password.value===''){
        isPasswordConflicting.value=true
        passwordConflictMessage.value='(Password is mandatory)'
      }else{
        isPasswordConflicting.value=false
        passwordConflictMessage.value=''
      }
    }

    const visiblePlaceholder = computed(() => {
      return "1234567890";
    });

    return {
      userName,
      password,
      firstName,
      lastName,
      email,
      phoneNumber,
      selectedUserRole,
      selectedUserGroup,
      roles,
      groups,
      onAddClick,
      onCloseClick,
      checkIfAnyFieldEmpty,
      checkUsername,
      listOfActiveAndInactiveAgents,
      isUsernameConflicting,
      validateFirstNameLenght,
      validateFirsttName,
      validateLastName,
      validatePhoneNumber,
      checkFirstNameLenght,
      validateEmail,
      emailConflictMessage,
      validEmail,
      //onPhoneNumberInput,
      validatPhoneNumberLenght,
      checkPhoneNumLenght,
      phoneNumberConflictMessage,
      visiblePlaceholder,
      selectedCountry,
      countries,
      restrictInput,
      handlePaste,
      updateCountryCode,
      duplicatePhoneNumber,
      lastNameConflictMessage,
      firstNameConflictMessage,
      usernameConflictMessage,
      isPasswordConflicting,
      passwordConflictMessage,
      checkPassword

    };
  },
};
</script>
  
  
  <style scoped>
.agent-form {
  max-width: 321px;
  min-width: 321px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
  z-index: 10;
  position: absolute;
  top: 140%;
  left: 0%;
  right: 3%;
  min-height: 300px;
  max-height: 420px;
  overflow: auto;
}
.Agent-title {
  font-size: large;
  font-weight: 0;
}
.form-group {
  text-align: left;
  margin: 10px 0;
  margin-right: 15px;
}

.label {
  text-align: left;
  display: block;
  font-weight: bolder;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.button-group {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

button:nth-child(1) {
  background: #053466;
  color: #fff;
}

button:nth-child(2) {
  background: #053466;
  color: white;
}

.form-title {
  text-align: left;
  font-size: 1.5em;
}
.required-msg {
  color: red;
}
::placeholder {
  color: rgb(215, 215, 215);
  font-size: 10px;
}
</style>