<template>
    <div class="modal fade modal-styles info-not-found-modal deletecontactmodal show" id="sessiontimeOut" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style="display: block;">
                <div class="modal-dialog">                    
                    <div class="modal-content">
    
                        <div class="info-notfound-inner">
                            <span><i class="fas fa-clock"></i></span>
                            <p style="font-size: 12px;">Session Timeout.<br> Please click on Ready to continue.</p>                            
                            <div class="button-wrapper">                                
                                <a href="#" class="theme-button dark-btn" @click="confirmOkay">Okay </a>
                               
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>            
    </template>
    <script>
    import store from '@/store';
    export default {
        name:'session-timeout-log-out',
        setup() {
    
            const confirmOkay=()=>{                
                store.dispatch("setSessionTimeOutLogOutModal",{status:false});    
            }
            const closeSessionTimeOutModal=()=>{                
                store.dispatch("setSessionTimeOutLogOutModal",{status:false});   
            }
            
            return {
                closeSessionTimeOutModal,
                confirmOkay,
            }
            
        },
    }
    </script>
    <style scoped>
    
    .modal-dialog{    
        box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3)
    }
    .modal-content{
        margin-top: 100px;
    }
    .button-wrapper {
    padding: 15px;   
    display: block; 
}
    </style>