import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import { $routerPath } from '@/axios'

const routes = [
  {
    path: $routerPath,
    component: LoginView
  }
  // Add more routes as needed
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

