<template>
    <div class="contact-list-tab-wrapper">
      <div class="search-bar" style="border-radius: 0px;">
        <form class="search-form d-flex" @submit.prevent  style="border-radius: 0px;"  >
          <input
            v-model="searchTerm"
            class="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
            @keydown.enter.prevent
            @dragover.prevent
            style="margin-top: 10px;margin-bottom: none;"
            pattern="^[^<>&]*$"
          />
          <button class="btn disabled" type="submit" style="border-radius: 0px;border-left: 1px solid #e5e5e5;">
            <i class="fas fa-search"></i>
          </button>
        </form>
      </div>

      <div class="contact-listing scrollbar">
        <ul class="list-unstyled mb-0">
          <li v-if="filteredAgentsList.length === 0" class="no-results">
            No agent found
          </li>
          <li
            v-for="agent in filteredAgentsList"
            :key="agent.AgentGuid"
            :class="['show-list']"            
          >
            <div class="each-contact d-flex align-items-center justify-content-between">
              <div class="single-contact-itm d-flex align-items-center">
                <span class="first-letter">{{ agent.FirstName[0] }}</span>

                <div class="each-user-profile">
                  <p class="mb-0 name" :title="agent.FirstName + ' ' + agent.LastName">                    
                    {{ (agent.FirstName + ' ' + agent.LastName).slice(0, 9) + (agent.FirstName.length + agent.LastName.length > 9 ? '...' : '') }}
                  </p>
                  
                  <span>{{ agent.PhoneNumber }}</span>

                </div>
              </div>
              <div>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#addcontactmodal" class="edit-contact">
                      <i class="fas fa-trash" @click="DeleteAgentDetails(agent.UserName)" style="color: red;"></i>
                  </a>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#addcontactmodal" class="edit-contact">
                      <i class="fa-solid fa-pen-to-square" @click="EditAgentDetails(agent)"></i>
                  </a>   
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
</template>
  
<script>
import store from "@/store";
import { computed, ref } from "vue";

export default {
  name: "contact-list-Form",    
  setup() {
    const agentListData = computed(() => store.getters.getAgentsList);    
    const searchTerm = ref("");      
    //const selectedAgentId = ref(null);

    const filteredAgentsList = computed(() => {
      const term = searchTerm.value.toLowerCase();
      if (agentListData.value === null) {
        return [];
      } else {
        return agentListData.value.filter((agent) => {
          // Check if FirstName and LastName exist and are non-empty strings
          const firstNameMatches = agent.FirstName && agent.FirstName.toLowerCase().includes(term);
          const lastNameMatches = agent.LastName && agent.LastName.toLowerCase().includes(term);
          
          // Include the agent in the filtered list if either FirstName or LastName matches the search term
          return firstNameMatches || lastNameMatches;
        }).sort((a, b) => {
          // Sort agents alphabetically by their FirstName
          return a.FirstName.localeCompare(b.FirstName);
        });
      }
    });

    const EditAgentDetails = async (agent) => {
      await store.dispatch ("SetEditAgentDetails",{agent:agent});      
      store.dispatch("SetAgentModal", { status: false });
      store.dispatch("setEditAgentModal", { status: true });        
    };  
    const DeleteAgentDetails=async(agentID)=>{       
      store.dispatch("setDeleteAgentIdentity", {agentIdentity: agentID});
      store.dispatch("SetAgentModal", { status: false });
      store.dispatch("setConfirmDeleteAgentModal",{status:true});               
    }      

    return {
      searchTerm,
      filteredAgentsList,
      EditAgentDetails,   
      DeleteAgentDetails
    };
  },
};
</script>

  
  <style scoped>
.scrollbar {
  max-height: 300px; /* Set the maximum height for the scrollbar */
  overflow-y: auto; /* Enable vertical scrollbar when content overflows */
}
.rotate-chevron {
  transform: rotate(180deg);
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}
.highlighted-contact {
  background-color: rgb(214, 218, 227) !important;
}
.btn.disabled {
  cursor: not-allowed; /* Set cursor style to not-allowed */
  pointer-events: none; /* Disable pointer events */
}
</style>
  