import axios from 'axios';
import { ref } from 'vue'; 
let environment = ref('qa'); 

let baseURL, afBaseURL, signalRURL,basic,appId,routerPath;
if (environment.value === 'prod') 
{
  baseURL = 'https://bitcurectitoolbar.bitcure.com/';
  afBaseURL = 'https://phone.bitcure.com/';
  signalRURL = 'https://bitcurectitoolbaruat.claysys.org/signalr';
  basic='Basic YXV0aDppeXQ4UktxcUYmNGFHbUtz';
  appId='APaabb73b3ea23dbb77b3941aff9147d11';
  routerPath='/toolbar/'
}
else if(environment.value==='uat') 
{
  baseURL = 'http://localhost:56243/';
  afBaseURL = 'https://ctitoolbarapi.claysys.org/';
  signalRURL = 'http://localhost:44386/';
  basic='YXV0aDppeXQ4UktxcUYmNGFHbUtz';
  appId='APb3a973c4bb6522ae8bca9e84b3703058';
  routerPath='/'
} 
else if (environment.value === 'qa') 
{
  baseURL = 'https://ctitoolbarapi.claysys.org/';
  afBaseURL = 'https://qabitcurephone.claysys.org/';
  signalRURL = 'https://ctitoolbarapi.claysys.org/signalr/';
  basic='Basic YWRtaW46UEBzc3cwcmQx';
  appId='APfdc8cc0cc82824e8aca73e2b4a2c09ef';
  routerPath='/'
} 
else if (environment.value === 'dev') 
{
  baseURL = 'https://devbitcurectitoolbar.unysite.com/';
  afBaseURL = 'https://bitcurephone.claysys.org/';
  signalRURL = 'https://devbitcurectitoolbar.unysite.com/signalr/';
  basic='Basic YWRtaW46UEBzc3cwcmQx';
  appId='AP1fb0ae2f80d186aa5ba296d17f8d0863';
  routerPath='/toolbar/'
} 
else if (environment.value === 'local') 
{
  baseURL = 'http://localhost:56243/';
  afBaseURL = 'https://qabitcurephone.claysys.org/';
  signalRURL = 'https://localhost:44386/';
  basic='Basic YWRtaW46UEBzc3cwcmQx';
  appId='APe34e98a2ceff26ee9ea63f93c8ee2416';
  routerPath='/'
}
const $http = axios.create({ baseURL });
const $httpAF = axios.create({ baseURL: afBaseURL });
const $httpSignalR = signalRURL;
const $authorizationValue=basic;
const $appIDValue=appId;
const $routerPath=routerPath;
export { $http, $httpAF, $httpSignalR,$authorizationValue,$appIDValue,$routerPath };
