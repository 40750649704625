<template>
  <div
    class="dial-and-contact-wrapper voicemail-callrecord-wrapp position-absolute"
    :style="{ left: onGoingCallStatus ? '120%' : '140%', boxShadow: '6px 6px 18px 0px rgba(0, 0, 0, 0.3)' }"
    >
    <div class="tab-content" id="myTabContent">
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
        "
      >
        <p class="mb-0 notif-heading">Notifications</p>
        <a href="#" @click="markAllAsRead" style="font-size: 10px; color: blue">Mark all as read</a>
      </div>
      <div class="contact-listing custom-listings">
        <ul v-if="checkLenght" class="list-unstyled mb-0">
          <li >
            <div class="each-contact d-flex" style="justify-content: flex-start;">
              <div class="d-flex align-items-center">
                <p class="mb-0">No Notifications</p>
              </div>
            </div>
          </li>
        </ul>


        <ul class="list-unstyled mb-0">
          <li v-for="item in combinedList" :key="item.id" :style="[!item.IsEnabled?'background-color: #eef1f7;':'background-color: white;']">
            <div v-if="item.dataType==='call'" class="each-contact d-flex">
              <span class="first-letter" style="background-color: #ffd4d4">
                <img src="@/assets/images/missed call-phone-incoming.svg" alt="wave"/>
              </span>
              <div class="each-user-profile">
                <div class="d-flex align-items-center">
                  <p class="mb-0">
                    Missed Voice call
                    <span class="callduration" :title=item.customerName>
                      from {{ item.customerName === 'Unknown' ? item.PhoneNumber : item.customerName.length>15?item.customerName.substring(0, 14)+'..':item.customerName }}
                    </span>
                  </p>
                  <br />
                </div>
                <span class="callduration">
                  {{
                    calculateTime(
                      item.Date,
                      timeZone
                    )
                  }}
                </span>
              </div>
            </div>
           
        
            <div v-if="item.dataType==='voiceMail'" class="each-contact d-flex" style="display: flex;">
              <span class="first-letter" style="background-color: #43ca88">
                <img src="@/assets/images/sound-wave.svg" alt="wave"/>
              </span>
              <div class="each-user-profile">
                <div class="d-flex align-items-center">
                  <p class="mb-0">
                   Voicemail Recording
                    <span class="callduration" :title=item.customerName>
                      from {{ item.customerName === 'Unknown' ? item.PhoneNumber : item.customerName.length>15?item.customerName.substring(0, 14)+'..':item.customerName }}
                    </span>
                  </p>
                  <br />
                </div>
                <span class="callduration">
                  {{
                    calculateTime(
                      item.Date,
                      timeZone
                    )
                  }}
                </span>
              </div>
            </div>
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import { computed } from "vue";

export default {
  setup() {    
    let onGoingCallStatus = computed(() => store.getters.isCallOngoing);
    let timeZone = computed(() => {
      if (store.getters.getAgentGroup === "US") {
        return "US";
      }
      return "IND";
    });

    // Get computed properties for missedCallList and voiceMailList
    let missedCallList = computed(() =>
      store.getters.getMissedCallNotificationList.map(item => ({ ...item, dataType: 'call' })).sort(
        (a, b) => new Date(b.Date) - new Date(a.Date)
      )
    );    
    let voiceMailList = computed(() =>
      store.getters.getVoiceMailNotificationList.map(item => ({ ...item, dataType: 'voiceMail' })).sort(
        (a, b) => new Date(b.Date) - new Date(a.Date)
      )
    );
    
    // Combine and sort both lists into a single list
    const combinedList = computed(() => {
      // Concatenate missedCallList and voiceMailList
      let combined = [...missedCallList.value, ...voiceMailList.value];
      // Sort the combined list based on Date in descending order
      combined.sort((a, b) => new Date(b.Date) - new Date(a.Date));
      return combined;
    });

    console.log(combinedList);
    
    const getCustomerInfo = async (phNumber) => {
      try {
        const response = await store.dispatch(
          "getCustomerDetailsByPhoneNumber",
          {
            phoneNumber: phNumber,
          }
        );        
        return response.data;
      } catch (error) {
        console.error("An error occurred:", error);
        throw error;
      }
    };

    let checkLenght = computed(() => {
      if (
        store.getters.getMissedCallNotificationList.length === 0 &&
        store.getters.getVoiceMailNotificationList.length === 0
      ) {
        return true;
      }
      return false;
    });

    const calculateTime = (utcDateString, timeZone) => {  
      const utcDate = new Date(utcDateString);  
      const currentDateTime=new Date(new Date().toISOString().slice(0, -1));
      
      const timeZoneOffsets = {
        "IND": 5.5 * 60 * 60 * 1000, // IST is 5 hours 30 minutes ahead of UTC
        "US": -4 * 60 * 60 * 1000 // EST is 4 hours behind UTC
      };
          
      const targetDate = new Date(utcDate.getTime() + timeZoneOffsets[timeZone]);
      const currentDateInTimeZone=new Date(currentDateTime.getTime() + timeZoneOffsets[timeZone]);  
      
      const difference = currentDateInTimeZone - targetDate;
      
      const seconds = Math.floor(difference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);

      if (years > 0) {
        return `${years} ${years === 1 ? "year" : "years"} ago`;
      } else if (months > 0) {
        return `${months} ${months === 1 ? "month" : "months"} ago`;
      } else if (days > 0) {
        return `${days} ${days === 1 ? "day" : "days"} ago`;
      } else if (hours > 0) {
        return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
      } else if (minutes > 0) {
        return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
      } else {
        return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
      }
    };

    const markAllAsRead = async () => {      
      await store.dispatch("setMarkAllAsRead", {agentIdentity:store.getters.getUser});
      store.dispatch('setMissedCallNotificationList',{agentIdentity:store.getters.getUser});
      store.dispatch('setVoiceMailNotificationList',{agentIdentity:store.getters.getUser});  
    };

    return {
      missedCallList,
      voiceMailList,
      getCustomerInfo,
      checkLenght,
      calculateTime,
      timeZone,
      markAllAsRead,
      combinedList,
      onGoingCallStatus
    };
  },
};
</script>
  
  <style scoped>
.custom-listings {
  max-height: 400px;
  overflow-y: auto;
}
.dial-and-contact-wrapper.voicemail-callrecord-wrapp .tab-content {
  padding: 10px !important;
}

.contact-listing .list-unstyled li {
  padding: 12px 16px;
  background-color: #eef1f7;
  margin-bottom: 4px;
}
.read {
  padding: 12px 16px;
  background-color: white;
  margin-bottom: 4px;
}

.contact-listing.custom-listings
  .list-unstyled
  li
  .each-contact
  .each-user-profile
  span.callduration {
  font-size: 12px;
  font-weight: 500 !important;
  margin-left: 2px !important;
}
.dial-and-contact-wrapper.voicemail-callrecord-wrapp {
  min-width: 300px;
  max-width: 340px;
  width: 100%;
}
.dial-and-contact-wrapper .tab-content {
  padding: 5px 10px 15px 10px;
  background: #ffffff;
}
.notif-heading {
  font-weight: bolder;
  text-align: left;
}
.contact-listing .list-unstyled li .each-contact .each-user-profile {
    margin-left: 14px;
    text-align: left;
    font-weight: 500 !important;
  margin-left: 2px !important;
}

</style>