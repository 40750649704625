<template>
  <div
    :class="[isCallOngoing?'readjust':'','search-for-agents']"         
    style="box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3)"
  >
    <div class="search-top">
      <p>
        <i class="fas fa-user"></i>
        <span>Search for Agents</span>
      </p>
    </div>
    <div class="search-results">      
      <ul class="nav nav-tabs" id="myTab2" role="tablist" style="margin-bottom: 10px;">
        <li class="nav-item " role="presentation">
          <button :class="[isUSSelected?'active':'','nav-link tab1']" id="UStab" data-bs-toggle="tab"
          data-bs-target="#UScountry" type="button" role="tab" aria-controls="UScountry"
          aria-selected="false" style="width: 134px;" @click="filterBasedOnCountry('US')">
          <span><img class ='region-logo' src="@/assets/images/us-logo.svg" alt="usa logo"></span>&nbsp; USA</button>
        </li>
        <li class="nav-item" role="presentation" style="border-left: 1px solid #eef1f7;">
          <button :class="[isINDSelected?'active':'','nav-link tab2']" id="india-tab" data-bs-toggle="tab"
          data-bs-target="#india" type="button" role="tab" aria-controls="india"
          aria-selected="false" style="width: 133px;" @click="filterBasedOnCountry('IND')">
          <span><img class ='region-logo' src="@/assets/images/india-logo.svg" alt="india logo"></span>&nbsp; IND</button>
        </li>
      </ul>


      <div class="search-bar d-flex" style="border-radius: 0px;">
        <form class="search-form d-flex" style="width: 80%;border-radius: 0px;">
          <input
            class="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
            v-model="searchInput"
            @keydown.enter.prevent
            style="margin-bottom: 0px;"
            @dragover.prevent
            pattern="^[^<>&]*$"
          />
          <!-- <button class="btn" >
            <i class="fas fa-search"></i>
          </button> -->
                  
        </form>
        <button class="btn"  style="width: 20%;
    border: 1px solid #E6E6E6;
    border-radius: 1px;" @click="refrestAgentsLists">
            <i class="fa-solid fa-rotate"></i>
          </button>
      </div>
      
      <div class="listed-profiles">
        <ul>
          <li v-for="agent in filteredAgents" :key="agent.UniqueID" 
          :class="[isAgentOnline(agent.Status) ? 'online': isAgentBusy(agent.Status)? 'busy': 'offline',isUserSelected===agent.UserName?'user-selected':'']"
          @click="handleItemClick(agent.UserName, agent.IsLoggedIn,agent.Status)"      
          >
            <a style="text-align: left;">{{ agent.UserName.slice(0,15) }}<span v-if="agent.UserName.length>16">...</span>
              <span v-if="isAgentOffline(agent.Status)" style="font-size: 11px;"> - Call on cell phone</span>
              <i :class="[isUserSelected===agent.UserName?'fas fa-check':'d-none']" style="color: green;"></i>
            </a>
          </li>
        </ul>
      </div>
      <div v-if="filteredAgents.length === 0">
        No users found.
      </div>
    </div>
    <div class="button-wrapper">
      <a :class="['activate-item','theme-button light-btn']" @click="CloseTransferModal" style="text-decoration: none;">Cancel</a>
      <a
      :class="[
        !isUserSelectedBusy && !isTransferClicked && isUserSelected ? 'activate-item' : 'disable-item',
        'theme-button dark-btn'
      ]"
      @click="handleTransferClick"
      style="text-decoration: none;"
      :disabled="isUserSelectedBusy || isTransferClicked || !isUserSelected"
    >Transfer</a>
    
    </div>
    
  </div>
</template>
<script>
import store from "@/store";
import { computed, ref } from "vue";
export default {
  name: "transfer-call-modal",

  setup() {  
    let isCallOngoing=computed(() => store.getters.isCallOngoing);
    let onGoingCallStatus=computed(() => store.getters.isCallOngoing);  
    const searchInput = ref('');
    let listOfActiveAndInactiveAgents = computed(() => store.getters.allInternalAgents);    
    let transButtonEnable=computed(()=>store.getters.getTransferButtonEnable);
    let isOutBoundCall=computed(()=>store.getters.getOutBoundCall);
    let originalAgents = computed(() => listOfActiveAndInactiveAgents.value); 
    let isUSSelected=ref(false);
    let isINDSelected=ref(false); 
    let isTransferInitiated=computed(()=>store.getters.isTransferPopUpModal);     
    
    const filteredAgents = computed(() => {
  if (searchInput.value === '') {
    let agents = originalAgents.value.filter((agent) =>
      !isCurrentUser(agent.UserName)
    );
    // Filter based on selected country
    if (isUSSelected.value) {
      agents = agents.filter(agent => agent.GroupId === 'US');
    } else if (isINDSelected.value) {
      agents = agents.filter(agent => agent.GroupId === 'IND');
    }
    sortAgentsByStatus(agents);
    return agents;
  }

      const searchLowerCase = searchInput.value.toLowerCase();
      let agents = originalAgents.value
        .filter((agent) =>
          !isCurrentUser(agent.UserName) &&
          agent.UserName.toLowerCase().includes(searchLowerCase)
        );

          // Filter based on selected country
  if (isUSSelected.value) {
    agents = agents.filter(agent => agent.GroupId === 'US');
  } else if (isINDSelected.value) {
    agents = agents.filter(agent => agent.GroupId === 'IND');
  }
      sortAgentsByStatus(agents);
      return agents;
    });

    const sortAgentsByStatus = (agents) => {
      const statusOrder = {
        online: 0,
        busy: 1,
        offline: 2,
      };

      agents.sort((a, b) => statusOrder[a.Status] - statusOrder[b.Status]);
    };

    const checkOutGoingCallrecieved =computed(()=>{        
      if(isOutBoundCall.value){
        if(transButtonEnable.value){
          return true
        }
        else{
          return false
        }
      }
      else{
        return true
      }
    })


    const isUserSelected = ref(false);
    const transfercallToAgent=ref("");
    const isTransferAgentLoggedIn=ref(null);
    const isUserSelectedBusy = ref(false);
    const isUserSelectedOffline=ref(false);
    const isTransferClicked = ref(false);

    const TransferCall = async () => {      
      await store.dispatch('TransferCall',{agentIdentity: transfercallToAgent.value,isLoggedIn:isTransferAgentLoggedIn.value}); 
      store.dispatch("setTransferAgentIsOfline",{status:isUserSelectedOffline});
      store.dispatch("setTransferCallModal", {status:false});
      store.dispatch("setTransferPopupModal",{status:true});     
      await store.dispatch("sendNotification",{agent:transfercallToAgent.value,message:{action:"INCOMING_TRANSFER_CALL_WARM_TRANSFER",transferingAgentName:store.getters.currentAgent}})       
    };
    const handleTransferClick = () => {
      if (!isTransferClicked.value) {
        isTransferClicked.value = true;
        TransferCall();
      }
    };

    

    const isAgentOnline = (status) => {           
      if (status==='online') {              
        return true;
      } else {        
        return false;
      }
    };

    const isAgentBusy=(status)=>{      
      if (status==='busy') {              
        return true;
      } else {        
        return false;
      }
    };

    const isAgentOffline=(status)=>{
      if (status==='offline') {              
        return true;
      } else {        
        return false;
      }
    }

    const handleItemClick = (agentName,logInStatus,status) => {      
      transfercallToAgent.value=agentName; 
      isTransferAgentLoggedIn.value=logInStatus;
      isUserSelectedBusy.value=isAgentBusy(status);
      isUserSelectedOffline.value=isAgentOffline(status);
      isUserSelected.value=agentName      
    };

    // const CloseTransferModal = () => {
    //   isUserSelected.value=false;
    //   store.dispatch("setTransferCallModal", {
    //     status: !store.getters.isTransferCallModal,        
    //   });      
    // };

    const CloseTransferModal = () => {
      isUserSelected.value = false;
      store.dispatch("setTransferCallModal", {
        status: !store.getters.isTransferCallModal,
      });
      isTransferClicked.value = false;  // Reset the click state when the modal is closed
    };

    const isCurrentUser = (userName) => {
      if (userName === store.getters.currentAgent) {
        return true;
      } else {
        return false;
      }
    };

    const refrestAgentsLists=()=>{
      isUserSelected.value = false; 
      transfercallToAgent.value=""; 
      isTransferAgentLoggedIn.value=null;
      isUserSelectedBusy.value=false;
      isUserSelectedOffline.value=false;   
      isUSSelected.value=false;
      isINDSelected.value=false;                     

      store.dispatch("fetchAllInternalAgents");
    }

    const filterBasedOnCountry=(countryName)=>{
      if(countryName=='US'){
        isUSSelected.value=true;
        isINDSelected.value=false;
        
      }else{
        isINDSelected.value=true;
        isUSSelected.value=false;
      }
    }

    return {
      isCallOngoing,
      listOfActiveAndInactiveAgents,
      TransferCall,
      isAgentOnline,
      CloseTransferModal,
      handleTransferClick,
      handleItemClick,
      isUserSelected,
      isCurrentUser,
      filteredAgents,
      searchInput,
      onGoingCallStatus,
      refrestAgentsLists,
      isAgentBusy,
      isAgentOffline,
      isUserSelectedBusy,
      transButtonEnable,
      checkOutGoingCallrecieved,
      filterBasedOnCountry,
      isUSSelected,
      isINDSelected,
      isTransferInitiated      
    };
  },
};
</script>
<style scoped>
.offline{
  background: #EDF9F6;
}
.listed-profiles{
  overflow-y: scroll;
    max-height: 250px;
}
.nav-link {
    background: white;
    color: black;
}
.nav-link.active {
    color: white;
    background-color: #001143;
    border-color: #dee2e6 #dee2e6 #fff;
}
.region-logo{
  width: 20px;
  height: 20px;
}
.disable-item{
  pointer-events: none;
  opacity:0.5  ;
  cursor: not-allowed;
}

</style>
