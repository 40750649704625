import { createStore } from "vuex";
import users from "@/store/modules/users";
import devices from "@/store/modules/devices";
import modal from "./modules/modal";
import customers from "./modules/customers";
import callAndVoiceRecording from "@/store/modules/callAndVoiceRecording";
import signalR from "@/store/modules/signalR";
import callLogs from "@/store/modules/callLogs";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    users,
    devices,
    modal,
    customers,
    callAndVoiceRecording,
    signalR,
    callLogs
  },
});
