import {$http,$httpAF,$authorizationValue} from "@/axios";

const state = {
    user: null,
    loggedIn: false,
    availableAgents: [],
    internalAgents: [],
    showOnline: [],
    agentOnline: false,
    isAdmin: null,
    addAgent:false,
    agentGroup:"",
    currentAgentOnline:false,
  };
  
  const getters = {
    getUser: state => state.user,
    isUserLoggedIn: state => state.loggedIn,
    allAvailableAgents: state => state.availableAgents,
    allInternalAgents: state => state.internalAgents,
    showAgentsOnline: state => state.showOnline,
    isAgentOnline: state => state.agentOnline,
    isAgentAdmin: state => state.isAdmin,
    getAgentGroup :state => state.agentGroup,
    isCurrentAgentOnline :state => state.currentAgentOnline
  };
  
  const actions = {   

    resetUserState({commit}){
      commit('RESET_USER_STATE');
    },

    async login({ commit }, payload) {            
        const usersResponse = await $http.get("Call/GetAgentRole?AgentIdentity=" + payload.user);
        commit("SET_USER", payload.user);        
        if (usersResponse.data == "admin" || usersResponse.data == "Admin" ) {
          commit("SET_ADMIN", true);
          const response = await $http.post("Call/GetAgentGroup?agentIdentity=" + payload.user);          
          commit("SET_ADMIN_GROUP",response.data);
        } else {
          commit("SET_ADMIN", false);
        }
      },

      async addUser({commit},payload) {       
        const afResponse= await $httpAF.post("Forms/api/appforms",
        {
          "TenantId": "default",
          "FormId": "63FD3732-12DA-4922-8CFC-D4EDD5146B06",
          "FormName": null,
          "ExecuteFormLoadRule": false,
          "RuleNames": [
            {
              "RuleName": "On_FormLoad"
            }
          ],
          "APIDataList": [
            {
              "ControlName": "txtFirstname",
              "Value": payload.FirstName,
              "Rows": null
            },
            {
              "ControlName": "txtLastName",
              "Value": payload.LastName,
              "Rows": null
            },
            {
              "ControlName": "txtUserName",
              "Value": payload.UserName,
              "Rows": null
            },
            {
              "ControlName": "txtPassword",
              "Value": payload.Password,
              "Rows": null
            }
          ],
          "OverrideValidation": false
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': $authorizationValue
          },
        }
        );
        console.log("insert values",afResponse.data);
        if(afResponse.status===200){
          const response = await $http.post("Call/SaveUserData",          
        {
          UserName: payload.UserName,
          UserRole: payload.UserRole,
          FirstName: payload.FirstName,
          LastName: payload.LastName,
          Password: payload.Password,
          Email: payload.Email,
          PhoneNumber:payload.PhoneNumber,
          AgentGroupName:payload.UserGroup
        }
        ); 
        if(response.status===200 && afResponse.status===200){
          alert('The new agent was added successfully.');
        } 
        else{
          alert ('Sorry! Could not add new agent. Try again. ');
        }  
        }   
        else{
          alert ('Sorry! Could not add new agent. Try again. ');
        }  
              
        commit("SET_ADD_AGENT")
      },
      
      async fetchAllInternalAgents({ commit }) {        
        let usersResponse = []
        usersResponse=await $http.get("Call/GetAllInternalAgents");                        
        commit("SET_INTERNAL_AGENTS", usersResponse.data.agents);              
        this.fetchAllAvailableAgents;
        this.fetchOnlineAgents;        
      },

      async fetchAllAvailableAgents({ commit }) {
        const usersResponse = await $http.get("Call/GetAvailableAgents");
        commit("SET_AVAILABLE_AGENTS", usersResponse.data.agents);
      },
      async fetchOnlineAgents({ commit }, payload) {
        commit("SHOW_ONLINE_AGENTS", payload);
      },
      async SetAgentOffline({commit},payload) {                
        await $http.post("Call/SetAgentOffline", {agentIdentity: payload.agentIdentity});
        await commit('SET_CURRENT_AGENT_ONLINE',false);
        commit("RESET_USER", null);
      },  
      async SetAgentOfflineBreak({commit},payload) {                
        await $http.post("Call/SetAgentOfflineBreak", {agentIdentity: payload.agentIdentity});
        await commit('SET_CURRENT_AGENT_ONLINE',false);
        commit("RESET_USER", null);
      },  
      async setCurrentAgentOnline({commit},payload){
        await commit('SET_CURRENT_AGENT_ONLINE',payload.status);
      }    
  };
  
  const mutations = {

    RESET_USER_STATE(state) {
      
      Object.assign(state, {
        user: null,
        loggedIn: false,
        availableAgents: [],
        internalAgents: [],
        showOnline: [],
        agentOnline: false,
        isAdmin: null,
        addAgent:false,
        agentGroup:""
      })
    },
    SET_USER(state, user) {
        state.user = user;
        state.loggedIn = true;
      },
      SET_ADMIN(state, admin) {
        state.isAdmin = admin
      },
      SET_AVAILABLE_AGENTS(state, agents) {
        state.availableAgents = agents;
      },
      SET_INTERNAL_AGENTS(state, agents) {
        state.internalAgents = agents;
      },
      SHOW_ONLINE_AGENTS(state, agents) {
        state.showOnline = agents;
      },
      RESET_USER(state, user) {
        state.user = user;
        state.loggedIn = false;        
      },
      SET_ADD_AGENT(state){
        state.addAgent = true
      },
      SET_ADMIN_GROUP(state,response){
        state.agentGroup=response
      },
      SET_CURRENT_AGENT_ONLINE(state,response){
        state.currentAgentOnline=response
      }
  };
  
  export default {
    state,
    getters,
    actions,
    mutations,
  };
  