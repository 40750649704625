<template>
    <div
      :class="['show active', 'tab-pane fade']"
      id="dial"
      role="tabpanel"
      aria-labelledby="dial-tab"
    >
      <form action="">
        <div class="form-group mobile-number-area d-flex align-items-center">
          <select
            name="countryCode"
            v-model="selectedCountry"
            class="custom-select country-select"
            style="font-size: 10px"
            @change="updateCountryCode"
          >
            <option
              v-for="country in countries"
              :key="country.cc"
              :value="country"
            >
              {{ `(+${country.cc})\n${country.name}` }}
            </option>
          </select>
          <input
            type="text"
            class="form-control"
            id="mobile-number"
            aria-describedby="mobilenumber"
            v-model="phoneNumber"
            @input="handleInputChange"
            @keydown.enter.prevent
            @keydown="restrictInput"
            @paste="handlePaste"
            style="margin: 0px;"
            @dragover.prevent
          />
          <a href="#" class="clearall" @click="clearPhoneNumber" style="margin: -20px;z-index: 100;">
            <i class="fas fa-times"></i>
          </a>
        </div>
      </form>
      <div class="dial-keypad">
      <div id="phone">
        <div id="wrapper">
          <div class="row">
            <div class="key" rel="1" @click="appendToPhoneNumber('1')">1</div>
            <div class="key" rel="2" @click="appendToPhoneNumber('2')">
              2<span>abc</span>
            </div>
            <div class="key" rel="3" @click="appendToPhoneNumber('3')">
              3<span>def</span>
            </div>
          </div>
          <div class="row">
            <div class="key" rel="4" @click="appendToPhoneNumber('4')">
              4<span>ghi</span>
            </div>
            <div class="key" rel="5" @click="appendToPhoneNumber('5')">
              5<span>jkl</span>
            </div>
            <div class="key" rel="6" @click="appendToPhoneNumber('6')">
              6<span>mno</span>
            </div>
          </div>
          <div class="row">
            <div class="key" rel="7" @click="appendToPhoneNumber('7')">
              7<span>pqrs</span>
            </div>
            <div class="key" rel="8" @click="appendToPhoneNumber('8')">
              8<span>tuv</span>
            </div>
            <div class="key" rel="9" @click="appendToPhoneNumber('9')">
              9<span>wxyz</span>
            </div>
          </div>
          <div class="row">
            <div class="key" rel="*" @click="appendToPhoneNumber('*')">*</div>
            <div class="key" rel="0" @click="appendToPhoneNumber('0')">
              0<span style="padding-left: 3px;">+</span>
            </div>
            <div class="key" rel="#" @click="appendToPhoneNumber('#')">#</div>
          </div>
          <!-- Other rows omitted for brevity -->
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import store from "@/store";
import { ref, computed ,watch} from "vue";


export default {
  name: "dialler-form",
  props:{
    clearPhNoInput:Boolean
  },
  setup(props) {    
    const fullPhNo=ref("");
    const countryCode= computed(()=>store.getters.getCountryCode);    
    let phoneNumber = ref("");    
    let isInputFocused = ref(false);
    const selectedCountry = ref({ name: "US", cc: "1" });
    let showCountryList = computed(() => store.getters.showCountyCodeDropDown); 
    let phNumberLenght=ref(10);

    const countries = [
      { name: "IN", cc: "91" },
      { name: "US", cc: "1" },
      { name: "GB", cc: "44" },
      { name: "CO", cc: "57" },
      { name: "EC", cc: "593" },
      { name: "EE", cc: "372" },
      { name: "DE", cc: "49" },
      { name: "HK", cc: "852" },
      { name: "IE", cc: "353" },
      { name: "SG", cc: "65" },
      { name: "ES", cc: "34" },
      { name: "BR", cc: "55" },
    ];

    const updatephNumberLenght=()=>{
      switch (selectedCountry.value.cc){
        case '91':
        case '1':
        case '44':
        case '57':
        case '49':
          phNumberLenght.value=10;
          break;
        case '593':
        case '353':
        case '34':
          phNumberLenght.value=9;
          break;
        case '372':
        case '852':
        case '65':
          phNumberLenght.value=8;
          break;
        case '55':
          phNumberLenght.value=11;
          break;
      }
      phoneNumber.value='';
    };

    const selectCountry = (country) => {        
      selectedCountry.value = country;
      countryCode.value=country.cc;
      showCountryList.value = false;
    };
    
    const updateCountryCode = () => {  
      updatephNumberLenght();      
      showCountryList.value = false;
      store.dispatch("setCountry", {country:selectedCountry.value.name});
      store.dispatch("setCountrycode", {cc:selectedCountry.value.cc});          
    };

    const appendToPhoneNumber = (digit) => {
      if (phoneNumber.value.length <= phNumberLenght.value) {
        phoneNumber.value += digit;
        store.dispatch("setPhoneNumberWithoutCountryCode", phoneNumber.value); 
        
        fullPhNo.value="+"+countryCode.value+phoneNumber.value;                                           
        store.dispatch("setOutBoundPhoneNumber", fullPhNo.value);   
      }           
    };

    const clearPhoneNumber = () => {            
      phoneNumber.value = "";
      fullPhNo.value="";
      store.dispatch("setOutBoundPhoneNumber", fullPhNo.value);
    };


    const toggleCountryList = () => {      
      store.dispatch("setShowCountryCodeList", {
        status: !store.getters.showCountyCodeDropDown,
      });
    };

    const handleInputChange = (event) => {                
      if (phoneNumber.value.length <= phNumberLenght.value) {        
        phoneNumber.value = event.target.value;
        store.dispatch("setPhoneNumberWithoutCountryCode", phoneNumber.value); 

        fullPhNo.value="+"+countryCode.value+phoneNumber.value;                                           
        store.dispatch("setOutBoundPhoneNumber", fullPhNo.value); 
      } else {        
        phoneNumber.value = phoneNumber.value.slice(0, phNumberLenght.value);    
      }
    };
    const restrictInput = (event) => {    
      const key = event.key;
      
      if (!/^\d$|^Backspace$|^#$|^\*$|^ArrowLeft$|^ArrowRight$/.test(key)) {
        event.preventDefault();
      }
    };
   
    
    const handlePaste=(event)=> {
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      if (!/^\d+$/.test(pastedText)) {
        event.preventDefault();
      }
    }

    watch(() => props.clearPhNoInput, () => {
      clearPhoneNumber()
    });

    return {
      phoneNumber,
      isInputFocused,
      appendToPhoneNumber,
      clearPhoneNumber,
      countries,
      updateCountryCode,
      selectedCountry,
      toggleCountryList,
      selectCountry,
      handleInputChange,
      restrictInput,
      handlePaste
    };

  }, 
};
</script>
  