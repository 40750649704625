<template>
  <div
    :class="['show active', 'tab-pane fade']"
    id="contactlist"
    role="tabpanel"
    aria-labelledby="contactlist-tab"
  >
    <div class="contact-list-tab-wrapper">
      <div class="search-bar" style="border-radius: 0px;">
        <form class="search-form d-flex" @submit.prevent  style="border-radius: 0px;">
          <input
            v-model="searchTerm"
            class="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
            @keydown.enter.prevent
            style="margin-top: 10px;margin-bottom: none;"
            @dragover.prevent
          />
          <button class="btn disabled" type="submit" style="border-radius: 0px; border-left: 1px solid #e5e5e5;">
            <i class="fas fa-search"></i>
          </button>
        </form>
      </div>

      <div class="contact-listing scrollbar">
        <ul class="list-unstyled mb-0">
          <li v-if="filteredContactList.length === 0" class="no-results">
            No user found
          </li>
          <li v-for="contact in filteredContactList" :key="contact.CustomerGuid" :class="[isSelectedContactID(contact) ? 'highlighted-contact' : '', 'show-list']" @dblclick="handleDoubleClick(contact.PhoneNumber1)">
            <div class="each-contact d-flex align-items-center justify-content-between" >
              <div class="single-contact-itm d-flex align-items-center" @click="setSelectedContact(contact.PhoneNumber1, contact.CustomerGuid)">
                <span class="first-letter">{{ contact.FirstName[0] }}</span>

                <div class="each-user-profile">
                  <p class="mb-0" @click="setSelectNumberasPhonenumber(contact.PhoneNumber1)" style="cursor: pointer" :title="contact.DisplayName">
                    {{ contact.DisplayName.slice(0,20)}}<span v-if="contact.DisplayName.length>21">...</span>
                  </p>
                  <span>{{ contact.PhoneNumber1 }}</span>

                  <span v-if="!checkPhoneNumbersListLenght(contact.PhoneNumbers)" class="more-contacts" @click="toggleMoreContacts(contact.CustomerGuid)">
                    <i class="fa-solid" :class="{
                      'fa-circle-chevron-down': !isMoreContactsVisible(contact.CustomerGuid),
                      'fa-circle-chevron-up': isMoreContactsVisible(contact.CustomerGuid)
                      }">
                      </i>
                  </span>
                </div>
              </div>

              <a href="#" data-bs-toggle="modal" data-bs-target="#addcontactmodal" class="edit-contact">
                <i class="fa-solid fa-pen-to-square" @click=" EditCustomerContatctDetails(contact.CustomerGuid,contact.FirstName)"></i>
              </a>
            </div>
            <div v-if="isMoreContactsVisible(contact.CustomerGuid)">
              <ul class="more-contact-listing list-unstyled">
                <li
                  v-for="(number, index) in contact.PhoneNumbers"
                  :key="index"
                  :style="{
                    'border-bottom':
                      index === contact.PhoneNumbers.length - 2
                        ? '1px solid #E6E6E6'
                        : '',
                    'padding-left': '10px',
                    'background-color': isSelectedNumber(number)
                      ? 'rgb(238 241 247)'
                      : 'transparent',
                  }"
                  @click="setSelectNumberasPhonenumber(number)"
                >
                  <span style="font-size: 10px">
                    Phone number {{ index + 1 }}:
                  </span>
                  <span style="font-size: 12px; font-weight: bold">
                    {{ number }}
                  </span>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  
<script>
import store from "@/store";
import { computed, ref, watch } from "vue";

export default {
  name: "contact-list-Form",
  props: {
    clearPhNoInput: Boolean,
  },
  setup(props) {
    const contactListData = computed(() => store.getters.getContactList);    
    const searchTerm = ref("");
    const selectedNumbers = ref([]);
    const selectedContactId = ref(null);

    const filteredContactList = computed(() => {
      const term = searchTerm.value.toLowerCase();
      if (contactListData.value === null) {
        return [];
      } else {
        return contactListData.value
          .filter((contact) => {
            const includesTerm =
              contact.DisplayName.toLowerCase().includes(term) ||
              contact.FirstName.toLowerCase().includes(term) ||
              contact.LastName.toLowerCase().includes(term);

            const includesNumber = contact.PhoneNumbers.some((number) =>
              number.includes(term)
            );

            return includesTerm || includesNumber;
          })
          .sort((a, b) =>
            a.DisplayName.toLowerCase().localeCompare(b.DisplayName.toLowerCase())
          );
      }
    });

    

    const checkPhoneNumbersListLenght=(list)=>{
      if(list[1]==''){
        return true
      }
      return false
    }

    const openContactId = ref(null);

    const toggleMoreContacts = (customerId) => {
      openContactId.value =
        openContactId.value === customerId ? null : customerId;
    };

    const isMoreContactsVisible = (customerId) => {
      return openContactId.value === customerId;
    };

    const setSelectNumberasPhonenumber = (number) => {
      selectedContactId.value = null;
      selectedNumbers.value = [number];
      store.dispatch("setOutBoundPhoneNumber", selectedNumbers.value);      
    };

    const setSelectedContact =(phoneNumber1, customerGuid)=>{
      selectedContactId.value = customerGuid;
      selectedNumbers.value = [phoneNumber1];
      store.dispatch("setOutBoundPhoneNumber", selectedNumbers.value);
    }

    const isSelectedNumber = (number) => {
      return selectedNumbers.value.includes(number);
    };

    const isSelectedContactID = (contact) => {            
      return selectedContactId.value === contact.CustomerGuid;
    };

    const clearPhoneNumber = () => {
      searchTerm.value = "";
      selectedNumbers.value = "";
      store.dispatch("setOutBoundPhoneNumber", selectedNumbers);
    };

    const EditCustomerContatctDetails = async (customerGuId, firstName) => {
      await store.dispatch("getCustomerDetailsByName", {
        name: firstName,
        customerGuid: customerGuId,
      });
      await store.dispatch("SetDeleteEditCustomerContactModal", {
        status: !store.getters.isDeleteEditContactFormModal,
      });
    };

    const handleDoubleClick =async(phNumber)=>{            
      store.dispatch('resetCallLogsList');
      store.dispatch('setCallLogsList',{number:phNumber});      
      store.dispatch('setCallLogModal',{status:true});
    }

    watch(
      () => props.clearPhNoInput,
      () => {
        clearPhoneNumber();
      }
    );

    return {
      searchTerm,
      filteredContactList,
      toggleMoreContacts,
      isMoreContactsVisible,
      setSelectNumberasPhonenumber,
      isSelectedNumber,
      isSelectedContactID,
      EditCustomerContatctDetails,
      setSelectedContact,
      handleDoubleClick,
      checkPhoneNumbersListLenght
    };
  },
};
</script>

  
  <style scoped>
.scrollbar {
  max-height: 300px; /* Set the maximum height for the scrollbar */
  overflow-y: auto; /* Enable vertical scrollbar when content overflows */
}
.rotate-chevron {
  transform: rotate(180deg);
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}
.highlighted-contact {
  background-color: rgb(214, 218, 227) !important;
}
.btn.disabled {
  cursor: not-allowed; /* Set cursor style to not-allowed */
  pointer-events: none; /* Disable pointer events */
}
</style>
  