<template>
    <div class="modal fade modal-styles info-not-found-modal deletecontactmodal show" id="deletecontactmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style="display: block;">
                <div class="modal-dialog">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cancel"><i class="fas fa-times"></i></button>
                    <div class="modal-content">
    
                        <div class="info-notfound-inner">
                            <span><i class="fas fa-address-book"></i></span>
                            <p style="font-size: 12px;">Customer contact is not saved. Would you like to save it ?</p>
                            <div class="button-wrapper">
                                <a href="#" class="theme-button light-btn" @click="cancel">Cancel</a>
                                <a href="#" class="theme-button dark-btn" @click="OpenAddCustomerContactModal">Confirm </a>
                               
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>
            <!-- deletecontact modal ends here  -->
    </template>
    <script>
    import store from '@/store';
    import { computed } from "vue";
    export default {
        name:'confirm-delexte-contact-modal',
        setup() {
            let onGoingCallStatus = computed(() => store.getters.isCallOngoing);
            
            const OpenAddCustomerContactModal = async () => {
                store.dispatch("setConfirmToSaveNewContactNumberModal", { status: false});
                if (!onGoingCallStatus.value) {
                    store.dispatch("setPrimaryNumberToNull", { phoneNo: "" });
                    await store.dispatch("SetAddCustomerContactModal", {
                        status: !store.getters.isAddCustomerContactModal,
                        line:"CSCN->35"
                    });
                } 
                else {
                    await store.dispatch("SetAddCustomerContactModal", {
                        status: !store.getters.isAddCustomerContactModal,
                        line:"CSCN->39"
                    });
                }
            };
            
            const cancel=()=>{
                store.dispatch("setConfirmToSaveNewContactNumberModal", { status: false}); 
            }
            
            return {
                OpenAddCustomerContactModal,
                cancel
            }
            
        },
    }
    </script>
    <style scoped>
    
    .modal-dialog{    
        box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3)
    }
    .modal-content{
        margin-top: 100px;
    }
    </style>