<template>
  <div
    :class="[isCallOngoing ? 'readjust' : '', 'search-for-agents']"
    style="box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3)"
  >
    <div
      class="dial-and-contact-wrapper position-absolute"
      style="box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3)"
    >
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            :class="[!isDiallerClicked ? 'active' : '', 'nav-link']"
            id="contactlist-tab"
            data-bs-toggle="tab"
            data-bs-target="#contactlist"
            type="button"
            role="tab"
            aria-controls="contactlist"
            aria-selected="false"
            @click="ChangeIsDiallerStatus('contactList')"
          >
            <i class="fas fa-user"></i>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            :class="[isDiallerClicked ? 'active' : '', 'nav-link']"
            id="dial-tab"
            data-bs-toggle="tab"
            data-bs-target="#dial"
            type="button"
            role="tab"
            aria-controls="dial"
            aria-selected="true"
            @click="ChangeIsDiallerStatus('dialler')"
          >
            <i class="fas fa-th"></i>
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <!-- for contact listing -->
        <div v-if="!isDiallerClicked">
          <ContactListForm :clearPhNoInput="clearInput"></ContactListForm>
        </div>

        <!-- for dialing area  -->
        <div v-if="isDiallerClicked">
          <DiallerForm :clearPhNoInput="clearInput"></DiallerForm>
        </div>
      </div>
      <!-- dial and contact section ends -->

      <!-- dialer actions on bottom -->
      <div class="dialing-actions">
        <ul class="list-unstyled mb-0 d-flex justify-content-center">
          <li class="d-none">
            <a href="#"><i class="fas fa-phone-slash"></i></a>
          </li>

          <li>
            <a href="#" :class="{ disabled: isCallOngoing }"  @click="initiatePhoneCall">
              <i class="fas fa-phone"></i>
            </a>
          </li>

          <li>
            <a href="#"
              ><i class="fa-solid fa-rotate" @click="resetPhoneNumber"></i
            ></a>
          </li>
          <li>
            <a href="#"><i class="fas fa-times" @click="closeDialler"></i></a>
          </li>
        </ul>
      </div>
      <!-- dialer actions on bottom ends here-->
    </div>
  </div>
</template>
<script>
import { computed, isProxy, toRaw, ref } from "vue";
import store from "@/store";
import DiallerForm from "@/components/DiallerFom.vue";
import ContactListForm from "@/components/ConatctListForm.vue";
export default {
  name: "dialler-and-contactList-modal",
  components: {
    DiallerForm,
    ContactListForm,
  },
  setup() {
    let isCallOngoing = computed(() => store.getters.isCallOngoing);
    let clearInput = ref(true);
    let isDiallerClicked = computed(() => store.getters.isShowDialler);
    const closeDialler = () => {
      store.dispatch("setDiallerAndContactListModal", { status: false });
    };

    const ChangeIsDiallerStatus = (type) => {
      switch (type) {
        case "dialler":
          store.dispatch("setShowDialler", { status: true });
          break;
        case "contactList":
          store.dispatch("setShowDialler", { status: false });
          break;
      }
    };

    const validateNumber = async () => {
      const phoneNumber = store.getters.getOutBoundPhoneNumber;

      if (Array.isArray(phoneNumber)) {
        await store.dispatch("validatePhone", {
          CountryCode: "",
          PhoneNumber: toRaw(phoneNumber[0]),
        });
      } else {
        // If it's a plain string
        await store.dispatch("validatePhone", {
          CountryCode: "",
          PhoneNumber: toRaw(phoneNumber),
        });
      }
    };

    const initiatePhoneCall = async () => {
      // Assuming 'device' is your Twilio Device instance
      // You need to handle this part based on your actual setup
      await validateNumber();
      if (store.getters.getNumberValidityStatus) {
        store.dispatch("setOutBoundStatus", true);
        store.dispatch("setDiallerAndContactListModal", { status: false });
        store.dispatch("setCallLogModal", { status: false });
        store.dispatch("setAgentName", { agentName: store.getters.getUser });
        const device = store.getters.currentDevice;

        if (isProxy(device)) {
          const rawData = toRaw(device);
          rawData
            .connect({
              params: {
                To: store.getters.getOutBoundPhoneNumber,
                From: store.getters.currentAgent,
                CallDirection: "outbound",
              },
            })
            .then((call) => {
              // Store the connection in the Vuex store or handle it as needed
              store.dispatch("setConnection", {
                connection: call,
                incoming: false,
                AgentIdentity: store.getters.getUser,
              });

              // Set up event listeners for the call
              call.on("accept", () => {
                store.dispatch("setOutBoundStatus", true);

                // Additional logic here for when the call is accepted
                store.dispatch("setStopTimerStatus", { status: false });
                store.dispatch("setTimerStatus", { status: true });
                store.dispatch("setIsCallOnGoing", { onGoingCall: true });
                store.dispatch("setIncoming", {
                  status: false,
                  line: "DC->160",
                });
              });

              call.on("disconnect", () => {
                //store.dispatch('hangUpClient', { connection: null, incoming: false,value:store.getters.getUser,line:"DC->165"});
                store.dispatch("setIsCallOnGoing", { onGoingCall: false });
                store.dispatch("setStopTimerStatus", { status: true });
                store.dispatch("setTimerStatus", { status: false });
                store.dispatch("setOutBoundStatus", false);
                store.dispatch("setTransferPopupModal", { status: false });
                store.dispatch("setEnableTransferCall", { status: false });
                store.dispatch("setControlOnTransfer", { status: true });
                store.dispatch("setConnection", {
                  connection: null,
                  incoming: false,
                  AgentIdentity: store.getters.getUser,
                });
              });

              call.on("reject", () => {
                store.dispatch("setEnableTransferCall", { status: false });
                store.dispatch("setConnection", {
                  connection: null,
                  incoming: false,
                  AgentIdentity: store.getters.getUser,
                });
              });
              call.on("cancel", () => {
                store.dispatch("setEnableTransferCall", { status: false });
                store.dispatch("setConnection", {
                  connection: null,
                  incoming: false,
                  AgentIdentity: store.getters.getUser,
                });
              });
            })
            .catch((error) => {
              console.error("Error connecting call:", error);
            });
        } else {
          console.error("Twilio Device not initialized");
        }
      } else {
        alert("Outbound Number not valid");
      }
    };

    const resetPhoneNumber = () => {
      clearInput.value = !clearInput.value;
    };

    return {
      isCallOngoing,
      closeDialler,
      ChangeIsDiallerStatus,
      isDiallerClicked,
      initiatePhoneCall,
      resetPhoneNumber,
      clearInput,
    };
  },
};
</script>
<style scoped>
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
